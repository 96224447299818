export enum RouteNames {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    FORGET_PASSWORD = '/forget_password',
    CONTRACTORS = '/contractors',
    CONTACT = '/contact',
    STAFF = '/staff',
    MAIN = '/',
    CONTAINERS = '/containers',
    TERMINALS = '/terminals',
    DEPOT = '/depot',
    DEAL = '/deal'
}
