import React, { useEffect, useCallback, useMemo } from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Col, Flex, Select, Typography } from "antd";
import './style.scss';
import Tabs from "./tabs/Tabs";
import TabDeal from "./tabs/tabDeal";
import { useStoreDispatch } from "../../store";
import {getCurrency, getPercent} from "../../store/store";
import {findCompany, findMyCompany} from "../../store/contractors";
import TabDocument from "./tabs/tabDocument";
import DealContext from './context/DealContext';
import {generateIdDeal} from "../../store/deal";

interface FormDealProps {
    onClose?: () => void;
    onFinish?: () => void;
    title?: string;
}

const FormDeal = ({ onClose, onFinish, title }: FormDealProps) => {
    const [type, setType] = React.useState<string | null>(null);
    const dispatch = useStoreDispatch();

    const getFindCompany = useCallback((searchText: string) => {
        if (searchText.length > 2) {
            dispatch(findCompany(searchText));
        }
    }, [dispatch]);

    const selectedType = (val: string | null) => {
        setType(val);
      dispatch(generateIdDeal('П'))
    }

    const tabs = useMemo(() => [
        { label: 'Сделка', content: <TabDeal
                onClose={() => {
                    if (onClose) {
                        onClose()
                    }
                    }
                }
                getFindCompany={getFindCompany}/>
        },
        { label: 'Документы',
          content: <TabDocument
                onClose={() => {
                    if (onClose) {
                        onClose()
                    }
                    }
                } />
        },
    ], [getFindCompany]);

    useEffect(() => {
        dispatch(getCurrency());
        dispatch(findMyCompany());
        dispatch(getPercent());
    }, [dispatch]);

    return (
        <>
            <CloseOutlined className='close' onClick={onClose} />
            <Flex justify='space-between' className='drawer-header' align='center'>
                <Flex gap={10} align='center' style={{ marginTop: 15 }}>
                    <Typography.Text className='label-card-20'>{title}</Typography.Text>
                </Flex>
                <Col span={8}>
                    <Flex vertical>
                        <Typography.Text className='text-card-12'>Тип сделки</Typography.Text>

                        <Select placeholder="Выберите тип сделки" value={type} onChange={selectedType} options={[
                            {value: 'Доставка КТК', label: 'Доставка КТК'},
                            {value: 'Доставка груза', label: 'Доставка груза'},
                            {value: 'Закуп', label: 'Закуп'},
                            {value: 'Передислокация', label: 'Передислокация'},
                            {value: 'Внутрянка', label: 'Внутрянка'},
                            {value: 'Продление', label: 'Продление'},
                            {value: 'Эвакуация', label: 'Эвакуация'},
                            {value: 'Забрал клиент', label: 'Забрал клиент'},
                        ]}/>

                    </Flex>
                </Col>
                {/* <Flex vertical>
                    <Typography.Text className='text-card-12'>Статус</Typography.Text>
                    <Select
                        defaultValue="Новый"
                        style={{ width: 141 }}
                        options={[
                            { value: 'Новый', label: 'Новый' },
                            { value: 'Ожидаем оплаты', label: 'Ожидаем оплаты' },
                            { value: 'Выдан без оплаты', label: 'Выдан без оплаты' },
                            { value: 'Продан', label: 'Продан' },
                            { value: 'Просрочено', label: 'Просрочено' },
                        ]}
                    />
                </Flex> */}
            </Flex>
            <Flex className='drawer-header'>
                <DealContext.Provider value={{type, setType: selectedType}}>
                  <Tabs tabs={tabs} />
                </DealContext.Provider>
            </Flex>
        </>
    );
};

export default FormDeal;
