import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IAddComment, IContainers, IContainersPhoto, IContainersStatus} from "../types/containers";
import {Rest_containers} from "../services/rest_containers";


interface IContainersState {
    resultContainers: Array<IContainers>,
    resultContainersPhoto: Array<IContainersPhoto>,
    dataStatusContainer: Array<IContainersStatus>,
    totalData: 0,
    dataComment: [],
    dataFilters: [],
    dataHistory: []
}

const initialState: IContainersState = {
    resultContainers: [],
    resultContainersPhoto: [],
    dataStatusContainer: [],
    totalData: 0,
    dataComment: [],
    dataFilters: [],
    dataHistory: []
};

export const validCompanyContainer = createAsyncThunk(
    'validCompanyContainer',
    async (payload: {}) => {
        return await Rest_containers.validCompanyContainer(payload)
    }
)
export const setContainer = createAsyncThunk(
    'setContainer',
    async (payload: {}) => {
        return await Rest_containers.setContainer(payload)
    }
)
export const setContainerImport = createAsyncThunk(
    'setContainerImport',
    async (payload: {}) => {
        return await Rest_containers.setContainerImport(payload)
    }
)
export const findContainers = createAsyncThunk(
    'findContainers',
    async (payload: string) => {
        return await Rest_containers.findContainers(payload)
    }
)
export const findPhotoContainers = createAsyncThunk(
    'findPhotoContainers',
    async (payload: number) => {
        return await Rest_containers.findContainersPhoto(payload)
    }
)
export const getContainers = createAsyncThunk(
    'getContainers',
    async (payload: {}) => {
        return await Rest_containers.getContainers(payload)
    }
)
export const loadPhotoContainers = createAsyncThunk(
    'loadPhotoContainers',
    async (payload: any) => {
        return await Rest_containers.loadPhotoContainers(payload)
    }
)
export const getStatusContainer = createAsyncThunk(
    'getStatusContainer',
    async () => {
        return await Rest_containers.getStatusContainer()
    }
)
export const updateNewBooking = createAsyncThunk(
    'getStatusContainer',
    async (payload: IContainersStatus) => {
        return await Rest_containers.updateNewBooking(payload)
    }
)
export const addCommentContainer = createAsyncThunk(
    'addCommentContainer',
    async (payload: IAddComment) => {
        return await Rest_containers.addCommentContainer(payload)
    }
)
export const deleteCommentContainer = createAsyncThunk(
    'deleteCommentContainer',
    async (payload: IAddComment) => {
        return await Rest_containers.deleteCommentContainer(payload)
    }
)
export const getCommentContainer = createAsyncThunk(
    'getCommentContainer',
    async (id: number) => {
        return await Rest_containers.getCommentContainer(id)
    }
)
export const getActivityContainer = createAsyncThunk(
    'getActivityContainer',
    async (id: number) => {
        return await Rest_containers.getActivityContainer(id)
    }
)
export const updateRepair = createAsyncThunk(
    'updateRepair',
    async (payload: {}) => {
        return await Rest_containers.updateRepair(payload)
    }
)
export const deleteContainer = createAsyncThunk(
    'updateRepair',
    async (payload: {}) => {
        return await Rest_containers.deleteContainer(payload)
    }
)
export const searchContainer = createAsyncThunk(
    'searchContainer',
    async (payload: {}) => {
        return await Rest_containers.searchContainer(payload)
    }
)
export const filters = createAsyncThunk(
    'filters',
    async (payload: {}) => {
        return await Rest_containers.filters(payload)
    }
)
export const getHistoryContainer = createAsyncThunk(
    'getHistoryContainer',
    async (id: number) => {
        return await Rest_containers.getHistoryContainer(id)
    }
)
const containersSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        clearFilter(state) {
            state.dataFilters = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setContainer.fulfilled, (state, action) => {
            state.resultContainers = action.payload
        })
        builder.addCase(findContainers.fulfilled, (state, action) => {
            state.resultContainers = action.payload
        })
        builder.addCase(findPhotoContainers.fulfilled, (state, action) => {
            state.resultContainersPhoto = action.payload
        })
        builder.addCase(getContainers.fulfilled, (state, action) => {
            state.totalData = action.payload.count.count
            state.resultContainers = action.payload.data
        })
        builder.addCase(getStatusContainer.fulfilled, (state, action) => {
            state.dataStatusContainer = action.payload
        })
        builder.addCase(getCommentContainer.fulfilled, (state, action) => {
            state.dataComment = action.payload
        })
        builder.addCase(filters.fulfilled, (state, action) => {
            state.dataFilters = action.payload.rows
        })
        builder.addCase(getHistoryContainer.fulfilled, (state, action) => {
            state.dataHistory = action.payload
        })
    },
});
export default containersSlice.reducer;
export const { clearFilter } = containersSlice.actions
