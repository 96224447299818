import Icon, {MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from "@ant-design/icons";
import {
    Avatar, Badge, Button, Divider,
    Flex,
    Layout,
    MenuProps,
    theme,
} from "antd";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../assets/css/layouts.scss';
import exitIcon from '../assets/image/exit.svg';
import Image from '../assets/image/Image.png';
import { notificationIcon } from "../assets/image/svg";
import { Helpers } from "../helpers";
import { RouteNames } from "../router/names";
import socket from "../socket";
import { RootState, useStoreDispatch } from "../store";
import { setDrawer } from "../store/store";
import { entitiesInfo, setAuth } from "../store/users";
import { Utils } from "../utils";
import Headers from "./Header";
import Menus from "./Menu/Menu";
import DrawerRight from "./ui/DrawerRight/DrawerRight";

const {Sider, Content} = Layout;


const Layouts = (props: any) => {
    const router = useHistory();
    const {token: {colorBgContainer}} = theme.useToken();
    const dispatch = useStoreDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const isDrawer: boolean = useSelector((state: RootState) => state.stores.isDrawer);
    const dataMenu: any = useSelector((state: RootState) => state.stores.dataMenu);
    const breadcrumb: string = useSelector((state: RootState) => state.stores.breadcrumb);
    const [name, setName] = useState('');

    const [isTerm,setIsTerm] = useState(false);
    const [isContact,setIsContact] = useState(false);
    const [isCompanys,setIsCompanys] = useState(false);
    const [isStaff,setIsStaff] = useState(false);
    const [isTerminalTable,setIsTerminalTable] = useState(false);
    const [isContainers,setIsContainers] = useState(false);
    const [isListTerminals,setIsListTerminals] = useState(false);
    const [isLoad,setLoad] = useState(false);

    const onClick: MenuProps['onClick'] = (e): void => {
        switch (e.key) {
            case 'finance':
                router.push(RouteNames.STAFF);
                break;
            case 'depot':
                router.push(RouteNames.DEPOT);
                break;
            case 'terminals':
                router.push(RouteNames.TERMINALS);
                break;
            case 'containers':
                router.push(RouteNames.CONTAINERS);
                break;
            case 'logistics':
                router.push(RouteNames.STAFF);
                break;
            case 'deal':
                router.push(RouteNames.DEAL);
                break;
            case '/':
                router.push(RouteNames.CONTRACTORS)
        }
    }

    const exit = () =>{
        localStorage.removeItem('_in');
        dispatch(setAuth(false))
        router.push(RouteNames.LOGIN);
    }

    useEffect(() => {
        const loadMenuLocal = async () => {
            setIsCompanys(await Helpers.localGroupEntities('companys'))
            setIsContact(await Helpers.localGroupEntities('contacts'));
            setIsStaff(await Helpers.localGroupEntities('staff'));
            setIsTerm(await Helpers.localGroupEntities('term'));
            setIsContainers(await Helpers.localGroupEntities('containers'));
            setIsTerminalTable(await Helpers.localGroupEntities('terminalTable'));
            setIsListTerminals(await Helpers.localGroupEntities('terminals'));
            setLoad(true)
        }
        if (Utils.getStorage('ent') === null) {
            dispatch(entitiesInfo(Utils.role())).then(async (response: any) => {
                if (Utils.role().id === response.payload.role_id) {
                    await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(response.payload.res_client)));
                    const resLoc = await Utils.getStorage('ent');
                    if (resLoc !== null){
                        loadMenuLocal();
                    }
                }
            })
        } else {
            loadMenuLocal();
        }
        socket.once("res-update-group-entities", async (response: any) => {
            if (Utils.role().type === 'group') {
                if (Utils.role().id === response.role_id) {
                    await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(response.res_client)));
                    const resLoc = await Utils.getStorage('ent');
                    if (resLoc !== null){
                        loadMenuLocal();
                    }
                }
            }
        });
        socket.once("res-update-entities", async (response: any) => {
            if (Utils.role().type === 'group') {
                const loc = await Utils.decrypt(await localStorage.getItem('ent'));
                loc.map((item: any) =>{
                    response.res_client.map((el: any) =>{
                        if (el.entities === item.entities && el.status){
                            item.status = el.status;
                        }
                    })
                })
                await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(loc)));
                const resLoc = await Utils.getStorage('ent');
                if (resLoc !== null) {
                    loadMenuLocal();
                }
            }else {
                await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(response.res_client)));
                const resLoc = await Utils.getStorage('ent');
                if (resLoc !== null) {
                    loadMenuLocal();
                }
            }
        })

        const localUser = async () => {
            if (Utils.getStorage('_in') !== null) {
                const user = await Utils.decrypt(Utils.getStorage('_in'))
                setName(user.name === null ? 'Гость' : user.name + ' ' + user.last_name)
            }
        }
        localUser();
    }, []);

    return (
        <Layout className={'layout'}>
            <Sider
                trigger={null}
                collapsible
                collapsed={isDrawer}
                collapsedWidth={60}
                className={'sider'} width={252}
            >
                <Flex justify="space-between" align="center" className={'sider avatar'}>
                    <Flex
                        justify="space-between"
                        align="center"
                        gap={10}
                        style={!isDrawer ? {marginLeft: 8, marginTop: 8, marginBottom: 13} : {
                            marginTop: 8, marginBottom: 7,
                            width: "100%", justifyContent: "center"
                        }}
                    >
                        <Avatar size={!isDrawer ? 36 : 42} src={
                            Utils.getUserInfo().avatar !== null &&  Utils.getUserInfo().avatar
                        } icon={<UserOutlined/>}/>
                        {!isDrawer &&
                        <h3 style={{margin: 0}}>{name} </h3>
                        }
                    </Flex>
                    {!isDrawer &&
                    <Button
                        type="text"
                        style={{marginRight: 10}}
                        icon={isDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => dispatch(setDrawer(true))}
                    />
                    }
                </Flex>
                <Flex
                    justify={"flex-start"}
                    align={'center'}
                    style={{height: 61, padding: '5px 11px', borderBottom: '1px solid #D8D8D8'}}
                    gap={8}
                >
                    <Badge count={5} size="small">
                        <Icon component={notificationIcon}/>
                    </Badge>
                    {!isDrawer &&
                        <div className={'text-notification'}>Уведомления</div>
                    }
                </Flex>
                {isLoad &&
                    <Menus
                        onClick={onClick}
                        data={dataMenu}
                        isContact={isContact}
                        isTerm={isTerm}
                        isCompanys={isCompanys}
                        isStaff={isStaff}
                        isTerminalTable={isTerminalTable}
                        isContainers={isContainers}
                        isListTerminals={isListTerminals}
                    />
                }
                <Divider style={{marginTop: 6, marginBottom: 6}}/>
                <Flex
                    justify={"flex-start"}
                    align={'center'}
                    style={{height: 61, padding: '5px 11px',cursor: 'pointer'}} gap={8}
                >
                        <Avatar shape="circle" src={exitIcon}/>
                    {!isDrawer &&
                        <div className={'text-notification'} onClick={exit}>Выход</div>
                    }
                </Flex>
            </Sider>
            <Layout>
                <Flex justify={"space-between"}  align={"center"} style={{width: '100%'}} gap={8}>
                    <Headers setCollapsed={setCollapsed} collapsed={collapsed} title={breadcrumb} />
                </Flex>
                <Content className={"content"}>
                    <div>{props.children}</div>
                </Content>
                <DrawerRight col={8}/>
            </Layout>
        </Layout>
    );
};

export default Layouts;
