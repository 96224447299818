import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
  AutoComplete,
  Dropdown,
  Flex,
  Form,
  FormListFieldData,
  InputNumber,
  MenuProps,
  message,
  Select,
  Space,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import style from '../../../style.module.scss';
import { messagesModal } from '../../../../../constants';
import { moreIcon } from '../../../../../assets/image/svg';
import { IContainer, ITabForm, IValues } from '../../types';
import { findContainers } from '../../../../../store/containers';
import { fileTypes } from '../../../../../helpers/input-helpers';
import { RootState, useStoreDispatch } from '../../../../../store';
import { setIsEditDealContainers } from '../../../../../store/deal';
import UploaderDocs from '../../../../../components/ui/UploaderDocs';
import ModalConfirm from '../../../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../../../components/ui/TooltipButton/TooltipButton';

const { title, subtitle } = messagesModal.delete;

const checkEditContainers = (containers: IContainer[]) =>
  containers.some((item) => !!item?.isEdit);

const TabDoc: React.FC<ITabForm> = ({ form, isOpen }) => {
  const observedDiv = useRef<HTMLDivElement>(null);
  const containers: IContainer[] = Form.useWatch('containers', {
    form,
    preserve: true,
  });
  const [options, setOptions] = React.useState<IContainer[] | []>([]);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [valuesPrice, setValuesPrice] = React.useState<IValues>({});
  const [values, setValues] = React.useState<IValues>({});
  const dispatch = useStoreDispatch();
  const [value, setValue] = useState<string>('');
  const [indexCurrent, setIndexCurrent] = useState<number>(0);
  const isEditDeal = useSelector<RootState>((state) => state.deal.isEditDeal);
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.deal.containerDealStatuses,
  ) as { id: number; value: string }[];

  const removeField = (remove: (index: number) => void) => {
    remove(indexCurrent);
    setIndexCurrent(0);
  };

  const editContainer = (
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];

    setValuesPrice((prev) => ({
      ...prev,
      [index]: container?.price,
    }));

    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...container,
        price: container?.price,
        status: container?.status,
        status_id: container?.status_id,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], isEdit: true } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };

  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ): MenuProps['items'] => [
    {
      key: 1,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: 'Редактировать' }}
          propsButton={{
            onClick: () => editContainer(edit, index),
            icon: <EditOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
    {
      key: 2,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: 'Удалить' }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
  ];

  const addRow = (
    val: string,
    addForm: (defaultValue?: any, insertIndex?: number) => void,
    key: string,
  ) => {
    const container: IContainer | undefined = options?.find(
      (opt: IContainer) => opt?.num_container === val,
    );
    const isUnique = !containers?.some(
      (item: any) => item?.num_container === val,
    );
    if (isUnique && container) {
      const containers = {
        num_container: val,
        type: container?.type_container,
        status: null,
        status_id: null,
        bet: container?.price ?? null,
        price: '',
        isEdit: false,
      };
      setValues((prev) => ({ ...prev, [val]: {} }));
      setValue('');
      addForm({ ...containers }, 0);
    } else {
      message.warning('Этот контейнер уже добавлен!');
      setValue('');
    }
  };

  const handleContainer = (data: any) => {
    setOptions([]);
    setValue(data);
  };

  const getFindContainer = (text: string) => {
    if (text?.length >= 2) {
      dispatch(findContainers(text))
        .then((res) => setOptions(res.payload))
        .catch((err) => console.log('ошибка запроса', err));
    }
  };

  const submitContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];
    const price = valuesPrice[index] || '';
    const status =
      containerDealStatuses.find(({ id }) => id === container?.status_id)
        ?.value || null;
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        price,
        status,
        status_id: container?.status_id,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, status, price, isEdit: false } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };
  const closeEditContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = { ...values[index] };
    setValuesPrice((prev) => ({
      ...prev,
      [index]: container?.price,
    }));
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        price: container?.price,
        status: container?.status,
        status_id: container?.status_id,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], isEdit: false } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };


  return (
    <Flex gap={30} vertical>
      <Form.Item name="docs" className={style['file-box']}>
        <UploaderDocs
          isOpen={isOpen}
          ids={form.getFieldValue('docs')}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
          setDataId={(files) => form.setFieldValue('docs', files)}
          isRemoved={!isEditDeal}
        />
      </Form.Item>
      <div className={style['overlap-group-deal']}>
        <div className={style['table']}>
          <div className={style['table__container']}>
            <div className={style['table__header']}>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                № КТК
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Тип
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Статус
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Входящая ставка
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Стоимость
              </div>
              <div className={style['menu-icon']}>
                <div className={style['label-card-16']}>
                  <Dropdown
                    menu={{ items: [{
                      key: 'link1',
                      label: (
                        <Typography.Text
                          data-id="link"
                          className={style['menu-icon__link']}
                        >
                          Загрузить в Excel
                        </Typography.Text>
                      ),
                    },
                    {
                      key: 'link2',
                      label: (
                        <Typography.Text
                          data-id="link"
                          className={style['menu-icon__link']}
                        >
                          Выгрузить в Excel
                        </Typography.Text>
                      ),
                    },
                  ], }}
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <Typography.Link className="" onClick={(e) => e.preventDefault()}>
                      <Icon component={moreIcon} />
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Form.List name="containers">
              {(
                fields: FormListFieldData[],
                operation,
                meta: { errors: any },
              ) => (

                <div className={style['table__body']}>
                  <AutoComplete
                    value={value}
                    placeholder={'Введите № КТК'}
                    style={{ width: '26%', minWidth: '110px' }}
                    options={options?.map((opt: IContainer) => ({
                      id: opt?.id,
                      value: opt?.num_container,
                    }))}
                    onSelect={(val) =>
                      addRow(val, operation?.add, `${fields.length}`)
                    }
                    onChange={handleContainer}
                    onSearch={(text: string) => getFindContainer(text)}
                    maxLength={11}
                    allowClear
                  />

                    {fields.map(({ ...field }) => (
                      <div key={field.key} className={style['table__row']} data-row="row" ref={observedDiv}>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {
                            form.getFieldValue('containers')?.[field.name]?.[
                              'num_container'
                            ]
                          }
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {
                            form.getFieldValue('containers')?.[field.name]?.[
                              'type'
                            ]
                          }
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {form.getFieldValue('containers')?.[field.name]?.[
                            'isEdit'
                          ] ? (
                            <Form.Item name={[field.name, 'status_id']}>
                              {
                                <Select
                                  placeholder="Добавьте статус"
                                  style={{ width: '100%' }}
                                  options={containerDealStatuses?.map(
                                    ({ id, value }) => ({
                                      value: id,
                                      label: value,
                                    }),
                                  )}
                                />
                              }
                            </Form.Item>
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'leftBottom',
                                  title:
                                    form.getFieldValue('containers')?.[
                                      field.name
                                    ]?.status,
                                },
                              }}
                            >
                              {form.getFieldValue('containers')?.[field.name]
                                ?.status || 'Добавьте статус'}
                            </Typography.Text>
                          )}
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          <span>
                            {form.getFieldValue('containers')?.[field.name]?.[
                              'bet'
                            ] ?? ''}
                          </span>
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {form.getFieldValue('containers')?.[field.name]?.[
                            'isEdit'
                          ] ? (
                            <Space>
                              <Form.Item name={[field.name, 'price']} noStyle />
                              <InputNumber<number>
                                className={style.input}
                                placeholder="Добавьте цену"
                                value={valuesPrice?.[field.name]}
                                controls={false}
                                min={0}
                                maxLength={15}
                                onChange={(val) => {
                                  setValuesPrice((prev) => ({
                                    ...prev,
                                    [field.name]: val,
                                  }));
                                }}
                              />
                              {valuesPrice[field.name] ? (
                                <CloseCircleOutlined
                                  className={style.input__icon}
                                  onClick={() =>
                                    setValuesPrice((perv) => ({
                                      ...perv,
                                      [field.name]: '',
                                    }))
                                  }
                                />
                              ) : null}
                            </Space>
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'topLeft',
                                  title:
                                    form.getFieldValue('containers')?.[
                                      field.name
                                    ]?.price,
                                },
                              }}
                            >
                              {form.getFieldValue('containers')?.[field.name]
                                ?.price || 'Добавьте цену'}
                            </Typography.Text>
                          )}
                        </div>
                        <Flex className={style['table__menu']} align="center">
                          <div className={style['text-card-14']}>
                            {form.getFieldValue('containers')?.[field.name]?.[
                              'isEdit'
                            ] ? (
                              <Dropdown
                                open
                                menu={{
                                  items: [
                                    {key: '1', label: (<TooltipButton
                                    propsTooltip={{ title: 'Сохранить' }}
                                    propsButton={{
                                      className: style['table__button'],
                                      shape: 'circle',
                                      icon: <CheckOutlined />,
                                      onClick: () => submitContainer(field.name),
                                    }}
                                  />)}, {
                                    key: '2',
                                    label: (
                                      <TooltipButton
                                      propsTooltip={{ title: 'Закрыть' }}
                                      propsButton={{
                                        className: style['table__button'],
                                        shape: 'circle',
                                        icon: <CloseOutlined />,
                                        onClick: () =>
                                          closeEditContainer(field.name),
                                      }}
                                    />
                                    )
                                  }
                                  ],
                                  style: {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                  },
                                }}
                                placement="bottom"
                                trigger={['click']}
                              >
                                <Typography.Link
                                  className=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon component={moreIcon} />
                                </Typography.Link>
                              </Dropdown>
                            ) : (
                              <Dropdown
                                menu={{
                                  items: getItems(
                                    () => {
                                      setConfirmation(true);
                                      setIndexCurrent(field.name);
                                    },
                                    operation.add,
                                    field.name,
                                  ),
                                  style: {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                  },
                                }}
                                placement="bottom"
                                trigger={['click']}
                              >
                                <Typography.Link
                                  className=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon component={moreIcon} />
                                </Typography.Link>
                              </Dropdown>
                            )}
                          </div>
                        </Flex>
                      </div>
                    ))}
                <ModalConfirm
                  title={title}
                  subtitle={subtitle}
                  isOpen={confirmation}
                  closeModal={() => setConfirmation(false)}
                  actionAfterConsent={() => removeField(operation.remove)}
                />
                </div>
              )}
            </Form.List>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default TabDoc;
