// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content--page {
  margin: 16px;
  background: white;
  border-radius: 10px;
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/layouts.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".content--page{\n  margin: 16px;\n  background: white;\n  border-radius: 10px;\n  padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
