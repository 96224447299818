import React, {ChangeEvent, CSSProperties, MouseEvent, useEffect, useState} from 'react';
import {Input, Select, Space} from "antd";
import Button from "../Button/Button";
import '../../../assets/css/select-search.scss'
import {SearchOutlined} from "@ant-design/icons";

export interface Props<T> {
    style?: CSSProperties,
    size?: string,
    dataSource: IOption[] | [],
    onClick?: (e: MouseEvent) => void,
    onChangeSelect?: (e: number, option: any) => void,
    dropdownRender?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
    onClose?: () => void,
    placeholder?: string,
    defaults?: string | null,
    hiddenBtn?: boolean,
    type?: string,
    notFoundContent?: any
}

interface IOption {
    label?: string | undefined
    value?: string | number | undefined
    child_terminal?: { name: string } | undefined
    avatar?: string,
    lat?: string | number | undefined
    lon?: string | number | undefined
}

export function SelectSearch<T>({
                                    dataSource,
                                    onChangeSelect,
                                    dropdownRender,
                                    onClick,
                                    onClose,
                                    placeholder,
                                    defaults,
                                    style,
                                    hiddenBtn,
                                    type,
                                    notFoundContent
                                }: Props<T>): JSX.Element {
    const [defValue, setDefValue] = useState<string | null>(null);
    const stateTasksOptions =
        type === 'page' ? (
            dataSource.map((item: any, parentIndex) => (
                <React.Fragment key={`parent-${item.value}-${parentIndex}`}>
                    <Select.Option value={item.value} data-avatar={item.avatar}>
                        {item.label}
                    </Select.Option>
                    {Object.keys(item)
                        .filter(key => key.startsWith('childName'))
                        .map((childKey, childIndex) => (
                            <Select.Option key={`child-${item.value}-${parentIndex}-${childIndex}`}
                                           value={JSON.stringify({
                                               value: item.value,
                                               child: item[childKey].id
                                           })}>
                                <span style={{float: "right"}}>{item[childKey].child}</span>
                            </Select.Option>
                        ))}
                </React.Fragment>
            ))
        ) : (
            dataSource.map((item: any) =>
                !item.child &&
                <Select.Option key={item.value} value={item.value}>
                    { <span>{item.label}</span> }
                </Select.Option>
            ))

    useEffect(() => {
        if (defaults) {
            setDefValue(defaults);
        } else {
            setDefValue(null)
        }
    }, [defaults]);

    const handleChange = (e: any, option: any) => {
        const avatar = option.props['data-avatar'];
        const selectedOption: IOption = { ...option.props, avatar };

        if (onChangeSelect) {
            onChangeSelect(e, selectedOption);
        }
        setDefValue(option.children);
        if (typeof option.children === 'object') {
            setDefValue(option.children.props.children)
        }else {
            setDefValue(option.children)
        }
    }

    return (
        <>
            <Select style={style}
                    allowClear
                    onChange={(e: any, option: any) => handleChange(e, option)}
                    defaultValue={defValue}
                    value={defValue}
                    placeholder={placeholder}
                    notFoundContent={notFoundContent}
                    dropdownRender={(menu) => (
                        <Space className={'space'} direction={'vertical'}>
                            <Input
                                allowClear
                                prefix={<SearchOutlined/>}
                                className={'input'}
                                placeholder="Поиск"
                                onChange={dropdownRender}
                            />
                            {menu}
                            {hiddenBtn &&
                                <Button type="primary" style={{width: '100%'}} onClick={onClick}>
                                    Добавить терминал
                                </Button>
                            }
                        </Space>
                    )}>
                {stateTasksOptions}
            </Select>
        </>
    );
};
