import {Key, ReactNode, useEffect, useRef} from 'react';
import {Menu, MenuProps} from "antd";
import Icon from '@ant-design/icons';
import {depoIcon, financeIcon, logisticsIcon, rentIcon, saleIcon, userIcon} from "../../assets/image/svg";
import '../../assets/css/menu.scss'
import {useTranslation} from "react-i18next";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: ReactNode,
    key: Key,
    icon?: ReactNode,
    children?: MenuItem[] | null,
    style?: boolean | { display: string },
    type?: "group",
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        style,
        type,
    } as MenuItem;
}

const Menus = (props: any) => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const items: MenuProps['items'] = [
        getItem('CRM', '/', <Icon component={userIcon}/>, null, !props.isCompanys && {display: "none"}),
        getItem(t('Depot'), 'depot',  <Icon component={depoIcon}/>,
            [getItem(t('Terminals'),'terminals',null,null,!props.isStaff && {display: "none"}),
                    getItem(t('Containers'),'containers',null,null,!props.isStaff && {display: "none"})],
                  !props.isStaff && {display: "none"}),
        getItem(t('Sales'), 'sale',  <Icon component={saleIcon}/>,
            [getItem(t('Deal'),'deal',null,null,!props.isStaff && {display: "none"})],
            !props.isStaff && {display: "none"}),
        getItem(t('Rent'), 'rent',  <Icon component={rentIcon}/>, null, !props.isStaff && {display: "none"}),
        getItem(t('Logistics'), 'logistics',  <Icon component={logisticsIcon}/>, null, !props.isStaff && {display: "none"}),
        getItem(t('Finance'), 'finance',  <Icon component={financeIcon}/>, null, !props.isStaff && {display: "none"}),
    ]

    return (
        <Menu
            ref={ref}
            mode="inline"
            defaultOpenKeys={['depot','sale']}
            onClick={props.onClick}
            style={{background: '#FFFFFF',borderInlineEnd: 0 }}
            items={items}
        />
    );
};

export default Menus;
