import React from 'react';
import {Button, Modal} from "antd";
import Icon from "@ant-design/icons";
import {CloseButton} from "./CloseButton";
import {errorImportIcon} from "../../../assets/image/svg";
import './style.scss'


interface ModalConfirmProps {
    actionAfterConsent?: () => void;
    actionAfterReject?: () => void;
    isOpen: boolean;
    closeModal: () => void;
    title?: string;
    subtitle?: string;
}

const ModalConfirm = ({isOpen, closeModal, actionAfterConsent, actionAfterReject, title = 'Поставить на удаление', subtitle = 'Уверены, что хотите поставить на удаление?'}: ModalConfirmProps) => {

    const onCloseModalConfirm = () => {
        closeModal();
        if (typeof actionAfterReject  === 'function') {
            actionAfterReject();
        }
    }

    const onOkModalConfirm = () => {
        closeModal();
        if (typeof actionAfterConsent  === 'function') {
            actionAfterConsent();
        }

    }
    return (
        <Modal closable={false} footer={null} open={isOpen} centered>
            <div className="screen">
                <div className="icon-container">
                    <Icon component={errorImportIcon} className="element-alert-attention"/>
                </div>
                <div className="content-confirm">
                    <div className="body">
                        <div className="text">
                            <div className="header">{title}</div>
                            <p className="subtitle">
                                {subtitle}
                            </p>
                        </div>
                        <CloseButton className="close-button-instance" size="s" state="default"
                                    onCloseModalConfirm={onCloseModalConfirm}/>
                    </div>
                    <div className="action-buttons">
                        <div className="function-button">
                            <div className="label-wrapper">
                                <Button onClick={onOkModalConfirm}>Да</Button>
                            </div>
                        </div>
                        <div className="div">
                            <div className="label-wrapper">
                                <Button onClick={onCloseModalConfirm}>Нет</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirm;
