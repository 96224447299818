import {Card, Flex, Form, Input, Modal, Table, TableColumnsType, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from 'react';
import Button from "../../components/ui/Button/Button";

interface DataType {
    key: React.Key;
    name: string;
    meaning: string;
}

const columns: TableColumnsType<DataType> = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'meaning',
        dataIndex: 'meaning',
    },
];
const {Text} = Typography;

const FormRequisites = (props: any) => {
    const [formReq] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataBank, setDataBank] = useState<any>([]);

    useEffect(() => {
        setDataBank(props.bank);
    }, [props.bank]);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        formReq.resetFields()
        setIsModalOpen(false);
    };
    const onFinishBank = (data: any) => {
        const arResult: DataType[] = [];
        [data].forEach((el: any) => {
            arResult.push({key: 1, name: 'Название', meaning: el?.title})
            arResult.push({key: 2, name: 'Наименование банка', meaning: el?.name_bank})
            arResult.push({key: 3, name: 'БИК', meaning: el?.bik})
            arResult.push({key: 4, name: 'Расчетный счёт', meaning: el?.payment_account})
            arResult.push({key: 5, name: 'Кор. счёт', meaning: el?.cor_account})
            arResult.push({key: 6, name: 'Валюта счёта', meaning: el?.account_currency})
            arResult.push({key: 7, name: 'Адрес банка', meaning: el?.address_bank})
            arResult.push({key: 8, name: 'Комментарий', meaning: el?.comment})
        });
        props.setDataBankRequisites(arResult);
        setDataBank(arResult);
        formReq.resetFields();
        setIsModalOpen(false);
    }

    return (
        <Flex vertical>
            <Flex justify={'flex-start'} className={'drawer-header'} gap={20}>
                <LeftOutlined onClick={props.onClose}/>
                <div className={'drawer-title'}>{props.title}</div>
            </Flex>
            <Flex style={{margin: 16}} vertical gap={10}>
                <Card
                    title={'Реквизиты'} style={{margin: 0}}
                    extra={ <Text style={{color: '#05F',cursor: "pointer"}}>Редактировать</Text>}>
                    {(props.reg !== null && props.reg !== undefined) && Object.keys(props.reg).length > 0 &&
                        <Table
                            columns={columns}
                            dataSource={props.reg}
                            size="small"
                            showHeader={false}
                            pagination={false}
                        />
                    }
                </Card>
                <Card
                    title={'Банковские реквизиты'} style={{margin: 0}}
                    extra={<Button type={'link'} onClick={showModal}>Добавить</Button>}>
                    {(props.bank !== null && props.bank !== undefined) && Object.keys(props.reg).length > 0 &&
                        <Table
                            columns={columns}
                            dataSource={dataBank}
                            size="small"
                            showHeader={false}
                            pagination={false}
                        />
                    }
                </Card>

                <Modal
                    title="Реквизиты"
                    open={isModalOpen}
                    onOk={formReq.submit}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form
                        style={{margin: 20}}
                        layout="vertical"
                        form={formReq}
                        onFinish={onFinishBank}
                        autoComplete="off"
                        initialValues={{
                            title: null,
                            name_bank: null,
                            bik: null,
                            payment_account: null,
                            cor_account: null,
                            account_currency: null,
                            address_bank: null,
                            comment: null
                        }}>
                        <Flex gap={10} vertical>
                            <div>
                                <Form.Item label="Название" name="title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Наименование банка" name="name_bank">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="БИК" name="bik">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Расчетный счёт" name="payment_account">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Кор. счёт" name="cor_account">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Валюта счёта" name="account_currency">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Адрес банка" name="address_bank">
                                    <Input.TextArea autoSize/>
                                </Form.Item>
                                <Form.Item label="Комментарий" name="comment">
                                    <Input.TextArea autoSize/>
                                </Form.Item>
                            </div>
                            <Flex justify={'flex-end'} style={{width: '100%'}}>
                                <Form.Item>
                                    <Flex gap={10}>
                                        <Button onClick={handleCancel}>Закрыть</Button>
                                        <Button type="primary" >Сохранить</Button>
                                    </Flex>
                                </Form.Item>
                            </Flex>
                        </Flex>
                    </Form>
                </Modal>
            </Flex>
        </Flex>
    );
};

export default FormRequisites;
