import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import Container from "../../components/Container";
import {AutoComplete, Button, Flex, Form, Input, message, Modal, notification, Space} from "antd";
import {RootState, useStoreDispatch} from "../../store";
import "../../assets/css/terminal.scss";
import {getTerminalCity} from "../../store/terminals";
import socket from "../../socket";
import Icon, {PlusOutlined} from "@ant-design/icons";
import DrawerComponent from "../../components/DrawerComponent";
import FormContainer from "./formContainer";
import {typeContainers, typeQuality} from "../../store/types";
import {useSelector} from "react-redux";
import {getCurrency} from "../../store/store";
import {IColumnType, IPagination, Table} from "../../components/ui/Table/Table";
import {
    deleteContainer, filters,
    getActivityContainer,
    getCommentContainer,
    getContainers, getHistoryContainer,
    getStatusContainer, searchContainer,
    setContainer
} from "../../store/containers";
import {columns} from "./columns";
import FormModalContainer from "./formModalContainer";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {blackCloseIcon} from "../../assets/image/svg";
import {Utils} from "../../utils";
import moment from "moment";
import FormModalImport from "./formModalImport";
import {TerminalService} from "./service";
import {IActivityContainer} from "../../types/containers";
import {useCsvExport} from "../../hooks";

const Containers: React.FC = () => {
    const dispatch = useStoreDispatch();
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const [form] = Form.useForm();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isModalOpenContainer, setIsModalOpenContainer] = useState<boolean>(false);
    const [isModalOpenImport, setIsModalOpenImport] = useState<boolean>(false);
    const [api, contextHolder] = notification.useNotification();
    const [openDrawerContainer, setOpenDrawerContainer] = useState<boolean>(false);
    const dataTypeQuality = useSelector((state: RootState) => state.types.dataTypeQuality);
    const dataTypeContainers = useSelector((state: RootState) => state.types.dataTypeContainers);
    const dataTypePhone = useSelector((state: RootState) => state.types.dataTypePhone);
    const dataTypeEmail = useSelector((state: RootState) => state.types.dataTypeEmail);
    const dataStatusContainer = useSelector((state: RootState) => state.containers.dataStatusContainer);
    const dataCurrency = useSelector((state: RootState) => state.stores.dataCurrency);
    const dataTypeMessenger = useSelector((state: RootState) => state.types.dataTypeMessenger);
    const [current, setCurrent] = useState<number>(1);
    const [page, setPage] = useState<number>(20);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>(Utils.defaultTable);
    const [dataRowContainer, setDataRowContainer] = useState<any[]>([]);
    const [cityData, setDataCity] = useState<any>([]);
    const [nameTerminal, setNameTerminal] = useState<string | null>(null);
    const searchParams = new URLSearchParams(location.search);
    const [typeForm, setTypeForm] = useState<string>('');
    const [loadings, setLoadings] = useState<boolean>(false);
    const [dataComment, setDataComment] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [activeButtons, setActiveButtons] = useState<any>({});
    const [searchColumn, setSearchColumn] = useState<any[]>([]);
    const [activityContainer, setActivityContainer] = useState<IActivityContainer[]>([]);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const { generateCSV, copyToClipboard } = useCsvExport({
        fields: columns
            .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
            .map((item: any) => item.dataIndex) // Создаем массив из dataIndex
    });

    const handleSearch = async (inputValue: string) => {
        setLoading(true);
        setSelectedOption(inputValue);
        const commonParams = [
            { page: page },
            { current: (current - 1) * page, page: page }
        ];

        const executeSearch = async (params: any) => {
            try {
                const response = await dispatch(searchContainer(params));
                setData([]);
                setData(response.payload);
            } finally {
                setLoading(false);
            }
        };

        // Если inputValue пусто, выполняем запрос с пустым массивом
        if (inputValue === '') {
            setSelectedOption(null);
            setSearchColumn([]);
            await executeSearch([
                { page: page },
                { current: current }
            ]);
            return;
        }

        // Если searchColumn пуст, выполняем поиск по всем колонкам, кроме исключенных
        if (searchColumn.length === 0) {
            const searchParams = columns
                .filter((item: any) =>
                    item.dataIndex !== 'date_entry' &&
                    item.dataIndex !== 'arrival_date' &&
                    item.dataIndex !== 'departure_date' &&
                    item.dataIndex !== 'booking_before' &&
                    item.dataIndex !== 'hidden'
                )
                .map((item: any) => ({ [item.dataIndex]: inputValue }));

            const finalSearchParams = [
                ...searchParams,
                ...commonParams
            ];

            await executeSearch(finalSearchParams);
            return;
        }

        // Если есть колонки для поиска и inputValue длиннее 1 символа
        if (searchColumn.length > 0 && inputValue.length > 1) {
            const searchParams = searchColumn.map((item) => ({ [item]: inputValue }));

            const finalSearchParams = [
                ...searchParams,
                ...commonParams
            ];

            await executeSearch(finalSearchParams);
        } else {
            setLoading(false);
        }
    };

    const handleButtonClickSearch = (option: any) => {
        setActiveButtons((prevActiveButtons: any) => ({
            ...prevActiveButtons,
            [option.key]: !prevActiveButtons[option.key],
        }));
        setSearchColumn((prevData: any) => [option.dataIndex, ...prevData]);
    };

    const fetchData = (current: number,
                       page: number,
                       clear: string,
                       order: string) => {
        setLoading(true)
        dispatch(getContainers({current: (current - 1) * page, page: page,
            child: clear === 'fetch' ? Number(searchParams.get('child')) : 0,
            city: clear === 'fetch' ? Number(searchParams.get('city')) : 0,
            order
        })).then(response => {
            if (response.payload.success === 1) {
                setData(response.payload.data);
                setTotal(response.payload.count)
                setLoading(false)
            } else {
                message.error(response.payload.message);
                setLoading(false)
            }
        }).catch(error => console.error(error));
    };

    const onChangePagination = (page: IPagination<any>) => {
        if (page.current  != null && page.pageSize != null) {
            setCurrent(page.current);
            setPage(page.pageSize);
            fetchData(page.current, page.pageSize, '',"DESC")
        }
    }

    useEffect(() => {
        setNameTerminal(searchParams.get('name'))
        dispatch(getTerminalCity()).then(response =>{setDataCity(response.payload)})
        dispatch(typeQuality())
        dispatch(typeContainers())
        dispatch(getCurrency())
        dispatch(getStatusContainer())
        fetchData(current, page,'fetch','DESC');
        socket.on("res-update-new-booking", handleNewBooking);
        socket.on("res-set-container", handleNewContainer);
        socket.on("res-set-container-import", resContainerImport);
        socket.on("res-delete-container", resHandleDeleteRow);
        socket.on("res-update-repair", resUpdateRepair);

        return () => {
            socket.off("res-update-new-booking", handleNewBooking);
            socket.off("res-set-container", handleNewContainer);
            socket.off("res-set-container-import", resContainerImport);
            socket.off("res-delete-container", resHandleDeleteRow);
            socket.off("res-update-repair", resUpdateRepair);
        };
    }, [current, page,typeForm]);

    const resUpdateRepair = (response: any) => {
        setActivityContainer(response.data)
        setData((prevData: any) => {
            return prevData.map((row: any) => {
                // Найти соответствующий элемент в response.data
                const matchedElement = response.data.find((el: any) => row.id === el.container_id);
                if (matchedElement) {
                    // Обновить поле repair, если найдено совпадение
                    return {
                        ...row, // Скопировать остальные свойства строки
                        repair: matchedElement.repair // Обновить значение repair
                    };
                }
                return row; // Если совпадение не найдено, вернуть оригинальную строку
            });
        });
    }

    const resHandleDeleteRow = (response: any) => {
        setData(prevData => {
            // Проверьте, что response является массивом, если это необходимо
            if (!Array.isArray([response])) {
                console.error('Invalid response data:', [response]);
                return prevData;
            }

            // Предположим, что response это объект с обновленными данными
            const updatedItem = response;
            return prevData.map(item =>
                item.id === updatedItem.id ? { ...item, ...updatedItem } : item
            );
        });
    };

    const resContainerImport = (response: any) =>{
        setData(prevData => {
            const updatedData = [...prevData];

            response.data.forEach((newItem: any) => {
                const index = updatedData.findIndex(item => item.id === newItem.id);
                if (index !== -1) {
                    // Обновляем существующий объект
                    updatedData[index] = newItem;
                } else {
                    // Добавляем новый объект
                    updatedData.unshift(newItem);
                }
            });

            return updatedData;
        });
        setIsModalOpenImport(false);
        setLoadings(false);
    }

    const handleNewContainer = (response: any) => {
        if (JSON.parse(response).success === 0) {
            Utils.InfoOpenNotification('topRight', JSON.parse(response).message, 0,api);
        } else {
            if (typeForm === 'create') {
                setData((prevData: any) => [JSON.parse(response).data[0], ...prevData]);
            }else {
                setData(prevData => {
                    return prevData.map(item =>
                        item.id === JSON.parse(response).data[0].id ? {...JSON.parse(response).data[0]} : item
                    );
                });
            }
            setOpenDrawerContainer(false)
            if (Utils.getUserInfo().id === JSON.parse(response).user_id) {
                Utils.openNotification('topLeft',
                    typeForm === 'create' ? 'Добавленно' : 'Обновленно', api);
            }
            form.resetFields()
        }
    }

    const handleNewBooking = (response: any) => {
        setData((prevData: any) => {
           return  prevData.map((row: any) =>
                row.id === response[0].id ? { ...row,
                    ['status_terminal.name']: response[0]['status_terminal.name'],
                    booking_before:
                    response[0].booking_before !== null ?
                        moment(response[0].booking_before).format('DD.MM.YYYY') :
                        null,
                } : row
            )
        });
    };

    const handleShowDrawerContainer = () =>{
        setTypeForm('create')
        setOpenDrawerContainer(true)
        setDataRowContainer([])
        form.resetFields()
    }

    const onCloseFormContainer = () =>{
        setOpenDrawerContainer(false)
        form.resetFields()
    }

    const closeModalContainers = () =>{
        setIsModalOpenContainer(false)
    }

    const onClickLink = (data: any) =>{
        setDataRowContainer(data[0])
        dispatch(getActivityContainer(data[0]?.id)).then(response =>{
            setActivityContainer(response.payload)
        })
        dispatch(getCommentContainer(data[0]?.id)).then(response =>{
            setDataComment(response.payload)
        })
        setIsModalOpenContainer(true)
    }

    const onCloseFilter = () =>{
        fetchData(current, page,"clear",'DESC');
        setNameTerminal(null)
    }

    const handleShowEditForm = (payload: any) =>{
        setDataRowContainer(payload)
        setOpenDrawerContainer(true)
        setTypeForm('update')
    }

    const onFinish = (data: any) =>{
        dispatch(setContainer(data));
    }

    const showCardContainer = (data: any) =>{
        setDataRowContainer(data)
        setIsModalOpenContainer(true)
    }

    const handleShowModal = () =>{
        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    const handleFileChangeImport = async (event: React.ChangeEvent<HTMLInputElement>) =>{
        TerminalService.handleFile(event,columns).then((response) =>{
            if (response.length > 0){
                setIsModalOpenImport(true);
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
            }
        })
    }

    const closeModalImport = () => {
        setIsModalOpenImport(false)
    }

    const filtersFetch = (text: string | number, column: IColumnType<any>) =>{
        //console.log(column)
        dispatch(filters({text: text,column: column.dataIndex,current: (current - 1) * page ,page: page})).then(response =>{
            console.log(response)
      /*      setData([]);
            setData(response.payload.rows);
            setTotal(response.payload.count)*/
        })
    }

    const filtersColumn = (column: any) =>{
        const cc =  data.filter((row: any) => row.id === column)
        console.log(cc)

    }

    const handleOrder = (order: string) =>{
        fetchData(current,page, '', order)
    }

    const handleCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        const rowsData = generateCSV(arSelectedRows, data);
        copyToClipboard(rowsData);
    };

    const handleDeleteRow = (row: any) =>{
        dispatch(deleteContainer({
            current: (current - 1) * page ,
            page: page,
            id: row.id,
            delete: row.delete !== true
        }))
    }

    const handleDeleteCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        arSelectedRows.forEach((item: any) => {
            // Оптимизированная фильтрация
            const deleteData = data.find(el => el.id === item && el.delete !== true);

            if (deleteData) {
                dispatch(deleteContainer({
                    current: (current - 1) * page,
                    page: page,
                    id: item,
                    delete: deleteData.delete !== true
                }));
            }
        });
    };

    return (
        <Container>
            {contextHolder}
            <Flex gap={20} justify={'space-between'} align={'flex-start'} style={{width: '100%'}}
                  className={'container'}>
                <Flex align={"center"} gap={10}>
                    <div className={'drawer-title'}>{t('ContainerTable')}</div>
                    <div className={'drawer-title terminal'}>{nameTerminal}</div>
                    {nameTerminal !== null &&  <Icon component={blackCloseIcon} onClick={onCloseFilter} />}
                </Flex>
                <Flex gap={16}>
                    <input
                        style={{display: 'none'}}
                        ref={inputRef}
                        type="file"
                        id="fileSelect"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChangeImport}
                    />
                    <Button onClick={handleShowModal}>Импорт</Button>

                    <Button icon={<PlusOutlined/>} style={{background: '#05F', color: '#fff'}}
                            onClick={handleShowDrawerContainer}/>
                    <AutoComplete
                        allowClear
                        style={{width: 300}}
                        options={columns}
                        onSearch={(text) => handleSearch(text)}
                        popupMatchSelectWidth={false}
                        value={selectedOption}
                        dropdownRender={() => (
                            <>
                                <div className={'text-card-12'} style={{padding: '10px 10px'}}>
                                    Выбор колонок по которым будет производиться поиск
                                </div>
                                <Flex gap={5} style={{width: 300, flexWrap: 'wrap', padding: 10}}>
                                    {columns.map((option) => (
                                        option.title !== '' && option.dataIndex !== 'date_entry' &&
                                        option.dataIndex !== 'arrival_date' && option.dataIndex !== 'departure_date' &&
                                        option.dataIndex !== 'booking_before' &&
                                        <Button
                                            size={"small"}
                                            key={option.key}
                                            type="primary"
                                            className={activeButtons[Number(option.key)] ? 'active-search-btn' : ''}
                                            onClick={() => handleButtonClickSearch(option)}>
                                            {option.title}
                                        </Button>
                                    ))}
                                </Flex>
                            </>
                        )}>
                        <Input placeholder="Введите текст"/>
                    </AutoComplete>
                </Flex>
            </Flex>
            <Space direction="vertical" size="middle" style={{width: "100%"}}>
                <Table
                    onClickLink={onClickLink}
                    editRow={handleShowEditForm}
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    order={handleOrder}
                    filtersFetch={filtersFetch}
                    filters={filtersColumn}
                    deleteRow={handleDeleteRow}
                    height={'calc(-162px + 100vh)'}
                    pagination={{pageSize: page, total: Number(total), showSizeChanger: true}}
                    style={{overflow: "auto hidden"}}
                    onChangePagination={onChangePagination}
                    handleCopyAll={handleCopyAll}
                    handleDeleteCopyAll={handleDeleteCopyAll}
                    onRow={(record, rowIndex) => {
                        showCardContainer(record)
                    }}
                />
            </Space>
            <DrawerComponent
                open={openDrawerContainer}
                form={
                    <FormContainer
                        typeForm={typeForm}
                        form={form}
                        dataRowContainer={dataRowContainer}
                        cityData={cityData}
                        dataCurrency={dataCurrency}
                        dataTypeQuality={dataTypeQuality}
                        dataTypeContainers={dataTypeContainers}
                        dataTypePhone={dataTypePhone}
                        dataTypeEmail={dataTypeEmail}
                        dataTypeMessenger={dataTypeMessenger}
                        onClose={onCloseFormContainer}
                        onFinishForm={onFinish}
                        title={typeForm === 'create' ?'Добавить контейнер' : 'Редактировать контейнер'}
                    />
                }
            />
            <Modal
                closable={false}
                footer={null}
                width={'80%'}
                open={isModalOpenContainer}>
                <FormModalContainer
                    activityContainer={activityContainer}
                    dataComment={dataComment}
                    dataStatusContainer={dataStatusContainer}
                    dataRowContainer={dataRowContainer}
                    closeModalContainers={closeModalContainers}
                />
            </Modal>
            <Modal
                closable={false}
                footer={null}
                width={'94%'}
                open={isModalOpenImport}>
                <FormModalImport
                    closeModalImport={closeModalImport}
                    loadings={loadings}
                    setLoadings={setLoadings}
                />
            </Modal>
        </Container>
    );
};

export default Containers;
