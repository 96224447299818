import React from 'react';

import { Flex, Typography } from 'antd';
import { useSelector } from 'react-redux';

import Row from './Row';
import Comment from '../../list/Comment';
import { ITabView } from '../../types';
import RouteList from '../../list/RouteList';
import style from '../../../style.module.scss';
import { RootState } from '../../../../../store';
import { IComments } from '../../../../../store/deal';
import { RouteNames } from '../../../../../router/names';
import ScrollList from '../../../../../components/ui/ScrollList';




const TabDeal: React.FC<ITabView> = ({ deal }) => {
  const comments = useSelector<RootState>((state) => state.deal.comments) as IComments[];
  const routes = deal?.route || []
  const refComments = React.useRef<HTMLDivElement>(null);
  const width = refComments?.current ? refComments.current.getBoundingClientRect().width : '';
  const company = <Row title='Компания' subtitle={deal?.my_company} isDivider link={deal?.my_company_id ? `${RouteNames.CRM_COMPANY_VIEW}${deal.my_company_id}` : ''} />;
  const client = <Row title='Контрагент' subtitle={deal?.contact ? [{text: deal?.company, link: deal?.company_id ?`${RouteNames.CRM_COMPANY_VIEW}${deal.my_company_id}` : ''}, {text: deal?.contact?.fio_contact || '', link: deal?.contact_id ? `${RouteNames.CRM_CONTACTS_VIEW}${deal.contact_id}` : ''}] : deal?.company} isDivider link={deal?.company_id ?`${RouteNames.CRM_COMPANY_VIEW}${deal.my_company_id}` : ''} />;
  return (
    <Flex vertical style={{ width: '100%' }}>
      <Row title='Название' subtitle={deal?.name_deal} isDivider />
      {deal?.type_deal_id === 3 ? <>{company}{client}</> : <>{client}{company}</>}
      <Row title='Номер счета' subtitle={deal?.account_num} isDivider />
      <Row title='НДС' subtitle={deal?.nds} isDivider />
      <Row title='Валюта' subtitle={deal?.currency} isDivider />

      <Typography.Paragraph className={style['subtitle']}>Маршрут</Typography.Paragraph>
      <div className={style['comments-box']}>
        {!!routes?.length && <ScrollList
          setLastItemReached={(v) => v}
          height={100}
          totalTableActivity={1}
          data={routes}
          tableRef={refComments}
          paginationActivity={() => {}}
          isTable={false}
          endMessage={null}
        >
          <RouteList array={routes} width={width} />
        </ScrollList>}

      </div>
      <Typography.Paragraph className={style['subtitle']}>Комментарий</Typography.Paragraph>
      <div className={style['comments-box']}>
        {!!comments?.length && <ScrollList
          setLastItemReached={(v) => v}
          height={comments?.length >= 3 ? 200 : (comments?.length || 1) * 80}
          totalTableActivity={1}
          data={comments}
          tableRef={refComments}
          paginationActivity={() => {}}
          isTable={false}
          endMessage={null}
        >
          {comments.map((comment, index) => <Comment key={index} comment={comment} isDelete={false} deleteComment={() => {}} />)}
        </ScrollList>}

      </div>
    </Flex>
  );
};

export default TabDeal;
