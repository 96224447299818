import { CSSProperties } from 'react';
import './Checkbox.scss'

interface CheckboxProps {
	isChoice: boolean;
	onChange: Function;
	type?: string,
	label?: string,
	labelType?: string,
	style?: CSSProperties,
}
export const Checkbox = ({ isChoice, style, onChange, type, label, labelType }: CheckboxProps) => {
	return (
		<label className={'label ' + labelType}>
			<input
				style={style}
				type="checkbox"
				checked={isChoice}
				onChange={(e) => onChange(e.target.checked)}
				className={'element ' + type}/>
			{label}
		</label>
	)
}
