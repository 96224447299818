import socket from "../socket";

export class Rest_deal{
    static generateIdDeal(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('generate-id-deal', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}
