import React, {useState} from 'react';
import {AutoComplete, Avatar, Button, Dropdown, Flex, Form, Input, MenuProps, message, Select, SelectProps, Typography} from "antd";
import {CheckCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, UserOutlined} from "@ant-design/icons";
import Icon from '@ant-design/icons/lib/components/Icon';
import UploaderDocument from "../../../components/ui/UploaderDocument";
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import Scroll from './Scroll';
import {useStoreDispatch} from "../../../store";
import {findContainers} from "../../../store/containers";
import { moreIcon } from '../../../assets/image/svg';
import {Utils} from "../../../utils";
import { messagesModal } from '../../../constants';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import '../style.scss'

const { title: warningTitle, subtitle } = messagesModal.warning;

interface ITabDocument {
    onClose: () => void;
}
interface IValues {
    [key: string]: any;
}
interface IData {
    id?: number;
    num_container: string;
    status: string | null;
    bet?: number | string | null;
    price?: number | string | null;
    type?: string | number;
    type_container?: string | number;
    isEdit?: boolean;
}

const TabDocument: React.FC<ITabDocument> = ({onClose}) => {
    const [options, setOptions] = React.useState<IData[] | []>([]);
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IValues>({});
    const dispatch = useStoreDispatch();
    const [arResponsible, setArResponsible] = useState<any[]>([{
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id
    }]);
    const [value, setValue] = useState<string>('');
    const [data, setData] = useState<IData[]>([]);

    const showModalResponsible = () => {
        // Logic to show modal
    };

    const deleteContainer = (idContainer: string) => setData((prev) => prev?.filter((container) => container?.num_container !== idContainer));

    const editContainer = (idContainer: string) => setData((prev) => prev?.map((container) => {
        if (container?.num_container === idContainer) {
            setValues((prev) => ({...prev, [idContainer]: {...prev[idContainer], price: container?.price, status: container?.status}}));
            container.isEdit = !container.isEdit;
        }
        return container;
    }));

    const getItems = (containerNum: string):  MenuProps['items'] => [
        {
            key: 1, label: <TooltipButton propsTooltip={{placement: 'left', title: 'Редактировать'}} propsButton={{onClick: () => editContainer(containerNum), icon: <EditOutlined />, shape: "circle"}} />,
        },
        {
            key: 2, label: <TooltipButton propsTooltip={{placement: 'left', title: 'Удалить'}} propsButton={{onClick: () => deleteContainer(containerNum), icon: <DeleteOutlined />, shape: "circle"}} />,
        },
    ];

    const addRow = (val: string) => {
        const container: IData | undefined = options?.find((opt: IData) => opt?.num_container === val);
        const isUnique = !data.some(item => item.num_container === val);
        if (isUnique && container) {
            setData(prevState => [{
                num_container: val,
                type: container?.type_container,
                status: null,
                bet: container?.price ?? null,
                price: '',
                isEdit: false
            }, ...prevState]);
            setValues((prev) => ({...prev, [val]: {}}));
            setValue('');
        } else {
            message.warning('Этот контейнер уже добавлен!');
            setValue('');
        }
    };

    const handleContainer = () => {
        setOptions([]);
    }

    const getFindContainer = (text: string) => {
      if (text?.length >= 3) {
        dispatch(findContainers(text))
          .then((res) => setOptions(res.payload))
          .catch((err) => console.log("ошибка запроса", err));
      }
    }

    const handleUpdateStatus = (value: string, key: string) => {
        setValues((prev) => ({...prev, [key]: {...prev[key], status: value}}));
    };

    const handleUpdatePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const {value: inputValue} = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setValues((prev) => ({...prev, [key]: {...prev[key], price: inputValue}}));
        }
    };

    const submitContainer = (idContainer: string) => setData((prev) => prev?.map((container) => {
        if (container?.num_container === idContainer) {
            container.isEdit = !container.isEdit;
            if (values[container?.num_container]) {
                const result = {...container, ...values[container?.num_container]};
                delete values[container?.num_container];
                return result;
            }
            return container;
        }
        return container;
    }));
    const closeEditContainer = (idContainer: string) => setData((prev) => prev?.map((container) => {
        if (container?.num_container === idContainer) {
            container.isEdit = !container.isEdit;
            if (values[idContainer]) {
                setValues((prev) => ({...prev, [idContainer]: {...prev[idContainer], price: container?.price, status: container?.status}}));
            }
            return container;
        }
        return container;
    }));

    return (
        <>
            <Form>
                <Flex gap={30} vertical>
                    <UploaderDocument/>
                    <div className="overlap-group-deal">
                        <div className="table">
                            <div className="text-container">
                                <div className={'header__deal'}>
                                    <div className="table-cell__deal_1 label-card-16">№ КТК</div>
                                    <div className="table-cell__deal label-card-16">Тип</div>
                                    <div className="table-cell__deal label-card-16">Статус</div>
                                    <div className="table-cell__deal label-card-16">Входящая ставка</div>
                                    <div className="table-cell__deal label-card-16">Стоимость</div>
                                    <Flex align="center">
                                        <div className="label-card-16">
                                            <Dropdown menu={{ items: [] }} placement='bottomLeft' trigger={['click']}>
                                                <a className='' onClick={(e) => e.preventDefault()}>
                                                    <Icon component={moreIcon} />
                                                </a>
                                            </Dropdown>
                                        </div>
                                    </Flex>
                                </div>
                                <Scroll className="scroll-instance_deal" hovered={false}
                                        knobClassName="design-component-instance-deal-node">
                                    <div className="table-body">
                                        <AutoComplete
                                            placeholder={'Введите № КТК'}
                                            style={{width: '26%'}}
                                            options={options?.map((opt: IData) => ({id: opt?.id, value: opt?.num_container}))}
                                            onSelect={(val) => addRow(val)}
                                            onChange={handleContainer}
                                            onSearch={(text: string) => getFindContainer(text)}
                                            maxLength={11}
                                            allowClear
                                        />
                                        {data.map((row, index) => (
                                            <div key={index} className="table-row_deal">
                                                <div className="table-cell__deal text-card-14">{row.num_container}</div>
                                                <div className="table-cell__deal text-card-14">{row.type}</div>
                                                <div className="table-cell__deal text-card-14">
                                                    {row?.isEdit ? (
                                                        <Select
                                                            placeholder="Добавьте статус"
                                                            value={values?.[row.num_container]?.status || null}
                                                            style={{width: '100%'}}
                                                            onChange={(value) => handleUpdateStatus(value, row.num_container)}
                                                            options={[
                                                                {value: 'В пути', label: 'В пути'},
                                                                {value: 'Вышел из депо', label: 'Вышел из депо'},
                                                            ]}
                                                        />
                                                    ) : <span>{row.status || 'Добавьте статус'}</span>
                                                    }
                                                </div>
                                                <div className="table-cell__deal text-card-14">

                                                <span>{row.bet ?? ""}</span>
                                                </div>
                                                <div className="table-cell__deal text-card-14">
                                                    {row?.isEdit ? (
                                                        <Input
                                                            placeholder="Добавьте цену"
                                                            allowClear
                                                            value={values?.[row.num_container]?.price}
                                                            onChange={(e) => handleUpdatePrice(e, row.num_container)}
                                                            autoFocus
                                                        />
                                                    ) : (
                                                        <span>{row.price || 'Добавьте цену'}</span>
                                                    )}
                                                </div>
                                                <Flex align="center">
                                                    <div className="text-card-14">
                                                    {row?.isEdit ?
                                                    (
                                                        <Flex gap={1}>
                                                            <TooltipButton propsTooltip={{title: 'Сохранить'}} propsButton={{shape: 'circle', icon: <CheckOutlined />, onClick: () => submitContainer(row?.num_container) }} />
                                                            <TooltipButton propsTooltip={{title: 'Закрыть'}} propsButton={{shape: 'circle', icon: <CloseOutlined />, onClick: () => closeEditContainer(row?.num_container) }} />
                                                        </Flex>

                                                    ) : (
                                                        <Dropdown menu={{ items: getItems(row.num_container), style: {background: 'transparent', boxShadow: 'none'}}} placement='bottom' trigger={['click']}>
                                                            <a className='' onClick={(e) => e.preventDefault()}>
                                                                <Icon component={moreIcon} />
                                                            </a>
                                                        </Dropdown>)}
                                                    </div>
                                                </Flex>
                                            </div>
                                        ))}
                                    </div>
                                </Scroll>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <Flex gap={6} vertical>
                            <Typography.Title level={4} style={{marginBottom: 0}}>Дополнительно</Typography.Title>
                            <Typography.Text style={{marginTop: 0}}>Ответственный</Typography.Text>
                            <Flex justify={'space-between'} align={'center'} style={{paddingRight: 7}}>
                                <Flex vertical gap={6}>
                                    {arResponsible.map((item, index) => (
                                        <Flex gap={6} key={index} align={"center"}>
                                            <Avatar size={28} src={item.avatar !== null && item.avatar}
                                                    icon={<UserOutlined/>}/>
                                            <Typography.Text style={{marginTop: 0, color: '#05F'}}>
                                                {item.name}
                                            </Typography.Text>
                                        </Flex>
                                    ))}
                                </Flex>
                                <Typography.Text
                                    style={{marginTop: 0, color: '#05F', cursor: "pointer"}}
                                    onClick={showModalResponsible}
                                >
                                    Добавить ответственного
                                </Typography.Text>
                            </Flex>
                        </Flex>
                        <Flex gap={8} style={{margin: '31px 0 31px'}}>
                            <Button type={'primary'} style={{width: '70%'}} htmlType={"submit"}>Добавить</Button>
                            <Button onClick={() => setConfirmation(true)} type={'text'} style={{width: '30%', color: '#E14453'}}>Отменить</Button>
                        </Flex>
                    </div>
                </Flex>
            </Form>
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={onClose} />
        </>
    );
};

export default TabDocument;
