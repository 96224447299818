// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationForm_element__x--H4 {
  display: block;
  margin: 3px;
  padding: 3px;
  border: 1px solid rgba(68, 83, 113, 0.1490196078);
  border-radius: 5px;
}

.RegistrationForm_button__KsXAT {
  border-radius: 5px;
  background-color: #0055FF;
  color: #fff;
  font-size: 14;
}

.RegistrationForm_p__R5YxU {
  color: #0055FF;
}

::placeholder { /* Most modern browsers support this now. */
  color: #000000;
}

.RegistrationForm_email__OBCTz {
  position: absolute;
  left: 150;
}

.RegistrationForm_formEmail__Pjh2v {
  position: relative;
}

.RegistrationForm_smallStep__w3i6z {
  margin: 5px 0;
}

.RegistrationForm_bigStep__YaYUk {
  margin: 20px 0;
}

.RegistrationForm_container__MkpTH {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/RegistrationForm/RegistrationForm.module.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,kBAAA;AACD;;AACA;EACC,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;AAED;;AAAA;EACC,cAAA;AAGD;;AAAA,gBAAA,2CAAA;EACE,cAAA;AAGF;;AAAA;EACC,kBAAA;EACA,SAAA;AAGD;;AAAA;EACC,kBAAA;AAGD;;AAAA;EACC,aAAA;AAGD;;AAAA;EACC,cAAA;AAGD;;AADA;EACC,YAAA;AAID","sourcesContent":[".element {\n\tdisplay: block;\n\tmargin: 3px;\n\tpadding: 3px;\n\tborder: 1px solid #44537126;\n\tborder-radius: 5px;\n}\n.button {\n\tborder-radius: 5px;\n\tbackground-color: #0055FF;\n\tcolor: #fff;\n\tfont-size: 14;\n}\n.p {\n\tcolor: #0055FF;\n}\n\n::placeholder { /* Most modern browsers support this now. */\n  color: #000000;\n\t// padding-left: 7px;\n}\n.email {\n\tposition: absolute;\n\tleft: 150\n}\n\n.formEmail {\n\tposition: relative;\n}\n\n.smallStep {\n\tmargin: 5px 0;\n}\n\n.bigStep {\n\tmargin: 20px 0;\n}\n.container {\n\twidth: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": `RegistrationForm_element__x--H4`,
	"button": `RegistrationForm_button__KsXAT`,
	"p": `RegistrationForm_p__R5YxU`,
	"email": `RegistrationForm_email__OBCTz`,
	"formEmail": `RegistrationForm_formEmail__Pjh2v`,
	"smallStep": `RegistrationForm_smallStep__w3i6z`,
	"bigStep": `RegistrationForm_bigStep__YaYUk`,
	"container": `RegistrationForm_container__MkpTH`
};
export default ___CSS_LOADER_EXPORT___;
