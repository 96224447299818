import {Drawer} from "antd";
import '../assets/css/drawer.scss'

const DrawerComponent = (props: any) => {
    return (
        <Drawer
            width={'auto'}
            placement="right"
            closable={false}
            forceRender={false}
            open={props.open}>
            <div style={{minWidth: 'calc(45vw - 250px)'}}>
                {props.form}
            </div>
        </Drawer>
    );
};

export default DrawerComponent;
