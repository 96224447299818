import React, {useEffect, useState} from 'react';
import {
    Divider, Flex,
    Form, message,
    notification, Space,
    Typography
} from "antd";
import DrawerComponent from "../../components/DrawerComponent";
import FormContractors from "./formContractors";
import {RootState, useStoreDispatch} from "../../store";
import {deleteContractors, getContractors, saveContractors, updateContractors} from "../../store/contractors";
import {columns} from "./columns";
import {Utils} from "../../utils";
import {typeCompany, typeEmail, typeMessenger, typePhone, typeSite} from "../../store/types";
import {
    getCustomsServices,
    getGeographyPresence,
    getRentKTK,
    getSaleKTK,
    getTerminalStorage,
} from "../../store/store";
import {useSelector} from "react-redux";
import FormEditContractors from "./formEditContractors";
import {entitiesInfo} from "../../store/users";
import socket from "../../socket";
import Button from "../../components/ui/Button/Button";
import {IPagination, Table} from "../../components/ui/Table/Table";
import {SettingOutlined} from "@ant-design/icons/lib";
import {sourceContact} from "../../store/contact";
import FormCardContractors from "./formCardContractors";
import {useCsvExport} from "../../hooks";

const { Title } = Typography;

const Contractors = () => {
    const dispatch = useStoreDispatch();
    const [form] = Form.useForm();
    const [formCard] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [event, setEvent] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataRequisites, setDataRequisites] = useState<any>([]);
    const [dataBankRequisites, setDataBankRequisites] = useState([]);
    const [data, setData] = useState<any[]>(Utils.defaultTable);
    const [current, setCurrent] = useState(0);
    const [page, setPage] = useState(20);
    const [total, setTotal] = useState(0);
    const [dataContractors, setDataContractors] = useState<any>({});
    const dataTypePhone = useSelector((state: RootState) => state.types.dataTypePhone);
    const dataTypeMessenger = useSelector((state: RootState) => state.types.dataTypeMessenger);
    const dataTypeEmail = useSelector((state: RootState) => state.types.dataTypeEmail);
    const [isAddCompany, setIsAddCompany] = useState(false);
    const { generateCSV, copyToClipboard } = useCsvExport({
        fields: columns
            .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
            .map((item: any) => item.dataIndex) // Создаем массив из dataIndex
    });

    const Fetch = async (current: number, page: number) => {
        setLoading(true)
        dispatch(getContractors({current: current,page: page})).then(response => {
            if (response.payload.success === 1) {
                setLoading(false)
                setData(response.payload.data)
                setTotal(response.payload.count.count)
                setLoading(false)
            } else {
                message.error(response.payload.message);
                setLoading(false)
            }
        });

    }

    const handleUpdateContractors = (response: any) =>{
        if (JSON.parse(response).success === 0) {
            Utils.InfoOpenNotification('topRight', JSON.parse(response).message, 20,api);
        } else {
            setData(prevData => {
                return prevData.map(item =>
                    item.id === JSON.parse(response).data[0].id ? {...JSON.parse(response).data[0]} : item
                );
            });

            if (Utils.getUserInfo().id === JSON.parse(response).data[0].user_id) {
                Utils.openNotification('topLeft', JSON.parse(response).message, api);
            }
            form.resetFields()
        }
    }

    useEffect(() =>{
        dispatch(typePhone());
        dispatch(typeMessenger());
        dispatch(typeCompany());
        dispatch(typeEmail());
        dispatch(typeSite());
        dispatch(getRentKTK());
        dispatch(getTerminalStorage());
        dispatch(getSaleKTK());
        dispatch(getCustomsServices());
        dispatch(getGeographyPresence());
        dispatch(sourceContact())
        if (Utils.getStorage('ent') === null) {
            dispatch(entitiesInfo(Utils.role())).then((response: any) => {
                response.payload.res_client.map(async (item: any) => {
                    if (item.entities === 'addCompany') {
                        if (Utils.role().id === response.payload.role_id) {
                            setIsAddCompany(item.status)
                            Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(response.payload.res_client)));
                        }
                    }
                })
            })
        } else {
            Utils.decrypt(Utils.getStorage('ent')).map((item: any) =>{
                if (item.entities === 'addCompany') {
                        setIsAddCompany(item.status)
                }
            })
        }
        Fetch(current,page).then(r => r);

        socket.on("res-update-contractors", handleUpdateContractors);
        socket.on("res-create-contractors", handleCreateContractors);
        socket.on("res-update-entities", handleUpdateEntities);
        socket.on("res-update-group-entities", handleUpdateGroupEntities)
        socket.on("res-delete-contractors", resHandleDeleteRowContractors);

        return () => {
            socket.off("res-update-contractors", handleUpdateContractors);
            socket.off("res-create-contractors", handleCreateContractors);
            socket.off("res-update-entities", handleUpdateEntities);
            socket.off("res-update-group-entities", handleUpdateGroupEntities)
            socket.off("res-delete-contractors", resHandleDeleteRowContractors);
        }
    },[])

    const resHandleDeleteRowContractors = (response: any) => {
        setData(prevData => {
            // Проверьте, что response является массивом, если это необходимо
            if (!Array.isArray([response])) {
                console.error('Invalid response data:', [response]);
                return prevData;
            }

            // Предположим, что response это объект с обновленными данными
            const updatedItem = response;
            return prevData.map(item =>
                item.id === updatedItem.id ? { ...item, ...updatedItem } : item
            );
        });
    };

    const handleUpdateGroupEntities = (response: any) => {
        if (Utils.role().type === 'group') {
            if (Utils.role().id === response.role_id) {
                response.res_client.map(async (item: any) => {
                    item.entities === 'addCompany' && setIsAddCompany(item.status);
                })
            }
        }
    }

    const handleUpdateEntities = (response: any) => {
        if (Utils.role().type === 'role') {
            if (Utils.role().id === response.role_id) {
                response.res_client.map( (item: any) => {
                    item.entities === 'addCompany' && setIsAddCompany(item.status);
                })
            }
        }
    }

    const handleCreateContractors = (response: any) => {
        setLoading(false)
        if (response.success === 0) {
            Utils.InfoOpenNotification('topRight', response.message, 20,api);
        } else {
            setTotal(response?.count?.count)
            response.data.key = response?.data?.id
            setData((prev: any) => {
                return [response.data,...prev];
            })
            setOpen(false);
            Utils.openNotification('topLeft', 'Добавленно', api);
        }
    }

    const onChangePagination = async (page: IPagination<any>) => {
        if (page.current != null && page.pageSize != null) {
            await Fetch(page.current, page.pageSize)
        }
    };

    const showDrawer = async () => {
        setDataContractors({});
        form.resetFields();
        setEvent(true);
        setOpen(true);
        localStorage.removeItem('vd')
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
    };

    const onFinish = async (data: any,type: string) => {
        data.bank = dataBankRequisites.length > 0 ? dataBankRequisites : null;
        data.short_address = dataRequisites.length > 0 ? dataRequisites[10]?.meaning : null;
        data.contractors_id = data.id

        setDataContractors(data)
        setOpenEdit(false);
        if (type === 'save') {
             await dispatch(saveContractors(data));
        }else {
            await dispatch(updateContractors(data));
        }
        localStorage.removeItem('vd')
    };

    const handleEditContractors = async (data: any) => {
        setDataContractors(data);
        setOpen(true);
        setEvent(false);
    };

    const handleShowEditForm = (data: any) => {
        setOpenEdit(true);
        setOpen(false)
        setDataContractors(data)
    }

    const handleCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        const rowsData = generateCSV(arSelectedRows, data);
        copyToClipboard(rowsData);
    };

    const handleDeleteRow = (row: any) =>{
        dispatch(deleteContractors({
            current: (current - 1) * page ,
            page: page,
            id: row.id,
            delete: row.delete !== true
        }))
    }

    const handleDeleteCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        arSelectedRows.forEach((item: any) => {
            // Оптимизированная фильтрация
            const deleteData = data.find(el => el.id === item && el.delete !== true);

            if (deleteData) {
                dispatch(deleteContractors({
                    current: (current - 1) * page,
                    page: page,
                    id: item,
                    delete: deleteData.delete !== true
                }));
            }
        });
    };


    return (
        <>
            {contextHolder}
            <Flex justify={"space-between"} align={"flex-start"} style={{margin: "20px 28px 0px"}}>
                <Title level={2} >Компания</Title>
                <Flex gap={16}>
                    <Button onClick={showDrawer} type={'primary'}>Создать</Button>
                    <Button icon={<SettingOutlined />} type={'text'} />
                </Flex>
            </Flex>
            <Divider style={{margin: 0}}/>
            <Space direction="vertical" size="middle" style={{width: "100%"}}>
                <Table
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    editRow={handleShowEditForm}
                    height={'calc(100vh - 309px)'}
                    pagination={{ pageSize: page, total: Number(total), showSizeChanger: true }}
                    style={{overflow: "auto hidden"}}
                    onChangePagination={onChangePagination}
                    deleteRow={handleDeleteRow}
                    handleDeleteCopyAll={handleDeleteCopyAll}
                    handleCopyAll={handleCopyAll}
                    onRow={(record, rowIndex) => {
                        return handleEditContractors(record);
                    }}/>
            </Space>

            <DrawerComponent
                open={open}
                form={ event ?
                <FormContractors
                    form={form}
                    setDataRequisites={setDataRequisites}
                    setDataBankRequisites={setDataBankRequisites}
                    dataContractors={dataContractors}
                    dataTypePhone={dataTypePhone}
                    dataTypeMessenger={dataTypeMessenger}
                    dataTypeEmail={dataTypeEmail}
                    onFinish={onFinish}
                    onClose={onClose}
                    setOpen={setOpen}
                    title={'Новая компания'}/>
                    :
                    <FormCardContractors
                        onClose={onClose}
                        handleShowEditForm={handleShowEditForm}
                        dataContractors={dataContractors}
                        formCard={formCard}
                        title={'Редактировать компанию'}
                    />
                }
            />
            <DrawerComponent
                open={openEdit}
                form={
                    <FormEditContractors
                        setDataRequisites={setDataRequisites}
                        dataContractors={dataContractors}
                        dataTypePhone={dataTypePhone}
                        dataTypeMessenger={dataTypeMessenger}
                        dataTypeEmail={dataTypeEmail}
                        onFinishEdit={onFinish}
                        onCloseEdit={onCloseEdit}
                        title={'Редактировать компанию'}
                    />
                }
            />
        </>
    );
};

export default Contractors;
