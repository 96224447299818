import React from 'react';
import Container from "../../components/Container";
import {useParamsHistory} from "../../hooks";

const ContainersOne = () => {
    const { history, location } = useParamsHistory();
    const { pathname, search } = location;

    return (
        <Container>
            fbdfbdfb
        </Container>
    );
};

export default ContainersOne;
