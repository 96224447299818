import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Flex, Form,
    MenuProps,
    message, Modal, notification,
    Row,
    Select, Tooltip,
    Typography
} from "antd";
import Icon, {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {blackCloseIcon, copyIcon, errorImportIcon} from "../../assets/image/svg";
import {useTranslation} from "react-i18next";
import {columns as crmColumns} from "./columns";
import {fileColumns, TerminalService} from "./service";
import {useStoreDispatch} from "../../store";
import {findTerminalChildCity, getTerminalCity, setTerminal} from "../../store/terminals";
import {SelectSearch} from "../../components/ui/SelectSearch/SelectSearch";
import FormModalTerminal from "../terminals/formModalTerminal";
import {Utils} from "../../utils";

const {Title} = Typography;
const {Option} = Select;
let itemsErrors: MenuProps['items'] = [];

interface FormModalImportProps {
    closeModalImport?: any,
    loadings?: boolean,
    setLoadings: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const FormModalImport = ({closeModalImport, loadings, setLoadings}: FormModalImportProps) => {
    const {t} = useTranslation();
    const dispatch = useStoreDispatch();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [mapping, setMapping] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [errorsNum, setErrorsNum] = useState<number>(0);
    const [isAddTerminal, setIsAddTerminal] = useState<boolean>(false);
    const [isOpenModalTerminal, setIsOpenModalTerminal] = useState<boolean>(false);
    const [terminals, setTerminals] = useState<any[]>([]);
    const [fileNameTerminal, setFileNameTerminal] = useState<string | undefined | number>("");
    const [isOpenModalInfoCompany, setIsOpenModalInfoCompany] = useState<boolean>(false);
    const [infoDataCompany, setInfoDataCompany] = useState<any[]>([]);
    const [disabledSave, setDisabledSave] = useState<boolean>(true);
    const [clickedIcons, setClickedIcons] = useState<boolean[]>([]);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isMuted, setIsMuted] = useState(true);
    const notificationKey = 'unique-notification-key';

    useEffect(() => {
        setDisabledSave(true)
        const initialMapping = crmColumns.map((crmColumn: any) => ({
            crmColumn,
            fileColumn: fileColumns[0].find((fileColumn: string) =>
                fileColumn.toLowerCase() === crmColumn.title.toLowerCase()) || null,
            disabled: fileColumns[0].find((fileColumn: string) =>
                fileColumn.toLowerCase() === crmColumn.title.toLowerCase()) || false,
        }));

        initialMapping.map((item) => {
            if (item.crmColumn.title === 'Терминал') {
                const indexTerminal = fileColumns[0].indexOf('Терминал');
                console.log(fileColumns[1][indexTerminal])
               const cd = new Set(fileColumns.map((item, index) => {
                   if (index > 0){
                       return item[indexTerminal];
                   }
                }).filter(row => row !== undefined));
                console.log(cd)
                setFileNameTerminal(fileColumns[1][indexTerminal]);
                dispatch(findTerminalChildCity(fileColumns[1][indexTerminal])).then(response => {
                    if (response.payload.length === 0) {
                        setIsAddTerminal(true)
                        dispatch(getTerminalCity()).then(response => {
                            setTerminals(response.payload)
                        })
                    }
                })
            }
        })

        setErrorsNum(initialMapping.filter(item =>
            !item.disabled && item.crmColumn.title !== 'Фото' && item.crmColumn.title !== '').length);

        itemsErrors = initialMapping.reduce((acc: any, item: any) => {
            if (!item.disabled && item.crmColumn.title !== 'Фото' && item.crmColumn.title !== '') {
                acc.push({
                    key: item.crmColumn.key,
                    label: item.crmColumn.title
                });
            }
            return acc;
        }, []);
        setMapping(initialMapping);

    }, [crmColumns, fileColumns[0]]);

    const handleCopy = async (row: string, index: number,correctedValues: any[], type: string) => {
        // Копируем текст в буфер обмена
        await navigator.clipboard.writeText(row);
        // Устанавливаем флаг clickedIcons для текущего элемента
        const newClickedIcons = [...clickedIcons];
        newClickedIcons[index] = true;
        setClickedIcons(newClickedIcons);

        // Обновляем уведомление с новыми данными
        if(type === 'container'){
            updateNotification(correctedValues, newClickedIcons,'Ошибка в номере контейнера');
        }else {
            updateNotification(correctedValues, newClickedIcons,'Компании');
        }
    };

    const updateNotification = (correctedValues: any[], clickedIcons: boolean[],title: string) => {
        setClickedIcons([])
        notification.open({
            key: notificationKey,
            message: title,
            description: (
                <div>
                    <ul>
                        {correctedValues.map((row, index) => (
                            index < 10 &&
                            <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                                <li>{row}</li>
                                {clickedIcons[index] ? (
                                    <CheckOutlined style={{color: 'green'}}/>
                                ) : (
                                    <Icon component={copyIcon} onClick={() =>
                                        handleCopy(row, index, correctedValues,title !== 'Компании' ? 'container' : '')} />
                                )}
                            </div>
                        ))}
                    </ul>
                </div>
            ),
            duration: null,
        });
    };

    const handleFileColumnChange = async (value: string, index: number) => {
        // Создание копии mapping и обновление значения fileColumn
        const newMapping: any = [...mapping];
        newMapping[index].fileColumn = value;
        if (value === 'Пустое поле'){
            newMapping[index].disabled = true;
        }

     /*   if (value !== 'Пустое поле' && index === 0) {
            const val = newMapping[index];
            const foundIndex = fileColumns[0].findIndex((fileColumn: string) => fileColumn === val.fileColumn);

            const findNum: any[] = fileColumns.map((fileColumn: string, idx: number) => {
                if (idx > 0 && fileColumn.length > foundIndex) {
                    return fileColumn[foundIndex];
                }
            }).filter(fileColumn => fileColumn !== undefined);

            const validChecks = findNum.map((item: string) => {
                return Utils.isValidContainerNumber(item);
            });
            const correctedValues = validChecks.map((isValid, idx) => {
                if (!isValid) {
                    return findNum[idx]; // Замена на номер контейнера из findNum
                }
            }).filter(fileColumn => fileColumn !== undefined);

            // Проверка и блокировка кнопки сохранения
            if (correctedValues.length > 0) {
                setDisabledSave(true); // Установите состояние блокировки кнопки
                // Оповещение пользователя об ошибке
                updateNotification(correctedValues, clickedIcons,'Ошибка в номере контейнера');
                // Прерывание выполнения функции для предотвращения дальнейших операций
                return;
            }
        }*/

        // Валидация и обновление состояния mapping
        await validateMapping(newMapping).then((response: any) => {
            if (newMapping[index].fileColumn !== 'Пустое поле') {
                newMapping[index].disabled = response.length <= 0 ;
                setMapping(newMapping);
            }else {
                setMapping(newMapping);
            }
        });

        // Подсчет и проверка количества элементов с флагом disabled
        const disabledCount = newMapping.filter((item: any) => item.disabled).length;

        // Снятие блокировки кнопки сохранения при условии
        if (mapping.length - 2 === disabledCount) {
            setDisabledSave(false);
        }else {
            setDisabledSave(true);
        }
    };

    const validateMapping = async (newMapping: any[]) => {
        return new Promise(async (resolve, reject) => {
            const errors = newMapping.reduce((acc: any[], current: any) => {
                if (current.fileColumn !== 'Пустое поле' && current.fileColumn && newMapping.filter((item: any) =>
                    item.fileColumn === current.fileColumn && item !== current).length > 0) {
                    acc.push({
                        crmColumn: current.crmColumn.title
                    });
                }
                resolve(acc)
                return acc;
            }, []);
            setErrors(errors);
        })
    };

    const cancelDisabled = (value: string, index: number) => {
        const newMapping = [...mapping];
        newMapping[index].fileColumn = value;
        newMapping[index].disabled = false;
        setMapping(newMapping);
        validateMapping(newMapping);
    }

    const handleSave = () => {
        //const foundIndex = fileColumns[0].findIndex((fileColumn: string) => fileColumn === val.fileColumn);
        const isValid = errors.length === 0;
        if (isValid) {
            //setLoadings(true);
            TerminalService.onSave(mapping, fileNameTerminal).then(res => {
            /*   dispatch(validCompanyContainer(res)).then(response => {
                    if (JSON.parse(response.payload).success === 2) {
                        setIsOpenModalInfoCompany(true);
                        setInfoDataCompany(JSON.parse(response.payload).data)
                        setLoadings(false);
                    }
                    if (JSON.parse(response.payload).success === 1) {
                        dispatch(setContainerImport(res))
                    }
                })*/
            });
        } else {
            message.error('Пожалуйста, исправьте ошибки в сопоставлении.');
        }
    };

    const handleAddTerminal = () => {
        setIsOpenModalTerminal(true)
    }

    const closeCancelViewModal = () => {
        setIsOpenModalTerminal(false)
        form.resetFields()
    }

    const closeInfoCompany = () => {
        setIsOpenModalInfoCompany(false);
        closeModalImport(false);
        updateNotification(infoDataCompany,clickedIcons ,'Компании')
    }

    const onFinishAddTerminal = (payload: any, open: boolean) => {
        Object.assign(payload, {type_form: 'create'});
        dispatch(setTerminal(payload)).then((response: any) => {
            if (response.payload.success === 0) {
                Utils.InfoOpenNotification('topRight', response.payload.message, 20, api);
            } else {
                setIsOpenModalTerminal(false);
                setIsAddTerminal(false)
                Utils.openNotification('topLeft', 'Добавленно', api);
            }
        })
    }

    const selectTerminal = (e: any, option: any) => {
        if (typeof option.children === 'object') {
            setFileNameTerminal(JSON.parse(option.value).child)
        }
        const newMapping = mapping.map((elem) => {
            if (elem.crmColumn.title === "Терминал") {
                elem.disabled = true
            }
            return elem;
        });
        setMapping(newMapping)
    }

    const handleClick = () => {
        if (videoRef.current && isMuted) {
            setIsMuted(false);
            videoRef.current.muted = false;
            videoRef.current.play();
        }
    };

    return (
        <>
            {contextHolder}
            <Flex justify={'space-between'} align={"center"}>
                <Flex className={'box-import'} align={'flex-start'} justify={'space-between'}
                      style={{width: '100%'}}>
                    <Flex align={'center'} justify={'center'} gap={24}>
                        <div className={'box-icon-import'}>
                            <Icon component={errorImportIcon}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                            <div className='label-card-20'>{t('importHeaderTitle')}</div>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={12} lg={15}>
                                    <div className='text-card-12 box-text-import'>{t('importHeaderText')}</div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={7}>
                                    <Flex vertical gap={10}>
                                        <Dropdown menu={{items: itemsErrors}} arrow>
                                            <Button danger style={{color: 'black'}}>
                                                {'Количество ошибок: ' + errorsNum}
                                            </Button>
                                        </Dropdown>
                                        {isAddTerminal &&
                                            <Flex vertical>
                                                <p className={'text-card-12'}
                                                   style={{marginBottom: 0}}>{t('chooseTerminal')}</p>
                                                <SelectSearch
                                                    onChangeSelect={selectTerminal}
                                                    dataSource={terminals}
                                                    hiddenBtn
                                                    type={'page'}
                                                    onClick={handleAddTerminal}
                                                />
                                            </Flex>
                                        }
                                    </Flex>
                                </Col>
                            </Row>
                        </div>
                    </Flex>
                    <Icon component={blackCloseIcon} onClick={closeModalImport}/>
                </Flex>
            </Flex>
            <Divider style={{marginTop: 14, marginBottom: 26}}/>
            <div className={'box-import'}>
                <Row gutter={[50, 50]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Flex gap={5}>
                            <Col span={12}>
                                <Title level={5}>{t('columnNameCRM')}</Title>
                                <Flex gap={8} vertical>
                                    {mapping.map((item: any, index: number) => (
                                        item.crmColumn.title !== '' && item.crmColumn.title !== 'Фото' &&
                                        <Row key={index}>
                                            <Col span={2}>{index + 1}</Col>
                                            <Col span={20}>
                                                <div className={
                                                    !item.disabled ? 'column-name-crm' : 'column-name-crm disabled'
                                                }>{item.crmColumn.title}</div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>Имя столбца в файле:</Title>
                                <Flex gap={8} vertical>
                                    {mapping.map((item: any, index: number) => (
                                        item.crmColumn.title !== '' && item.crmColumn.title !== 'Фото' &&
                                        <Flex key={index} gap={5}>
                                                <Select
                                                    placeholder={'Выберите имя'}
                                                    disabled={item.disabled}
                                                    style={item.disabled ? {width: '100%'} : {width: '79%'}}
                                                    value={item.fileColumn}
                                                    onChange={(value) => handleFileColumnChange(value, index)}
                                                >
                                                    {fileColumns[0].map((fileColumn: string) => (
                                                        <Option key={fileColumn} value={fileColumn}>
                                                            {fileColumn}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            <Flex>
                                                {errors.find((error: any) => error.crmColumn === item.crmColumn.title) ? (
                                                    <Tooltip title="Изменить"
                                                             arrow={false}
                                                             color={'#fff'}
                                                             overlayClassName="custom-tooltip"
                                                             placement="topLeft">
                                                        <Flex gap={5} align={"center"}>
                                                            <Button icon={<CloseOutlined
                                                                style={{fontSize: '12px', color: 'red'}}/>}
                                                                    onClick={() => cancelDisabled(item, index)}/>
                                                            <span>Дубликат</span>
                                                        </Flex>
                                                    </Tooltip>
                                                ) : (
                                                    <Flex gap={8} align={"center"}>
                                                        {item.disabled &&
                                                            <Tooltip title="Изменить"
                                                                     arrow={false}
                                                                     color={'#fff'}
                                                                     overlayClassName="custom-tooltip"
                                                                     placement="topLeft">
                                                                <Flex gap={5} align={"center"}>
                                                                <Button icon={<CloseOutlined
                                                                    style={{fontSize: '12px', color: 'red'}}/>}
                                                                        onClick={() => cancelDisabled(item, index)}/>
                                                                </Flex>
                                                            </Tooltip>
                                                        }
                                                        {item.disabled ?
                                                            <CheckOutlined style={{color: 'green'}}/>
                                                            :
                                                            <CheckOutlined style={{color: 'gray'}}/>
                                                        }
                                                    </Flex>
                                                )}
                                            </Flex>

                                        </Flex>
                                    ))}
                                </Flex>
                            </Col>
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <video
                                    onClick={handleClick}
                                    width="90%"
                                    ref={videoRef}
                                    autoPlay
                                    muted={isMuted}
                                    loop
                                    controls>
                                    <source
                                        src="/videos.mp4"
                                        type="video/mp4"/>
                                    Your browser doesn't support HTML5 video tag.
                                </video>

                    </Col>
                    <Col span={24} offset={6}>
                        <Button disabled={disabledSave} type={'primary'} onClick={handleSave} loading={loadings}>
                            Сохранить изменения и загрузить
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title={'Добавить терминал'}
                maskClosable={false}
                footer={null}
                onCancel={closeCancelViewModal}
                width={548}
                open={isOpenModalTerminal}>
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    <FormModalTerminal
                        nameTerminal={fileNameTerminal}
                        form={form}
                        typeForm='create'
                        onFinishAddTerminal={onFinishAddTerminal}
                        dataCityRedux={terminals}
                    />
                </Flex>
            </Modal>
            <Modal
                title={'Компании'}
                maskClosable={false}
                footer={null}
                onCancel={closeInfoCompany}
                width={548}
                open={isOpenModalInfoCompany}>
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    {
                        infoDataCompany.map((item: any, index: number) => (
                            <div key={index}>{item}</div>
                        ))
                    }
                </Flex>
            </Modal>
        </>
    )
};

export default FormModalImport;
