import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Flex, Input, message, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { RootState, useStoreDispatch } from '../../store';
import { RouteNames } from '../../router/names';
import Container from '../../components/Container';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../components/ui/Table/Table';
import { useBreadcrumb, useCsvExport, useParamsHistory } from '../../hooks';
import {
  deleteDeal,
  getDeals,
  getFiltersDeal,
  IDeal,
  setCount,
  setCurrentPage,
  setFiltersDeals,
  setLoading,
  setPage,
  setTableDeals,
} from '../../store/deal';
import { useSelector } from 'react-redux';
import { filtersDeal } from '../../store/filters';

const Deals = () => {
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const dealData = useSelector<RootState>(
    (state) => state.deal.dealData,
  ) as IDeal[];
  const loading = useSelector<RootState>(
    (state) => state.deal.loading,
  ) as boolean;
  const page = useSelector<RootState>((state) => state.deal.page) as number;
  const count = useSelector<RootState>((state) => state.deal.count) as number;
  const current = useSelector<RootState>(
    (state) => state.deal.currentPage,
  ) as number;
  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  useBreadcrumb(['Продажи', 'Сделки']);

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
    dispatch(filtersDeal({ text: val, data: searchColumn })).then((res) => {
      dispatch(setFiltersDeals(res.payload?.rows));
    });
  };

  const handleShowDrawerDeal = () => {
    history.push(RouteNames.DEAL_CREATE, { table: dealData, count });
  };

  const onClickLink = (data: any) => {
    const { id } = data?.[0];
    if (id) {
      history.push(`${RouteNames.DEAL_VIEW}${id}`, {
        row: data?.[0],
        table: dealData,
        count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    const { id } = data;
    if (id) {
      history.push(`${RouteNames.DEAL_VIEW}${id}`, {
        row: data,
        table: dealData,
        count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    const { id } = payload;
    if (id) {
      history.push(`${RouteNames.DEAL_EDIT}${id}`, {
        table: dealData,
        count,
        row: payload,
      });
    }
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, dealData);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear,
    page,
    order,
  }: {
    current: number;
    page: number;
    clear: string;
    order: string;
  }) => {
    dispatch(
      getDeals({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
      }),
    ).catch((error) => message.error(error));
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, clear: '', order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersDeal({ text: text, column: column }));
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersDeal({
          data: value,
          current: current >= 1 ? current - 1 : current * page,
          page,
        }),
      );
    } else {
      fetchData({ current, page, clear: '', order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page.current || 0;
      const size = page.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handleDeleteRow = (row: any) => {
    dispatch(
      deleteDeal({
        current: (current - 1) * page,
        page,
        id: row.id,
        delete: row.delete !== true,
      }),
    ).finally(() => {
      fetchData({ current, page, clear: '', order: 'DESC' });
    });
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    dispatch(setLoading(true));
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = dealData?.find((el) => el.id === item && !el.delete);

      if (deleteData) {
        dispatch(
          deleteDeal({
            current: (current - 1) * page,
            page,
            id: item,
            delete: !deleteData.delete,
          }),
        );
      }
    });
    dispatch(setLoading(false));
    fetchData({ current, page, clear: '', order: 'DESC' });
  };

  useEffect(() => {
    if (location?.state?.table) {
      dispatch(setTableDeals(location.state.table));
      dispatch(setCount(location.state.count));
    }
    if (!location?.state?.table) {
      dispatch(getDeals({ current, page, order: 'DESC' }));
    }
  }, [dispatch, page, current, pathname]);

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Deal')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff' }}
            onClick={handleShowDrawerDeal}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  Выбор колонок по которым будет производиться поиск
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder="Введите текст" />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          onClickLink={onClickLink}
          editRow={handleShowEditForm}
          deleteRow={handleDeleteRow}
          handleCopyAll={handleCopyAll}
          filtersFetch={filtersFetch}
          filters={filtersColumn}
          handleDeleteCopyAll={handleDeleteCopyAll}
          onRow={(record, rowIndex) => {
            showCardContainer(record);
          }}
          onChangePagination={onChangePagination}
          columns={columns}
          order={handleOrder}
          dataSource={dealData}
          loading={loading}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: page,
            total: count,
            showSizeChanger: true,
          }}
        />
      </Space>

    </Container>
  );
};

export default Deals;
