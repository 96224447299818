import moment from 'moment';
import { Avatar, Flex, Typography } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import { IComments } from '../../../../store/deal';
import { DeleteRedBtn } from '../../../../assets/image/svg';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import style from '../../style.module.scss';

interface IComment {
  comment: IComments;
  isDelete: boolean;
  deleteComment: () => void;
}

const Comment: React.FC<IComment> = ({ comment, deleteComment, isDelete }) => {
  return (
    <div className={style['comment']}>
      <Flex gap={5} justify="space-between" align="center">
        <Flex align="center" gap={16}>
          <Avatar
            className={style['comment__avatar']}
            src={comment?.user?.avatar}
            icon={<UserOutlined />}
          />
          <Typography.Paragraph className={style['comment__title']}>
            {comment?.user?.fio}
          </Typography.Paragraph>
        </Flex>
        <Flex align="center">
          <div className={style['comment__date']}>
            {moment(comment?.createdAt).isValid()
              ? moment(comment?.createdAt).format('DD MMM HH:mm')
              : ''}
          </div>
          <TooltipButton
            propsTooltip={isDelete ? { title: 'Удалить' } : undefined}
            propsButton={{
              className: style['comment__action'],
              type: 'text',
              icon: <DeleteRedBtn />,
              style: isDelete ? {} : {opacity: 0, cursor: 'default'},
              onClick: isDelete ? deleteComment : () => {},
            }}
          />
        </Flex>
      </Flex>
      <div className={style['comment__text-box']}>
        <Typography.Paragraph
          className={style['comment__text']}
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: comment.comment },
          }}
        >
          {comment.comment}
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default Comment;
