import {RouteNames} from "../router/names";
import {privateRoutes, publicRoutes} from "../router";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {Switch, Route, Redirect} from 'react-router-dom';

const AppRouter = () => {
    const isAuth = useSelector((state: RootState) => state.users.auth);
    return (
        isAuth ?
            <Switch>
                {privateRoutes.map(route =>
                    <Route
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={route.path}
                    />
                )}
                <Redirect to={RouteNames.MAIN}/>
            </Switch>
            :
            <Switch>
                {publicRoutes.map(route =>
                    <Route path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={route.path}
                    />
                )}
                <Redirect to={RouteNames.LOGIN}/>
            </Switch>
    );
};

export default AppRouter;