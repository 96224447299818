import get from 'lodash.get';

import {IColumnType, IHiddenColumn} from './Table';
import {
    Avatar,
    Button,
    Divider,
    Dropdown,
    Flex,
    MenuProps,
    Modal,
    Popover,
    Typography,
} from 'antd';
import Icon, {UserOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
import {TableCell, TruncatedText} from './styled';
import moment from 'moment';
import {Checkbox} from '../Checkbox/Checkbox';
import {
    burgerIcon,
    DeleteRed20,
    DeleteRedBtn,
    languageIcon,
    RepairIcon,
} from '../../../assets/image/svg';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import {type} from 'node:os';


interface Props<T> {
    item: any;
    column: IColumnType<T>;
    itemIndex: number;
    date?: boolean;
    link?: boolean;
    hiddenColumn: IHiddenColumn<T>;
    onRow?: (record: any, rowIndex: number) => void;
    editRow?: (item: any) => void;
    deleteRow?: (row: any) => void;
    onClickLink?: ((data: any) => void) | undefined;
    isChecked: boolean;
    onCheck: (checked: boolean) => void;
    handleCopyAll?: (() => void) | undefined;
    handleDeleteCopyAll?: (() => void | any[]) | undefined;
}

export function TableRowCell<T>({
                                    item,
                                    column,
                                    onRow,
                                    itemIndex,
                                    editRow,
                                    deleteRow,
                                    onClickLink,
                                    isChecked,
                                    onCheck,
                                    handleCopyAll,
                                    handleDeleteCopyAll,
                                }: Props<T>): JSX.Element {
    const [openContext, setOpenContext] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const title = get(item, column?.dataIndex);
    const refTitle = !column.date ? title : moment(title).format('DD.MM.YYYY');

    const subTitle = get(
        item,
        typeof column.subTitle !== 'undefined' ? column.subTitle : '',
    );
    const avatar = get(
        item,
        typeof column.avatar !== 'undefined' ? column.avatar : '',
    );
    const checkbox =
        typeof column.checkbox !== 'undefined' ? column.checkbox : false;
    const menu = typeof column.menu !== 'undefined' ? column.menu : false;

    const viewFunction = () => {
        setOpenPopover(false);
        if (onRow) {
            onRow(item, itemIndex);
        }
    };

    const handleEdit = (row: any) => {
        setOpenPopover(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        editRow ? editRow(row) : null;
    };

    const handleDelete = (row: any) => {
        setOpenPopover(false);
        if (!row.delete) {
            setConfirmation(true);
        } else {
            if (deleteRow) {
                deleteRow(row);
            }
        }
    };

    const handleOpenChange = (newOpen: boolean) => {
        if (newOpen) {
            setOpenContext(false);
        }
        setOpenPopover(newOpen);
    };

    const getAllValues = (obj: any) => {
        let values: any = [];
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                values = values.concat(getAllValues(obj[key]));
            } else {
                values.push(obj[key]);
            }
        }
        return values;
    };

    const handleCopy = async (row: any, column: any) => {
        setOpenPopover(false);
        const values1 = getAllValues(row).join(', ');
        await navigator.clipboard.writeText(values1);
    };

    const content = (item: any, column: any) => (
        <div>
            <p style={{cursor: 'pointer', marginLeft: 20}} onClick={viewFunction}>
                Посмотреть
            </p>
            <p
                style={{cursor: 'pointer', marginLeft: 20}}
                onClick={() => handleCopy(item, column)}>
                Копировать данные
            </p>
            <p
                style={{cursor: 'pointer', marginLeft: 20}}
                onClick={() => handleEdit(item)}>
                Редактировать
            </p>
            <p
                style={{cursor: 'pointer', marginLeft: 20}}
                onClick={() => handleDelete(item)}>
                {!item.delete ? 'Удалить' : 'Снять пометку на удаление'}
            </p>
        </div>
    );

    const items: MenuProps['items'] = [
        {
            label: <div onClick={handleCopyAll}>Копировать выделенное</div>,
            key: '1',
        },
        {
            label: <div onClick={handleDeleteCopyAll}>Удалить выделенное</div>,
            key: '2',
        },
    ];

    const openChangeContext = (open: boolean) => {
        setOpenContext(open);
        setOpenPopover(false);
    };

    const onCloseModalConfirm = () => {
        setConfirmation(false);
    };

    const onOkModalConfirm = (row: any) => {
        setConfirmation(false);
        if (deleteRow) {
            deleteRow(row);
        }
    };
    const renderTitle = (
        type: string | undefined,
        refTitle: string | string[] | undefined,
        column?: IColumnType<any>
    ) => {
        if (type === 'date' && refTitle) {
            // Parse the ISO 8601 date string
            const date = moment(refTitle, 'DD.MM.YYYY');
            if (date.isValid()) {
                // Format the date according to 'DD.MM.YYYY'
                return date.format('DD.MM.YYYY');
            } else {
                return moment(refTitle).format('DD.MM.YYYY');
            }
        }
        return refTitle || '';
    };

    return (
        <>
            <Dropdown
                menu={{items}}
                open={openContext}
                trigger={['contextMenu']}
                onOpenChange={openChangeContext}>
                <TableCell style={{width: column.width}}>
                    {column.render ? (
                        column.render(column, item)
                    ) : (
                        <Flex align={'center'} style={{width: column.width}}>
                            <Flex>
                                {checkbox ? (
                                    <div onClick={(e) => e.stopPropagation()}>
                                        {!item.delete ? (
                                            <Checkbox isChoice={isChecked} onChange={onCheck}/>
                                        ) : (
                                            <DeleteRed20/>
                                        )}
                                    </div>
                                ) : null}
                            </Flex>

                            <Flex align={'center'}>
                                {menu ? (
                                    <>
                                        <Popover
                                            placement="bottomLeft"
                                            content={content(item, column)}
                                            open={openPopover}
                                            onOpenChange={handleOpenChange}
                                            trigger="click">
                                            <Button onClick={(e) => e.stopPropagation()} type="link">
                                                <Icon component={burgerIcon}/>
                                            </Button>
                                        </Popover>
                                    </>
                                ) : null}
                                <Flex gap={6} align={'center'}>
                                    <Flex>
                                        {typeof column.avatar !== 'undefined' ? (
                                            avatar === null || avatar === undefined ? (
                                                <Avatar size={36}>
                                                    {title
                                                        ?.substring(0, 2)
                                                        .toUpperCase()
                                                        .replace(/['"]+/g, '')}
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    size={36}
                                                    src={avatar}
                                                    icon={<UserOutlined/>}
                                                />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </Flex>
                                    <Flex vertical>
                                        <TruncatedText>
                                            {column.link ? (
                                                <Typography.Link
                                                    ellipsis
                                                    style={{color: '#0148D7'}}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onClickLink) {
                                                            onClickLink([
                                                                item,
                                                                {column: Number(column.key)},
                                                            ]);
                                                        }
                                                    }}>
                                                    {refTitle}
                                                </Typography.Link>
                                            ) : (
                                                renderTitle(column.type, refTitle, column)
                                            )}
                                            {column.dataIndex === 'repair' &&
                                                item.repair === true && <Icon component={RepairIcon}/>}
                                            <div>{subTitle}</div>
                                        </TruncatedText>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                    <ModalConfirm
                        isOpen={confirmation}
                        closeModal={onCloseModalConfirm}
                        actionAfterConsent={() => onOkModalConfirm(item)}
                    />
                </TableCell>
            </Dropdown>
        </>
    );
}
