// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-screen-container {
  display: flex;
  width: 100%;
  height: 71px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #FFF;
  background: #1F1F1F;
  color: #ffffff;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header-screen.scss","webpack://./src/assets/css/virables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,0BAAA;EACA,mBAAA;EACA,cCTmB;ADQrB;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAF","sourcesContent":["@import \"../../assets/css/virables\";\n\n.header-screen-container{\n  display: flex;\n  width: 100%;\n  height: 71px;\n  padding: 14px 24px;\n  justify-content: center;\n  align-items: center;\n  flex-shrink: 0;\n  border-top: 1px solid #FFF;\n  background: #1F1F1F;\n  color: $button-color--text;\n}\n.ellipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n","$button-color: #0055FF;\n$button-color--hover: #0041C3;\n$button-color--hover-before: #003CB4;\n$button-color--text: #ffffff;\n$button-color--radius: 6px;\n$button-color--default: #445371;\n$button-color--border-icon: #E2E4E9;\n$button-color--border-icon-hover: #109BFF;\n\n$content-color: #F8F8F8;\n$sider-color: #FFFFFF;\n$border-color: #E2E4E9;\n$shadow-color: #E2E4E9;\n$white-color: #fff;\n$bg-color: #fff;\n$tab-color: #fff;\n$tab-text-color: #000;\n$tab-active-color: #0148D6;\n$tab-bg-active-color: #E1E9FA;\n$tab-text-active-color: #0148D6;\n$tab-bg-active-color: #E1E9FA;\n$tab-text-active-color: #000;\n$dark-color: #000;\n$error-color: #E14453;\n$error-light-color: #FCE8EA;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
