const TAB_DEAL = 'tabDeal';
const TAB_DOCUMENT = 'tabDocument';
const statusColorsDeal = [
  {
    title: 'Новый',
    color: '#00B288',
    background: '#E6F6EC',
  },
  {
    title: 'Ожидаем оплаты',
    color: '#F29100',
    background: '#FEF4E6',
  },
  {
    title: 'Выдан без оплаты',
    color: '#445371',
    background: '#EDEEF1',
  },
  {
    title: 'Продан',
    color: '#0055FF',
    background: '#F2F6FF',
  },
  {
    title: 'Просрочено',
    color: '#E14453',
    background: '#FCE8EA',
  },
];
enum CURRENCY_TYPE {
  '&#8381;' = 2, // рубль
  '&#36;' = 3, // доллар
  '&#8364;' = 4, // евро

}

export { TAB_DEAL, TAB_DOCUMENT, statusColorsDeal, CURRENCY_TYPE };
