import {IColumnType} from "../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
    {
        title: 'Наименование терминала',
        dataIndex: 'city_child.name',
        link: true,
        checkbox: true,
        menu: true,
        width: '20%',
        key: '1',
    },
    {
        title: 'Родитель',
        dataIndex: 'city_child.city_terminals.name',
        width: '20%',
        link: true,
        key: '2',
    },
    {
        title: 'Адрес',
        dataIndex: 'address_terminal',
        width: '60%',
        key: '3',
    },
    {
        title: 'Контакты',
        dataIndex: 'phone_director',
        width: '20%',
        key: '4',
    },
    {
        title: 'Вместимость',
        dataIndex: 'capacity',
        width: '20%',
        key: '5',
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '6',
    },
];
