import { IColumnType } from '../../components/ui/Table/Table';

export const columns: IColumnType<any>[] = [
    {
        title: 'Фамилия Имя Отчество',
        dataIndex: 'full_name',
        avatar: 'avatar',
        checkbox: true,
        menu: true,
        width: '100%',
        key: '1',
    },
    {
        title: 'Источник',
        dataIndex: 'source',
        key: '2',
        width: '100%',
    },
    {
        title: 'Должность',
        dataIndex: 'position',
        width: '100%',
        key: '3',
    },
    {
        title: 'Телефон',
        dataIndex: 'phone_first',
        width: '30%',
        key: '4',
    },
    {
        title: 'Тип тел.',
        dataIndex: 'type_phone_first',
        width: '30%',
        key: '5',
    },
    {
        title: 'Email',
        dataIndex: 'email_first',
        width: '100%',
        key: '6',
    },
    {
        title: 'Тип Email',
        dataIndex: 'type_email_first',
        width: '30%',
        key: '7',
    },
    {
        title: 'Мессенджер',
        dataIndex: 'messenger_first',
        width: '30%',
        key: '8',
    },
    {
        title: 'Тип мессенджера',
        dataIndex: 'type_messenger_first',
        width: '30%',
        key: '9',
    },
    {
        title: 'Тип контакта',
        dataIndex: 'type_contact',
        width: '30%',
        key: '10',
    },
    {
        title: 'Ответственный',
        dataIndex: 'fio',
        width: '30%',
        key: '11',
    },
        {
        title: 'Компания',
        dataIndex: 'name_company',
            width: '30%',
        key: '12',
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        width: '30%',
        key: '13',
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '14',
    },
];
