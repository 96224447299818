const messagesModal = {
  warning: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите отменить?',
  },
  delete: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите удалить?',
  }
};

const ALL_TYPE_DEAL = 'all-type-deal';

const COMPANY = 'company';
const CONTACTS = 'contacts';
const INTEGRATION = 'integration';
const DEALS = 'deals';
const LEADS = 'leads';

const crmNames: {[key: string]: string} = {
  [COMPANY]: 'Компании',
  [CONTACTS]: 'Контакты',
  [INTEGRATION]: 'Интеграции',
  [DEALS]: 'Сделки',
  [LEADS]: 'Лиды',
};


export {messagesModal, COMPANY, CONTACTS, INTEGRATION, DEALS, LEADS, crmNames, ALL_TYPE_DEAL};