import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Rest_deal} from "../services/rest_deal";
import {sourceContact} from "./contact";

interface IDealState {
   idDeal: string
}

const initialState: IDealState = {
    idDeal: ''
};
export const generateIdDeal = createAsyncThunk(
    'generateIdDeal',
    async (payload: string) => {
        return await Rest_deal.generateIdDeal(payload)
    }
)

const dealSlice = createSlice({
    name: 'deal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(generateIdDeal.fulfilled, (state, action) => {
            state.idDeal = action.payload
        })
    },
});
export default dealSlice.reducer;
//export const {  } = contactSlice.actions
