import React, {useEffect, useState} from 'react';
import {Input} from "antd";

interface NumericInputProps {
    style: React.CSSProperties,
    placeholder: string,
    setValue: (value: string) => void;
    value: string
}

const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

const NumericInput: React.FC<NumericInputProps> = ({
                                                       value,
                                                       setValue,
                                                       style,
                                                       placeholder
                                           }) => {
    const [valueNum, setValueNum] = useState<string>('');
    useEffect(() => {
        setValueNum(value)
    }, [value]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setValue(inputValue);
        }
    };

    const onBlur = () => {
        let val = parseFloat(valueNum);
        if (isNaN(val)) {
            setValue('');
        } else {
            setValue(val.toString());
        }
    };

    return (
        <Input
            allowClear
            style={style}
            value={valueNum}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={16}
        />
    );
};

export default NumericInput;
