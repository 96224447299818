import { Typography } from "antd";
import IRate from "../../components/ui/Rate";
import {IColumnType} from "../../components/ui/Table/Table";
import RouteList from "./components/list/RouteList";

export const columns: IColumnType<any>[] = [
    {
        title: 'ID',
        dataIndex: 'id_deal',
        link: true,
        checkbox: true,
        menu: true,
        width: '40%',
        type: "string",
        key: '1',
    },
    {
        title: 'Название',
        dataIndex: 'name_deal',
        width: '20%',
        type: "string",
        key: '2',
        render: (col, item) => item?.name_deal ? <Typography.Paragraph ellipsis={{rows: 2, tooltip: {placement: "top", title: item.name_deal}}} style={{maxWidth: '250px'}}>{item.name_deal}</Typography.Paragraph> : null
    },
    {
        title: 'Клиент',
        dataIndex: 'company',
        width: '20%',
        type: "string",
        key: '3',
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
        width: '30%',
        type: 'string',
        key: '4',
    },
    {
        title: 'Ответственный',
        dataIndex: 'responsible_name',
        width: '200px',
        key: '5',
        type: 'string',
    },
    {
        title: 'Кол-во КТК',
        dataIndex: 'containers_count',
        width: '20%',
        key: '6',
        type: 'string',
    },
    {
        title: 'Маршрут',
        dataIndex: 'routeText',
        width: '200px',
        key: '7',
        type: 'string',
        render: (col, item) => <RouteList array={item?.route} width={col?.width} />
    },
    {
        title: 'Статус',
        dataIndex: 'status_name',
        width: '20%',
        key: '8',
        type: 'string',
        render: (_, item) => item?.status && item?.status_id ? <IRate isOne desc={[item.status]} value={item.status_id}/> : null
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment_last',
        width: '20%',
        key: '9',
        type: 'string',
        render: (_, item) => item?.comment_last ? <Typography.Paragraph ellipsis={{rows: 2, tooltip: {placement: "left", title: item.comment_last}}} style={{maxWidth: '130px'}}>{item.comment_last}</Typography.Paragraph> : null
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '10',
    },
];
