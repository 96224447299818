import React, {Key, useEffect, useState, CSSProperties, useCallback} from "react";
import {Checkbox, Flex, message, Pagination, Spin} from "antd";
import {TableHeader} from "./TableHeader";
import {TableRow} from "./TableRow";
import {ColPage, ColPageMenu, Footer, TableWrapper} from "./styled";
import ModalConfirm from "../ModalConfirm/ModalConfirm";

export interface IColumnType<T> {
    key: Key;
    title?: string;
    dataIndex: string;
    subTitle?: string;
    date?: boolean;
    avatar?: string;
    checkbox?: boolean;
    link?: boolean;
    menu?: boolean;
    type?: string | undefined;
    width?: number | string;
    widthHeader?: number | string;
    repair?: boolean;
    render?: (column: IColumnType<T>, item: T) => JSX.Element | null;

    [key: number]: string
}

export interface IHiddenColumn<T> {
    type?: string;
    column?: number;
}

export interface IPagination<T> {
    total?: number;
    current?: number;
    pageSize?: number;
    showSizeChanger?: boolean;
}

interface Props<T> {
    dataSource: T[],
    columns: IColumnType<T>[],
    style?: CSSProperties,
    pagination: IPagination<T>,
    onChangePagination?: (pagination: IPagination<T>) => void,
    onRow?: (record: T, rowIndex: number) => void,
    editRow?: (item: T) => void,
    deleteRow?: (row: T) => void,
    onClickLink?: (data: T) => void,
    height?: string,
    loading?: boolean,
    order?: (sort: string) => void,
    filtersFetch?: (text: (string | number), column: IColumnType<any>) => void,
    filters?: (column: any) => void,
    handleCopyAll?: (selectedRows: any) => void,
    handleDeleteCopyAll?: (selectedRows: any) => void,
}

export function Table<T>({
                             dataSource,
                             columns,
                             pagination,
                             style,
                             onChangePagination,
                             onRow,
                             editRow,
                             deleteRow,
                             onClickLink,
                             height,
                             loading,
                             order,
                             filtersFetch,
                             filters,
                             handleCopyAll,
                             handleDeleteCopyAll
                         }: Props<T>): JSX.Element {
    const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState(pagination.pageSize || 10);
    const [sortedData, setSortedData] = useState<T[]>(dataSource);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [currentPage, setCurrentPage] = useState(pagination.current || 1);
    const [allSelected, setAllSelected] = useState(false);
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
    const [confirmation, setConfirmation] = useState<boolean>(false);

    const onChangeCheck = useCallback((index: number) => {
        setHiddenColumns(hiddenColumns.includes(index)
            ? hiddenColumns.filter(i => i !== index)
            : [...hiddenColumns, index]);
    }, [hiddenColumns]);

    const content = (
        <div>
            {columns.map((column, index) => (
                column.dataIndex !== 'hidden' &&
                <ColPageMenu key={index}>
                    <Checkbox onChange={(e) => onChangeCheck(index)}/> {column.title}
                </ColPageMenu>
            ))}
        </div>
    );

    const handlePageChange = useCallback((current: number, pageSize: number) => {
        setCurrentPage(current);
        if (pageSize) {
            setPageSize(pageSize);
        }
        if (onChangePagination) {
            onChangePagination({...pagination, current: current, pageSize});
        }
    }, [onChangePagination, pagination]);

    useEffect(() => {
        setSortedData(dataSource);
    }, [dataSource]);

    const getNestedValue = useCallback((obj: T, path: string) => {
        const parts = path.split('.');
        let value: any = obj;
        for (let part of parts) {
            if (value && value[part] !== undefined) {
                value = value[part];
            } else {
                return undefined;
            }
        }
        return value;
    }, []);

    const sortData = useCallback((dataSource: T[], column: string, order: 'asc' | 'desc') => {
        return dataSource.sort((a, b) => {
            const aValue = getNestedValue(a, column);
            const bValue = getNestedValue(b, column);
            if (aValue === undefined || bValue === undefined) {
                console.error('Undefined value found for column:', column);
                return 0;
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [getNestedValue]);

    const handleSelectAll = (checked: boolean) => {
        setAllSelected(checked);
        if (checked) {
            setSelectedRows(new Set(dataSource.map((item: any) => item.id)));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleRowSelect = (id: number, checked: boolean) => {
        const newSelectedRows = new Set(selectedRows);
        if (checked) {
            newSelectedRows.add(id);
        } else {
            newSelectedRows.delete(id);
        }
        setSelectedRows(newSelectedRows);
        setAllSelected(newSelectedRows.size === dataSource.length);
    };

    const onCloseModalConfirm = () => {
        setConfirmation(false);
    }

    const onOkModalConfirm = () => {
        setConfirmation(false);
        if (handleDeleteCopyAll) {
            handleDeleteCopyAll(selectedRows)
        }

    }

    const handleDeleteAll = (selectedRows: any) =>{
        if (selectedRows.size > 0){
            setConfirmation(true);
        }else {
            message.warning('Нет выделенных ячеек')
        }
    }

    return (
        <div style={style}>
            <div style={{
                marginLeft: 16,
                marginRight: 16,
                overflow: 'scroll',
                height: height,
                scrollbarWidth: "thin",
                scrollbarColor: "#b8b9b9 #dddddd"
            }}>
                <Spin tip="Загрузка..." spinning={loading}>
                    <TableWrapper>
                        <thead>
                        <TableHeader columns={columns} hiddenColumn={hiddenColumns} content={content}
                                     filtersFetch={filtersFetch} filters={filters} order={order}
                                     onSelectAll={handleSelectAll} allSelected={allSelected}/>
                        </thead>
                        <tbody>
                        <TableRow deleteRow={deleteRow} editRow={editRow} data={sortedData} columns={columns}
                                  hiddenColumn={hiddenColumns} onRow={onRow} onClickLink={onClickLink}
                                  selectedRows={selectedRows}
                                  handleCopyAll={() => handleCopyAll ? handleCopyAll(selectedRows) : []}
                                  handleDeleteCopyAll={() =>  handleDeleteAll(selectedRows)}
                                  handleRowSelect={handleRowSelect} />
                        </tbody>
                    </TableWrapper>
                </Spin>
            </div>
            <Footer>
                <Flex justify={'space-between'} align={'flex-start'} style={{width: '100%'}}>
                    <ColPage>
                        {currentPage} страница из {Math.ceil(Number(pagination.total || 0) / pageSize)}
                    </ColPage>
                    <div>
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            showSizeChanger={pagination.showSizeChanger}
                            onShowSizeChange={handlePageChange}
                        />
                    </div>
                </Flex>
            </Footer>
            <ModalConfirm isOpen={confirmation} closeModal={onCloseModalConfirm} actionAfterReject={onOkModalConfirm} />
        </div>
    );
}
