import React, {useEffect, useMemo, useState} from 'react';
import {
    Checkbox, CheckboxProps,
    Col,
    DatePicker,
    Divider,
    Flex,
    Form, Input, Modal,
    Popover,
    Row, Select,
    Skeleton, Tag,
    Typography,
} from "antd";
import {useStoreDispatch} from "../../store";
import {getHistoryContainer, updateNewBooking, updateRepair} from "../../store/containers";
import Icon from "@ant-design/icons";
import {blackCloseIcon, moreIcon,} from "../../assets/image/svg";
import ListComponent from "../../components/ui/List/ListComponent";
import {useTranslation} from "react-i18next";
import {Utils} from "../../utils";
import Button from "../../components/ui/Button/Button";
import moment from "moment/moment";
import {IActivityContainer, IContainersStatus} from "../../types/containers";
import FullScreen from "../../components/ui/FullScreen/FullScreen";
import Carousel from "../../components/ui/FullScreen/Carousel";
import FormModalComments from "./formModalComments";
import Uploader from "../../components/ui/Uploader/Uploader";
import FormModalHistory from "./history/formModalHistory";
import {RangePickerProps} from "antd/es/date-picker";

const {Title, Link} = Typography;

interface Props {
    dataRowContainer?: any,
    closeModalContainers?: any,
    dataStatusContainer?: Array<IContainersStatus>,
    dataComment?: any,
    activityContainer?: IActivityContainer[]
}

const FormModalContainer: React.FC<Props> = ({
                                                 dataRowContainer,
                                                 closeModalContainers,
                                                 dataStatusContainer,
                                                 dataComment,
                                                 activityContainer
                                             }) => {
    const dispatch = useStoreDispatch();
    const {t, i18n} = useTranslation();
    const [dataPhoto, setDataPhoto] = useState<any>([]);
    const [status, setStatus] = useState<string>('Не оплачен');
    const [booking, setBooking] = useState(false);
    const [dateBooking, setDateBooking] = useState(moment().format('DD.MM.YYYY'));
    const [extend, setExtend] = useState(false);
    const [newStatus, setNewStatus] = useState<boolean>(false);
    const [responsible, setResponsible] = useState<string>('');
    const [statusTerminal, setStatusTerminal] = useState<string>('');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [isModalOpenComment, setIsModalOpenComment] = useState<boolean>(false);
    const [parentDataComment, setParentDataComment] = useState<any[]>([]);
    const [checkedRepair, setCheckedRepair] = useState<boolean>(false);
    const [typeModalOpen, setTypeModalOpen] = useState<string>('');

    useEffect(() => {
        setCheckedRepair(dataRowContainer.repair)
    }, [dataRowContainer]);

    useMemo(() => {
        setParentDataComment(dataComment)
        setResponsible(dataRowContainer?.responsible?.fio);
    }, [dataRowContainer?.responsible?.fio, dataComment]);

    useMemo(() => {
        setStatusTerminal(dataRowContainer?.status_terminal?.name)
    }, [dataRowContainer?.status_terminal?.name]);

    const updateDate = useMemo(() => {
        return moment(dateBooking, 'DD.MM.YYYY').add(7, 'days').format('YYYY-MM-DD');
    }, [dateBooking])

    const handlePlaceOrder = (payload: IContainersStatus) => {
        setStatusTerminal(payload.name)
        if (payload.name === 'Хранение') {
            setNewStatus(false);
            dispatch(updateNewBooking({
                ...payload,
                status_id: payload.id,
                id: dataRowContainer?.id,
                booking_before: null,
                responsible_id: Utils.getUserInfo().id
            }))
            setDateBooking(moment().format('DD.MM.YYYY'));
        } else {
            setDateBooking(moment(dateBooking, 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY'));
            setNewStatus(true);
            dispatch(updateNewBooking({
                ...payload,
                status_id: payload.id,
                id: dataRowContainer?.id,
                booking_before: updateDate,
                responsible_id: Utils.getUserInfo().id
            }))
        }
    }

    const handleStatus = (value: any) => {
        if (value === 'Новое бронирование') {
            if (statusTerminal === 'Хранение') {
                setStatusTerminal('')
            }
            setResponsible(Utils.getUserInfo().fio)
            setNewStatus(true);
        }
        if (value === 'История изменений') {
            setIsModalOpenComment(true);
            setTypeModalOpen('history')
            dispatch(getHistoryContainer(dataRowContainer.id))
        }
    }

    const contentOrder = (
        <ListComponent objectList={dataStatusContainer}
                       type={"object"}
                       onClickObject={handlePlaceOrder}/>
    );
    const contentStatus: JSX.Element = (
        <ListComponent arrayList={["Paid", "NotPaid"]}
                       type={"array"}
                       onClickArray={handleStatus}/>
    );
    const containerStatusOrder: JSX.Element = (
        <ListComponent arrayList={["NewBooking", "PlaceOrder", "CancelReservation",
            "HistoryChanges", "ListContainers"]}
                       type={"array"}
                       onClickArray={handleStatus}/>
    );
    const handleDateExtend = (value: any) => {
        setDateBooking(moment(value['$d'], 'DD.MM.YYYY').format('DD.MM.YYYY'));
        setExtend(false);
    }

    const handleCloseExtend = () => {
        setExtend(false);
    }

    const closeContainers = () => {
        closeModalContainers()
        setNewStatus(false)
    }

    const onCloseModalImages = () => {
        setPreviewVisible(false)
    }

    const onOpenModalImages = () => {
        setPreviewVisible(true)
    }

    const handleComment = () => {
        setIsModalOpenComment(true);
        setTypeModalOpen('comment')
    }

    const handleCloseComment = () => {
        setIsModalOpenComment(false)
    }

    const formatFIO = (fullName: string) => {
        if (!fullName) return '';
        const [lastName, firstName, middleName] = fullName.split(' ');
        if (!lastName || !firstName || !middleName) return fullName; // обработка случая, когда имя неполное
        return `${lastName} ${firstName[0]}. ${middleName[0]}.`;
    };

    const updateParentDataComment = (newData: any, type: string) => {
        if (type === 'add') {
            setParentDataComment((prevData: any) => [newData, ...prevData]);
        } else {
            setParentDataComment(newData);
        }
    };

    const content = (parentDataComment.length > 0 && <>
            <div>{formatFIO(parentDataComment[0]?.['user.fio'])} {moment(parentDataComment[0]?.createdAt).format('DD.MM.YYYY H:mm')}</div>
            <div className={'box-popover'}>{parentDataComment[0]?.text}</div>
        </>);

    const checkRepair: CheckboxProps['onChange'] = (e) => {
        setCheckedRepair(e.target.checked)
        dispatch(updateRepair({
            repair: e.target.checked,
            id: dataRowContainer?.id,
            user_id: Utils.getUserInfo().id
        }))
    };

    const closeModalHistory = () => {
        setIsModalOpenComment(false);
        setTypeModalOpen('')
    }

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        const today = moment().startOf('day');
        const limitDate = moment().add(7, 'days');
        return current && (current < today || current > limitDate);
    };

    const renderTag = (label: string, value: any, dateFormat = false, isStatus = false) => {
        const hasValue = Boolean(value);
        const displayValue = hasValue ? (dateFormat ? moment(value).format('DD.MM.YYYY') : value) : 'Отсутствует';

        const style = isStatus
            ? hasValue
                ? { color: "#13AA64", background: '#CEFBE7' }
                : { color: "#445371", background: '#EDEEF1' }
            : hasValue
                ? { color: "#0055FF", background: '#E6EEFF' }
                : { color: "#445371", background: '#EDEEF1' };

        return (
            <Flex vertical gap={4}>
                <Typography.Text className={'text-card-12'}>{label}</Typography.Text>
                <Tag bordered={false} style={style}>
                    {displayValue}
                </Tag>
            </Flex>
        );
    };

    return (
        <>
            <Row className={'modal-container'}>
                <Flex align={'center'} vertical={false} style={{width: '100%'}}>
                    <Col span={7}>
                        <Title level={4} style={{marginBottom: 0}}>{dataRowContainer?.num_container}</Title>
                    </Col>
                    <Col span={15} className={'text-base'}>
                        <Flex gap={12}>
                            <div>{dataRowContainer?.type_container}</div>
                            <div>|</div>
                            <div>Собственник: {dataRowContainer?.contractors?.name_company}</div>
                            <div className={'line'}>|</div>
                            <div>Терминал: {dataRowContainer?.child_terminal?.name}</div>
                        </Flex>
                    </Col>
                    <Col span={2}>
                        <Flex gap={10} justify={"flex-end"} style={{cursor: "pointer"}}>
                            <Popover placement="bottomRight" content={containerStatusOrder} trigger="click">
                                <Icon component={moreIcon}/>
                            </Popover>
                            <Icon component={blackCloseIcon} onClick={closeContainers}/>
                        </Flex>
                    </Col>
                </Flex>
            </Row>
            <Divider style={{marginBottom: 0, marginTop: 8}}/>
            <Form autoComplete="off" >
                <Flex className={'container-modal'} vertical gap={20}>
                    <Row gutter={[16, 16]}>
                        <Col>
                            {renderTag('Состояние', dataRowContainer.quality,false,true)}
                        </Col>
                        <Col>
                            {renderTag('Дата входа', dataRowContainer.date_entry, true)}
                        </Col>
                        <Col>
                            {renderTag('Дата прибытия', dataRowContainer.arrival_date, true)}
                        </Col>
                        <Col>
                            {renderTag('Дата убытия', dataRowContainer.departure_date, true)}
                        </Col>
                        <Col>
                            {renderTag('Входящая ставка', dataRowContainer.currency_price)}
                        </Col>
                    </Row>
                    <Flex style={{marginBottom: 24, width: '100%'}}
                          className={dataRowContainer.delete ? 'disabled-div' : 'enabled-div'}>
                        <Col span={8}>
                            <Form.Item name={'status'}>
                                <Flex vertical gap={9}>
                                    <Title level={5} className={'text-base sm'}>
                                        <Flex gap={14}>
                                            <div>{t("current_status")}</div>
                                            {
                                                (statusTerminal === 'Хранение') ?
                                                    <div className={'text-base sm blue'}>{statusTerminal}</div>
                                                    :
                                                    <Popover placement="bottom" content={contentOrder} trigger="click">
                                                        <div
                                                            className={'text-base sm blue'}> {t('Booking') + ' ' + statusTerminal}</div>
                                                    </Popover>
                                            }
                                        </Flex>
                                    </Title>
                                    <Title level={5} className={'text-base sm'} style={{margin: '0 0 10px'}}>
                                        <Flex gap={14}>
                                            <div>Статус оплаты:</div>
                                            <Popover placement="bottom" content={contentStatus} trigger="click">
                                                <div className={'text-base sm blue'}>{status}</div>
                                            </Popover>
                                        </Flex>
                                    </Title>
                                </Flex>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Title level={5} className={'text-base sm'}>
                                    <Flex gap={14} justify={"space-between"}>
                                        <Flex gap={65}>
                                            <div>Бронь до:</div>
                                            <Flex gap={9} style={{height: 0}}>
                                                <Flex vertical>
                                                    {statusTerminal !== 'Хранение' ?
                                                        <Link onClick={() => setBooking(!booking)}>
                                                            <div className={'text-base sm red'}>{dateBooking}</div>
                                                        </Link>
                                                        :
                                                        <Link onClick={() => setBooking(!booking)}
                                                              disabled={!newStatus}>
                                                            <div className={'text-base sm'}>{dateBooking}</div>
                                                        </Link>
                                                    }
                                                </Flex>
                                                <Flex vertical>
                                                    {statusTerminal !== 'Хранение' && !newStatus ?
                                                        <Link onClick={() => setExtend(!extend)}>
                                                            <div className={'text-base sm blue'}>Продлить</div>
                                                        </Link>
                                                        :
                                                        <Link onClick={() => setExtend(!extend)} disabled={!newStatus}>
                                                            <div className={'text-base sm'}>Продлить</div>
                                                        </Link>
                                                    }
                                                    <DatePicker
                                                        disabledDate={disabledDate}
                                                        open={extend}
                                                        onOpenChange={handleCloseExtend}
                                                        style={{visibility: 'hidden', bottom: 35}}
                                                        onChange={handleDateExtend}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Title>
                                <Title level={5} className={'text-base sm'}>
                                    <Flex gap={14}>
                                        <div>Ответственный:</div>
                                        <div className={'text-base sm blue'}>{responsible}</div>
                                    </Flex>

                                </Title>
                            </Form.Item>
                        </Col>
                        <Flex gap={7} vertical>
                            <Checkbox
                                className={'text-base sm'}
                                checked={checkedRepair}
                                onChange={checkRepair} disabled={dataRowContainer.delete}>
                                В ремонте
                            </Checkbox>
                            <Select
                                defaultValue="Доставка"
                                style={{width: 150}}
                                options={[{value: 'Доставка', label: 'Доставка'}]}
                            />
                        </Flex>
                    </Flex>
                    <Flex gap={22} vertical>
                        <Flex className={'container-photo'}>
                            <Uploader ids_photo={dataRowContainer?.ids_photo} type={'view'}
                                      setDataPhoto={setDataPhoto}/>
                            {dataPhoto.length === 0 ?
                                <>
                                    <Skeleton.Image active={dataRowContainer?.photo_bool !== 'нет'}/>
                                    <Skeleton.Image active={dataRowContainer?.photo_bool !== 'нет'}/>
                                    <Skeleton.Image active={dataRowContainer?.photo_bool !== 'нет'}/>
                                    <Skeleton.Image active={dataRowContainer?.photo_bool !== 'нет'}/>
                                </>
                                :
                                <Flex gap={10} onClick={onOpenModalImages}>
                                    {dataPhoto?.map((row: any, index: number) =>
                                        <div key={index} className={'box-image-container'}>
                                            <img src={row.url} width={'100%'} height={78} alt={row.name}
                                                 className={'box-image'}/>
                                        </div>
                                    )}
                                </Flex>
                            }
                            {dataPhoto.length > 0 &&
                                <FullScreen isOpen={previewVisible}>
                                    <Carousel images={dataPhoto} onClick={onCloseModalImages}/>
                                </FullScreen>
                            }
                        </Flex>
                        <Flex gap={12} vertical>
                            <div className='box-container-activ'>
                                    <div className='table-title label-card-20'>Активность</div>
                                <div className='table-container'>
                                    <div className='table-header'>
                                        <div className={'label-card-16'}>Дата</div>
                                        <div className={'label-card-16'}>Статус</div>
                                        <div className={'label-card-16'}>Ответственный</div>
                                    </div>
                                    {
                                        activityContainer?.map((item:IActivityContainer) => (
                                            <div key={item.id} className='table-row'>
                                                <div className='table-cell'>{moment(item.created_at).format('DD.MM.YY H:mm')}</div>
                                                <div className='table-cell'>{
                                                   item.repair ? 'В ремонте' : item.status.name
                                                }</div>
                                                <div className='table-cell'>{item.user.fio}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={'box-container'}>
                                <div className={'text-base xs'}>Перемещения</div>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justify={'flex-start'} style={{width: '100%'}}>
                    <Popover placement="topLeft"
                             content={content}
                             arrow={false}>
                        <Button type={'text'} style={{width: 151, color: '#05F'}}
                                onClick={handleComment}>Комментарий</Button>
                    </Popover>
                </Flex>
            </Form>
            <Modal
                open={isModalOpenComment}
                footer={null}
                closable={typeModalOpen === 'comment'}
                width={typeModalOpen === 'comment'? 828 : '80%'}
                centered
                onCancel={handleCloseComment}
                title={typeModalOpen === 'comment' && "Комментарий"}>
                {typeModalOpen === 'comment' ?
                <FormModalComments
                    rowId={dataRowContainer?.id}
                    dataComment={dataComment}
                    updateParentDataComment={updateParentDataComment}/>
                    :
                    <FormModalHistory
                        dataRowContainer={dataRowContainer}
                        activityContainer={activityContainer}
                        closeModalHistory={closeModalHistory}
                    />
                }
            </Modal>
        </>
    );
};

export default FormModalContainer;
