// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-menu-item {
  padding-left: 5px !important;
}

.ant-menu-submenu-title {
  padding-left: 5px !important;
}

.ant-menu-item-only-child {
  padding-left: 45px !important;
}

.ant-menu-item-icon:hover {
  background: #E1E9FA !important;
  border-radius: 6px;
  padding: 5px;
  color: #69b1ff;
}

.ant-menu-item-icon {
  border-radius: 6px;
  padding: 5px;
}

.ant-menu-item-icon:focus {
  background: #E1E9FA !important;
  border-radius: 6px;
  padding: 5px;
}

.depo-icon:hover {
  fill: #0148D7;
}

.depo-icon:focus {
  fill: #0148D7;
}

.user-icon:hover {
  fill: #0148D7;
}

.sale-icon:hover {
  fill: #0148D7;
}

.rent-icon:hover {
  fill: #0148D7;
}

.logistics-icon:hover {
  fill: #0148D7;
}

.finance-icon:hover {
  fill: #0148D7;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/menu.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AACA;EACE,4BAAA;AAEF;;AAAA;EACE,6BAAA;AAGF;;AAAA;EACE,8BAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AAGF;;AAAA;EACE,kBAAA;EACA,YAAA;AAGF;;AAAA;EACE,8BAAA;EACA,kBAAA;EACA,YAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,aAAA;AAGF","sourcesContent":[".ant-menu-item {\n  padding-left: 5px !important;\n}\n.ant-menu-submenu-title{\n  padding-left: 5px !important;\n}\n.ant-menu-item-only-child{\n  padding-left: 45px !important;\n}\n\n.ant-menu-item-icon:hover {\n  background: #E1E9FA !important;\n  border-radius: 6px;\n  padding: 5px;\n  color: #69b1ff;\n}\n\n.ant-menu-item-icon {\n  border-radius: 6px;\n  padding: 5px;\n}\n\n.ant-menu-item-icon:focus {\n  background: #E1E9FA !important;\n  border-radius: 6px;\n  padding: 5px;\n}\n\n.depo-icon:hover {\n  fill: #0148D7;\n}\n\n.depo-icon:focus {\n  fill: #0148D7;\n}\n\n.user-icon:hover {\n  fill: #0148D7;\n}\n\n.sale-icon:hover {\n  fill: #0148D7;\n}\n\n.rent-icon:hover {\n  fill: #0148D7;\n}\n\n.logistics-icon:hover {\n  fill: #0148D7;\n}\n\n.finance-icon:hover {\n  fill: #0148D7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
