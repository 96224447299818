import React from 'react';
import Layouts from "./Layouts";
import {Flex, Tabs, TabsProps} from "antd";
import Terminals from "../pages/terminals";
import {useTranslation} from "react-i18next";
import Containers from "../pages/containers";

const DepoContainers = () => {
    const {t, i18n} = useTranslation();

    const items: TabsProps['items'] = [
        { key: '1', label: t('Terminals'), children: <Terminals/> },
        { key: '2', label: t('Containers'), children: <Containers/> },
    ];

    return (
        <Layouts>
            <Flex style={{width:'100%'}} vertical>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    style={{width:'100%'}}
                    indicator={{ size: (origin: any) => origin - 100, align: "center" }}
                />
            </Flex>
        </Layouts>
    );
};

export default DepoContainers;
