import {useEffect} from 'react';
import './assets/css/App.scss';
import {Layout} from "antd";
import AppRouter from "./components/AppRouter";
import {Utils} from "./utils";
import {BrowserRouter, useHistory} from "react-router-dom";
import {setAuth} from "./store/users"
import {useStoreDispatch} from "./store";

function App() {
    const history = useHistory();
    const dispatch = useStoreDispatch()

    useEffect(() => {
        if (Utils.getStorage('_in') === null){
            dispatch(setAuth(false))
            history.push("/");
        }else {
            dispatch(setAuth(true))
        }
    },[dispatch,history])
    return (
        <Layout style={{height: 'calc(100vh - 0)'}}>
            <Layout.Content>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </Layout.Content>
        </Layout>
    );
}

export default App;
