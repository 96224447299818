// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  padding: 0;
  width: 100% !important;
}

.text-language {
  color: #0E1117;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 2px;
}

.box-language {
  border: 1px solid transparent;
  display: flex;
  height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset;
  padding: 0 4px;
}

.box-language:hover {
  border: 1px solid #0048D9;
  display: flex;
  height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset;
  padding: 0 4px;
  color: #0048D9;
}

.box-language:active {
  background-color: rgba(0, 85, 255, 0.1);
  border: 1px solid transparent;
  color: #0048D9;
}

.box-language:focus {
  background-color: rgba(0, 85, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,sBAAA;AACF;;AACA;EACE,cAAA;EACA,kBAAA;EACA,gEAAA;EACA,6CAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAEF;;AAAA;EACE,6BAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,0CAAA;EACA,cAAA;AAGF;;AADA;EACE,yBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,0CAAA;EACA,cAAA;EACA,cAAA;AAIF;;AAFA;EACE,uCAAA;EACA,6BAAA;EACA,cAAA;AAKF;;AAHA;EACE,uCAAA;AAMF","sourcesContent":[".header-container{\n  padding: 0;\n  width: 100% !important;\n}\n.text-language{\n  color: #0E1117;\n  text-align: center;\n  font-variant-numeric: lining-nums tabular-nums stacked-fractions;\n  font-feature-settings: 'clig' off, 'liga' off;\n  font-family: Manrope;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 16px;\n  margin-top: 2px;\n}\n.box-language{\n  border: 1px solid transparent;\n  display: flex;\n  height: 24px;\n  min-width: 24px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 6px;\n  background: #FFF;\n  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.00) inset;\n  padding: 0 4px;\n}\n.box-language:hover{\n  border: 1px solid #0048D9;\n  display: flex;\n  height: 24px;\n  min-width: 24px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 6px;\n  background: #FFF;\n  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.00) inset;\n  padding: 0 4px;\n  color: #0048D9;\n}\n.box-language:active{\n  background-color: rgba(0, 85, 255, 0.10);\n  border: 1px solid transparent;\n  color: #0048D9;\n}\n.box-language:focus{\n  background-color: rgba(0, 85, 255, 0.10);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
