import {SettingOutlined} from "@ant-design/icons";
import {Button, Divider, Flex, Form, notification, Space, Typography} from "antd";
import React, {memo, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import DrawerComponent from "../../components/DrawerComponent";
import {IPagination, Table} from '../../components/ui/Table/Table';
import socket from "../../socket";
import {RootState, useStoreDispatch} from "../../store";
import {getContact, deleteContact, updateContact, saveContact} from '../../store/contact';
import {entitiesInfo, UserInfo} from "../../store/users";
import {ITypeQuality} from '../../types';
import {IContacts} from '../../types/contacts';
import {IUsers} from "../../types/users";
import {Utils} from "../../utils";
import {columns} from "./columns";
import FormContacts from "./formContact";
import ViewContacts from './viewContacts';
import {useCsvExport} from "../../hooks";

const {Title} = Typography;

const Contacts = () => {
    const [form] = Form.useForm();
    const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [isAddContact, setIsAddContact] = useState<boolean>(false);
    const [current, setCurrent] = useState<number>(0);
    const [page, setPage] = useState<number>(20);
    const [dataContact, setDataContact] = useState<IContacts[]>([]);
    const dispatch = useStoreDispatch();
    const userInfoData: IUsers[] = useSelector((state: RootState) => state.users.userInfoData);
    const dataTypeContact = useSelector((state: RootState) => state.types.dataTypeContact);
    const dataTypeQuality: ITypeQuality[] = useSelector((state: RootState) => state.types.dataTypeQuality);
    const totalData: number = useSelector((state: RootState) => state.contact.totalData);
    const contacts: IContacts[] = useSelector((state: RootState) => state.contact.dataContact);
    const [changeContacts, setChangeContacts] = useState<any[]>(Utils.defaultTable);
    const [api, contextHolder] = notification.useNotification();
    const { generateCSV, copyToClipboard } = useCsvExport({
        fields: columns
            .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
            .map((item: any) => item.dataIndex) // Создаем массив из dataIndex
    });

    const fetchData = async (current: number, page: number) => {
        setLoading(true)
        const res = await dispatch(getContact({current: (current - 1) * page, page: page}));
        if (res.payload.data.length >= 0) {
            setChangeContacts(res.payload.data[0])
            setLoading(false)
        }
    }

    const deleteContactHandler = async (row: any) => {
        dispatch(deleteContact({
            current: (current - 1) * page ,
            page: page,
            id: row.id,
            delete: row.delete !== true
        }))
    }

    const findEntitiesStatus = (response: any) => {
        response.res_client.forEach((item: any) => {
            item.entities === 'addContact' && setIsAddContact(item.status);
        })
    }

    const returnUpdateContact = (response: any) => {
        if (JSON.parse(response).success === 0) {
            Utils.InfoOpenNotification('topRight', JSON.parse(response).message, 20,api);
        } else {
            setChangeContacts(prevData => {
                return prevData.map(item =>
                    item.id === JSON.parse(response).data.id ? {...JSON.parse(response).data} : item
                );
            });

            if (Utils.getUserInfo().id === JSON.parse(response).data.user_id) {
                Utils.openNotification('topLeft', JSON.parse(response).message, api);
            }
            setOpenEdit(false);
            form.resetFields();
        }
    }

    const resUpdateEntities = (response: any) => {
        if (Utils.role().id === response.role_id) {
            response.res_client.map((item: any) => {
                item.entities === 'addContact' && setIsAddContact(item.status);
            })
        }
    }

    const resUpdateGroupEntities = (response: any) => {
        if (Utils.role().id === response.role_id) {
            response.res_client.map((item: any) => {
                item.entities === 'addContact' && setIsAddContact(item.status);
            })
        }
    }

    const returnNewContact = (response: any) => {
        if (JSON.parse(response).success === 0) {
            Utils.InfoOpenNotification('topRight', JSON.parse(response).message, 20,api);
        } else {
            setChangeContacts((prev: any) => {
                return [JSON.parse(response).data,...prev];
            })
            if (Utils.getUserInfo().id === JSON.parse(response).data.user_id) {
                Utils.openNotification('topLeft', JSON.parse(response).message, api);
            }
            setOpen(false);
            form.resetFields();
        }
    }

    const returnDeleteContact = (response: any) => {
        setChangeContacts(prevData => {
            // Проверьте, что response является массивом, если это необходимо
            if (!Array.isArray([response])) {
                console.error('Invalid response data:', [response]);
                return prevData;
            }
            // Предположим, что response это объект с обновленными данными
            const updatedItem = response;
            return prevData.map(item =>
                item.id === updatedItem.id ? { ...item, ...updatedItem } : item
            );
        });
    }

    useEffect(() => {
        dispatch(UserInfo({id: Utils.getUserInfo().id}))
        fetchData(current,page).then(r => r);
        dispatch(entitiesInfo(Utils.role())).then((response: any) => {
            findEntitiesStatus(response.payload)
        })

        socket.on('return-update-contact',returnUpdateContact);
        socket.on('res-update-entities', resUpdateEntities);
        socket.on('res-update-group-entities', resUpdateGroupEntities);
        socket.on('return-new-contact', returnNewContact);
        socket.on('return-delete-contact', returnDeleteContact)

        setChangeContacts(contacts)

        return () => {
            socket.off('return-delete-contact', returnDeleteContact);
            socket.off('return-new-contact', returnNewContact);
            socket.off('res-update-entities', resUpdateEntities);
            socket.off('res-update-group-entities', resUpdateGroupEntities);
            socket.off('return-update-contact',returnUpdateContact);
        }
    }, [current, dispatch, page]);

    const showDrawer = () => {
        form.resetFields()
        setEvent(true)
        setOpen(true);
    };

    const handleEditContact = async (data: any) => {
        form.resetFields();
        setDataContact(data);
        setOpen(true);
        setEvent(false);
    };

    const onChange = async (pagination: IPagination<any>) => {
        if (pagination.current != null && pagination.pageSize != null) {
            await fetchData(current, pagination.pageSize);
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleShowEditForm = (data: any) => {
        setDataContact(data);
        setOpenEdit(true);
        setEvent(false)
    }

    const onFinish = (data: IContacts) => {
        if (event){
            dispatch(saveContact(data));
        }else {
            dispatch(updateContact(data));
        }
    };

    const handleCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        const rowsData = generateCSV(arSelectedRows, changeContacts.length > 0 ? changeContacts : contacts);
        copyToClipboard(rowsData);
    };

    const handleDeleteCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        arSelectedRows.forEach((item: any) => {
            // Оптимизированная фильтрация
            const deleteData = changeContacts.find(el => el.id === item && el.delete !== true);

            if (deleteData) {
                dispatch(deleteContact({
                    current: (current - 1) * page,
                    page: page,
                    id: item,
                    delete: deleteData.delete !== true
                }));
            }
        });
    };

    return (
        <>
            {contextHolder}
            <Flex justify={"space-between"} align={"flex-start"} style={{margin: "20px 28px 0px"}}>
                <Title level={2}>Контакты</Title>
                <Flex gap={16}>
                    <Button onClick={showDrawer} type={'primary'}>Создать</Button>
                    <Button icon={<SettingOutlined/>} type={'text'}/>
                </Flex>
            </Flex>
            <Divider style={{margin: 0}}/>
            <Space direction="vertical" size="middle" style={{width: "100%"}}>
                <Table
                    dataSource={changeContacts.length > 0 ? changeContacts : contacts}
                    columns={columns}
                    loading={loading}
                    pagination={{pageSize: page, total: Number(totalData), showSizeChanger: true}}
                    style={{overflow: "auto hidden"}}
                    onChangePagination={onChange}
                    editRow={handleShowEditForm}
                    height={'calc(100vh - 309px)'}
                    deleteRow={deleteContactHandler}
                    handleDeleteCopyAll={handleDeleteCopyAll}
                    handleCopyAll={handleCopyAll}
                    onRow={(record) => {
                        form.resetFields();
                        return handleEditContact(record);
                    }}
                />
            </Space>
            <DrawerComponent
                open={open}
                form={event
                    ?
                    <FormContacts
                        form={form}
                        onFinish={onFinish}
                        onClose={onClose}
                        event={event}
                        pagination={{pageSize: page, current: current}}
                        title={'Контакты'}
                        dataTypeContact={dataTypeContact}
                        dataContacts={dataContact}
                        dataTypeQuality={dataTypeQuality}
                        userInfoData={userInfoData}/>
                    :
                    <ViewContacts
                        onClose={onClose}
                        dataContacts={dataContact}
                        form={form}
                        title={'Редактировать компанию'}
                        handleShowEditForm={handleShowEditForm}
                        event={event}
                        pagination={{pageSize: page, current: current}}
                        dataTypeContact={dataTypeContact}
                        dataTypeQuality={dataTypeQuality}
                        userInfoData={userInfoData}
                        handleEditContact
                    />
                }
            />
            <DrawerComponent
                open={openEdit}
                form={
                    <FormContacts
                        event={event}
                        form={form}
                        onFinish={onFinish}
                        onClose={onCloseEdit}
                        pagination={{pageSize: page, current: current}}
                        title={'Контакты'}
                        dataTypeContact={dataTypeContact}
                        dataContacts={dataContact}
                        dataTypeQuality={dataTypeQuality}
                        userInfoData={userInfoData}
                    />
                }
            />
        </>
    );
};

export default memo(Contacts);
