import React from 'react';
import '../style.scss'

interface Props {
    hovered: boolean,
    className: any,
    knobClassName: any,
    children: any
}

const Scroll = ({ children, className }: Props) => {
    return (
        <div className={`scroll-instance_deal ${className}`} style={{ /* ваши стили */ }}>
            {children}
        </div>
    );
};

export default Scroll;
