import { useEffect, useState } from 'react';

import { Flex, Spin, Tabs, TabsProps, Typography } from 'antd';

import Layouts from './Layouts';
import { Utils } from '../utils';
import Contacts from '../pages/contacts/Contacts';
import { RouteNames } from '../router/names';
import Contractors from '../pages/contractors/Contractors';
import { useBreadcrumb, useParamsHistory } from '../hooks';
import {
  COMPANY,
  CONTACTS,
  crmNames,
  DEALS,
  INTEGRATION,
  LEADS,
} from '../constants';

import '../assets/css/srm-container.scss';

const { Title } = Typography;

const CrmContainers = () => {
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  //const [isLoading, setIsLoading] = useState(true);
  const [activeKey, setActiveKey] = useState(() => COMPANY);
  const [name, setName] = useState('');
  const [activeTitle, setActiveTitle] = useState(() => crmNames[COMPANY]);
  useBreadcrumb(['CRM', activeTitle]);

  const items: TabsProps['items'] = [
    { key: LEADS, label: crmNames[LEADS], children: 'Лиды' },
    { key: DEALS, label: crmNames[DEALS], children: 'Сделки' },
    { key: COMPANY, label: crmNames[COMPANY], children: <Contractors /> },
    { key: CONTACTS, label: crmNames[CONTACTS], children: <Contacts /> },
    { key: INTEGRATION, label: crmNames[INTEGRATION], children: 'Интеграции' },
  ];

  const handleClickTab = (key: string) => {
    setActiveKey(key);
    history.replace(`${RouteNames.CRM}/${key}`);
  };

  useEffect(() => {
    const localUser = async () => {
      if (Utils.getStorage('_in') !== null) {
        const user = await Utils.decrypt(Utils.getStorage('_in'));
        setName(user.name === null ? '' : user.name);
      }
    };
    localUser();
  }, []);

  useEffect(() => {
    //setIsLoading(true);
    const isCrm = pathname.includes(RouteNames.CRM);
    const [, current] = pathname.split(isCrm ? RouteNames.CRM : '/');
    const [, path] = current?.split('/');
    switch (path) {
      case LEADS:
        setActiveKey(LEADS);
        setActiveTitle(crmNames[LEADS]);
      //  setIsLoading(false);
        return;
      case DEALS:
        setActiveKey(DEALS);
        setActiveTitle(crmNames[DEALS]);
        //setIsLoading(false);
        return;
      case CONTACTS:
        setActiveKey(CONTACTS);
        setActiveTitle(crmNames[CONTACTS]);
        //setIsLoading(false);
        return;
      case COMPANY:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
       // setIsLoading(false);
        return;
      case INTEGRATION:
        setActiveKey(INTEGRATION);
        setActiveTitle(crmNames[INTEGRATION]);
       // setIsLoading(false);
        return;
      default:
      //  setIsLoading(false);
        history.push(RouteNames.CRM_COMPANY);
    }
  }, [pathname]);

  return (
    <Layouts>
      <Flex style={{ width: '100%' }} vertical>
        <Title level={2} style={{ padding: '20px 18px' }}>
          Доброе утро {name ?? ''}!
        </Title>
  {/*      <Spin spinning={isLoading}>*/}
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={handleClickTab}
            items={items}
            style={{ width: '100%' }}
            indicator={{ size: (origin: any) => origin - 100, align: 'center' }}
          />
       {/* </Spin>*/}
      </Flex>
    </Layouts>
  );
};

export default CrmContainers;
