import Button from "../../components/ui/Button/Button";
import {
    BitrixIcon, ChatIcon,
    EmailIcon,
    FacebookIcon,
    InstagramIcon,
    PencilIcon,
    SkypeIcon, TelegramIcon,
    ViberIcon,
    VkIcon,
    WatsappIcon
} from "../../assets/image/svg";

export class Icons {
    static arrIcon = (type: any) => {
        if (type.type === "Facebook") {
            return  (
                <Button icon={<FacebookIcon/>} type={'icon'}/>
            );
        } else if (type.type === "ВКонтакте") {
            return  (
                <Button icon={<VkIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Pencil") {
            return  (
                <Button icon={<PencilIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Watsapp") {
            return  (
                <Button icon={<WatsappIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Viber") {
            return  (
                <Button icon={<ViberIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Skype") {
            return  (
                <Button icon={<SkypeIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Instagram") {
            return  (
                <Button icon={<InstagramIcon/>} type={'icon'}/>
            );
        }else if (type.type === "Битрикс24") {
            return  (
                <Button icon={<BitrixIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Chat") {
            return  (
                <Button icon={<ChatIcon/>} type={'icon'}/>
            );
        } else if (type.type === "Telegram") {
            return  (
                <Button icon={<TelegramIcon/>} type={'icon'}/>
            );
        }
        return null
    }
}
