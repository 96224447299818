import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { AutoComplete, Button, Flex, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { ViewDeal } from './components';
import { RouteNames } from '../../router/names';
import Container from '../../components/Container';
import { findOneDeal, getContainerDealStatuses, getTypeDeal, IDeal, setOneDeal } from '../../store/deal';
import { Table } from '../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../store';
import { useBreadcrumb, useParamsHistory } from '../../hooks';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { getTypeForm, VIEW } from '../../helpers/string-helpers';
import { t } from 'i18next';
import { getCurrency, getPercent } from '../../store/store';
import { findMyCompany } from '../../store/contractors';

const ViewPageDeal = () => {
  useBreadcrumb(['Продажи', 'Сделки']);
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealOneData = useSelector<RootState>(
    (state) => state.deal.dealOneData,
  ) as IDeal;
  const loadingOneDeal = useSelector<RootState>(
    (state) => state.deal.loadingOneDeal,
  ) as boolean;
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.deal.containerDealStatuses,
  ) as { id: number; value: string }[];
  const dealType = useSelector<RootState>((state) => state.deal.dealType) as {
    id: number;
    value: string;
  }[];
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);

  const onCloseFormDeal = () => {
    setOpenDrawerDeal(false);
    history.push(RouteNames.DEAL);
  };


  useEffect(() => {
    if (!containerDealStatuses?.length) {
      dispatch(getContainerDealStatuses());
    }
    if (!dealType?.length) {
      dispatch(getTypeDeal());
    }
  }, []);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(findMyCompany());
    dispatch(getPercent());
  }, []);

  useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.DEAL);
    const path = getTypeForm(current);

    if (!path) return;

    switch (path) {
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          dispatch(findOneDeal(+idView))
            .then((res) => {
              if (res?.payload?.deal?.id) {
                setOpenDrawerDeal(true);
              } else {
                history.push(RouteNames.DEAL);
              }
            })
            .catch((err) => {
              history.push(RouteNames.DEAL);
            });
          return;
        }
        return history.push(RouteNames.DEAL);
      default:
        history.push(RouteNames.DEAL);
    }
  }, [pathname]);
  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Deal')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff' }}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            popupMatchSelectWidth={false}
          >
            <Input placeholder="Введите текст" />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          loading={loadingOneDeal}
          columns={columns}
          dataSource={[dealOneData]}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: 1,
            total: 1,
            showSizeChanger: true,
          }}
        />
      </Space>
      <CustomDrawer open={openDrawerDeal} onClose={onCloseFormDeal} isHeight>
        <ViewDeal
          deal={dealOneData}
          onClose={() => {
            dispatch(setOneDeal({}));
            setOpenDrawerDeal(false);
            history.push(RouteNames.DEAL);
          }}
          onEdit={() =>
            history.push(`${RouteNames.DEAL_EDIT}${dealOneData?.id}`)
          }
          isOpen={openDrawerDeal && !loadingOneDeal}
        />
      </CustomDrawer>
    </Container>
  );
};

export default ViewPageDeal;
