import React, {useState} from 'react';
import {Button, Col, Dropdown, DropdownProps, Flex, Input, Layout, Menu, MenuProps, Row, Typography} from "antd";
import Icon, {
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, SearchOutlined,
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {RootState, useStoreDispatch} from "../../store";
import {setDrawer} from "../../store/store";
import moment from "moment";
import "moment/locale/ru";
import {useTranslation} from "react-i18next";
import {languageIcon} from "../../assets/image/svg";
import '../../assets/css/header.scss'


const {Header} = Layout;
const {Text} = Typography;


const HeaderComponent = (props: any) => {
    const dispatch = useStoreDispatch();
    const isDrawer = useSelector((state: RootState) => state.stores.isDrawer);
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState("RU");
    const [open, setOpen] = useState(false);
    const [activeKey, setActiveKey] = useState(null);

    const handleMenuClick: MenuProps['onClick'] = (e: any) => {
        setActiveKey(e.key);
        if (e.key === '1') {
            i18n.changeLanguage("ru").then(r => r);
            setLanguage("RU")
            setOpen(false);
        }
        if (e.key === '2') {
            i18n.changeLanguage("en").then(r => r);
            setLanguage("EN")
            setOpen(false);
        }
    };

    const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    const menu: any = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" style={{ backgroundColor: activeKey === '1' ? 'rgba(0, 85, 255, 0.10)' : '' }}>
                RU
            </Menu.Item>
            <Menu.Item key="2" style={{ backgroundColor: activeKey === '2' ? 'rgba(0, 85, 255, 0.10)' : '' }}>
                EN
            </Menu.Item>
        </Menu>
    );
    return (
        <Header style={{width: '100%'}}>
            <Flex vertical={false} justify={"space-between"} style={{width: '100%', height: 57}}>
                <Flex align={"center"}>
                    {isDrawer &&
                        <Button type="text"
                                icon={isDrawer ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                onClick={() => dispatch(setDrawer(false))}/>
                    }
                    <div>
                        <Text strong>{props.title} </Text>   &bull;  <Text type="secondary" strong>Контакты</Text>
                    </div>
                </Flex>
                <Flex style={{marginRight: 44}} align={"center"} gap={10}>
                    <Row>
                        <Col span={4} style={{alignSelf: "center"}}>
                            <Dropdown
                                menu={menu}
                                trigger={['click']}
                                open={open}
                                onOpenChange={handleOpenChange}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Flex align={"center"} gap={10} className={"box-language"}>
                                        <Flex gap={5} align={"center"}>
                                            <Icon component={languageIcon}/>
                                            <div className={'text-language'}>{language}</div>
                                        </Flex>
                                        <DownOutlined style={{color: '#0E1117'}}/>
                                    </Flex>
                                </a>
                            </Dropdown>
                        </Col>
                        <Col span={12}>
                            <div style={{width: "100%"}}>{moment().format('dddd, DD MMMM YYYY')}</div>
                        </Col>
                        <Col span={8}>
                            <Input placeholder="Поиск" prefix={<SearchOutlined/>}/>
                        </Col>
                    </Row>
                </Flex>
            </Flex>
        </Header>
    );
};

export default HeaderComponent;
