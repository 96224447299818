import React, { useState } from 'react';
import {Flex} from "antd";

const Tabs = ({tabs}: { tabs: { label: string, content: React.ReactNode }[]}) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Flex vertical style={{width:'100%'}}>
            <div className="tabs text-card-14">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`tab-button ${index === activeTab ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab(index)}>
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab].content}
            </div>
        </Flex>
    );
};

export default Tabs;
