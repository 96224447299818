import React, {useEffect} from 'react';
import './styleHistory.scss'
import ScrollHistory from "./ScrollHistory";
import {Col, Divider, Row} from "antd";
import {IActivityContainer} from "../../../types/containers";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import moment from "moment";

interface TableHistoryProps {
    dataHistory?: []
}

const TableHistory = ({dataHistory}: TableHistoryProps) => {

    return (
        <div className="text-area">
            <div className="overlap-group">
                <div className="input-container">
                    <ScrollHistory className="scroll-instance" hovered={false}
                                   knobClassName="design-component-instance-node"/>
                </div>
                <div className="text-container">
                    <Row style={{width: '96%'}}>
                        <Col span={8} className="text-wrapper">Дата изменения</Col>
                        <Col span={8} className="div">Изменение</Col>
                        <Col span={8} className="text-wrapper-2">Сотрудник</Col>
                    </Row>
                    <Divider className="line" style={{margin: 0}}/>
                    <div className="frame-2">
                        <div className="frame-3">
                            {dataHistory?.map((item: any,index: number) =>
                                <div key={index}>
                                    <Row>
                                        <Col span={8}
                                             className="text-wrapper-3">{moment(item.date).format('DD.MM.YYYY H:mm')}</Col>
                                        <Col span={8} className="frame-4">
                                            <div className="text-wrapper-5">Изменение статуса</div>
                                            <div className="text-wrapper-6">Было:</div>
                                            <div className="text-wrapper-7">{item.pair[0]}</div>
                                            <div className="text-wrapper-8">Стало:</div>
                                            <div className="text-wrapper-9">{item.pair[1]}</div>
                                        </Col>
                                        <Col span={8} className="text-wrapper-4">{item.user_name}</Col>
                                    </Row>
                                    <Divider style={{margin: 10}}/>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableHistory;
