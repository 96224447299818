import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Flex, Form, Input, notification, Typography } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { createContext, FC, useMemo } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../router/names";
import { RootState, useStoreDispatch } from "../../store";
import { Registration } from "../../store/users";
import s from './RegistrationForm.module.scss';
import { EmailInputIcon, EyeIcon, LockIcon } from '../../assets/image/svg';
import Button from '../ui/Button/Button';

const Context = createContext({ name: 'Default' });

interface IClickLogin {
    clickLogin: () => void
}

const RegistrationForm: FC<IClickLogin> = ({clickLogin}) => {
    const {usersData}: any = useSelector((state: RootState) => state.users.usersData);
    const dispatch = useStoreDispatch()
    const router = useHistory()
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (data: any) => {
        data.role_id = 1
        const result = await dispatch(Registration(data));
        const jRes = JSON.parse(result.payload)

        if (jRes.success === 0){
            openNotification('topRight',jRes.message);
        }else {
            router.push(RouteNames.MAIN)
        }
    }
    const openNotification = (placement: NotificationPlacement,message: string) => {
        api.error({
            message: `Ошибка`,
            description: <Context.Consumer>{({ name }) => message}</Context.Consumer>,
            placement,
        });
    };
    const contextValue = useMemo(() => ({ name: '' }), []);
    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <Flex align={'center'} vertical>
            <div className={s.container}>
                <h1 style={{marginBottom: 30, textAlign: 'center', fontFamily: 'Manrope', fontSize: 32, fontWeight: 700 }}>Регистрация аккаунта</h1>
                <Form onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="name"
                        rules={[{required: true, message: 'Пожалуйста, укажите Ваше имя!'}]}>
                        <Input
                            placeholder="Имя"
                            prefix={<UserOutlined />}
                            style={{marginBottom: 5}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        rules={[{required: true, message: 'Пожалуйста, укажите Вашу фамилию!'}]}>
                        <Input
                            placeholder="Фамилия"
                            prefix={<UserOutlined />}
                            style={{marginBottom: 5}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="first_name">
                        <Input
                            placeholder="Отчество"
                            prefix={<UserOutlined />}
                            style={{marginBottom: 5}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{
                            type: 'email', message: 'Введенный адрес электронной почты неверен!'},
                            {required: true, message: 'Пожалуйста, введите свой адрес электронной почты!'
                        }]}>
                        <Input
                            placeholder="Email"
                            prefix={<EmailInputIcon />}
                            style={{marginBottom: 5}}
                        />
                    </Form.Item>
                    <Form.Item name="password" className={s.smallStep} rules={[{required: true, message: 'Пожалуйста, введите свой пароль!'}]}>
                        <Input.Password
                            prefix={<LockIcon/>}
                            style={{marginBottom: 5}}
                            type={"password"}
                            placeholder="Пароль"
                            iconRender={(visible) => (visible ? <EyeIcon /> : <EyeIcon />)}
                        />
                    </Form.Item>
                    <Form.Item name="repeat_password" className={s.smallStep} rules={[{required: true, message: 'Пожалуйста, повторите пароль!'}]}>
                        <Input.Password
                            prefix={<LockIcon/>}
                            type={"password"}
                            placeholder="Повторите пароль"
                            iconRender={(visible) => (visible ? <EyeIcon /> : <EyeIcon />)}
                        />
                    </Form.Item>
                    <Form.Item name="button" className={s.bigStep} >
                        <Flex align={'center'} vertical>
                            <Button style={{backgroundColor: '#0055FF', width: '100%'}}>
                                Вход
                            </Button>
                            <p style={{color: '#0055FF'}} onClick={clickLogin} className={"ahref"}>или войдите</p>
                        </Flex>
                    </Form.Item>

                    {/* <div className={s.class}>
                        <h1 className={s.class}>Регистрация аккаунта</h1>
                        <div className={s.class}>
                            <div className={s.element}>
                                <Letter />
                                <input className={s.element} type="text" placeholder="Email"/>
                            </div>
                            <div className={s.element}>
                                <input className={s.element} type="text" placeholder="Пароль"/>
                            </div>
                            <div className={s.element}>
                                <input className={s.element} type="text" placeholder="Повторите пароль"/>
                            </div>

                        </div>
                        <div className={s.class}>
                            <button className={s.button}>Регистрация</button>
                            <p className={s.p}>или войдите</p>
                        </div>
                    </div> */}
                    {/* <Divider/>
                    <Form.Item>
                        <Flex vertical align={'center'}>
                            <Button type="primary" htmlType="submit" >
                                Регистрация
                            </Button>
                            <Col span={20}>
                                <p onClick={clickLogin} className={"ahref"}>или войдите</p>
                            </Col>
                        </Flex>
                    </Form.Item> */}
                </Form>
            </div>
            </Flex>
        </Context.Provider>
    );
};

export default RegistrationForm;
