import React from 'react';
import {Col, Flex, Row, Space} from "antd";
import '../../../assets/css/header-screen.scss'
import Icon from "@ant-design/icons";
import {closeIcon, copyLinkIcon, downloadIcon} from "../../../assets/image/svg";
import {Checkbox} from "../Checkbox/Checkbox";


interface HeaderScreenProps {
    onClick?: () => void,
    previewTitle?: any
}

const HeaderScreen = ({onClick, previewTitle}: HeaderScreenProps) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    }

    return (
        <Space className={'header-screen-container'}>
            <Row>
                <Col span={8}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <div className={'ellipsis'}>{previewTitle}</div>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={8}>
                        <Col span={6}><Flex gap={8} justify={'center'} align={"center"}>
                            <Icon component={downloadIcon}/>
                            <div className={'ellipsis'}>Скачать</div>
                        </Flex></Col>
                        <Col span={12}><Flex gap={8} justify={'center'} align={"center"}>
                            <Icon component={copyLinkIcon}/>
                            <div className={'ellipsis'}>Скопировать ссылку</div>
                        </Flex></Col>
                        <Col span={6}><Flex gap={8} justify={'center'} align={"center"} style={{height: 27}}>
                            <Checkbox type={'image-choice'} labelType={'image-label'} isChoice onChange={onChange}
                                      label={'Выбрать'}/>
                        </Flex></Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={8}>
                        <Col span={24} style={{textAlign: "right"}}><Icon component={closeIcon}
                                                                          onClick={onClick}/></Col>
                    </Row>
                </Col>
            </Row>

        </Space>
    );
};

const checkBoxStyle: React.CSSProperties = {
    backgroundColor: '#1F1F1F !important'
};

export default HeaderScreen;
