import {IColumnType} from "../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
    {
        title: 'ID',
        dataIndex: 'id_deal',
        link: true,
        checkbox: true,
        menu: true,
        width: '40%',
        type: "string",
        key: '1',
    },
    {
        title: 'Название',
        dataIndex: 'name_deal',
        width: '20%',
        type: "string",
        key: '2',
    },
    {
        title: 'Клиент',
        dataIndex: 'client',
        width: '20%',
        type: "string",
        key: '3',
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
        width: '30%',
        key: '4',
    },
    {
        title: 'Ставка',
        dataIndex: 'bet',
        width: '20%',
        key: '5',
    },
    {
        title: 'Ответственный',
        dataIndex: 'responsible',
        width: '20%',
        key: '6',
    },
    {
        title: 'Кол-во КТК',
        dataIndex: 'col_ktk',
        width: '20%',
        key: '7',
    },
    {
        title: 'Маршрут',
        dataIndex: 'route',
        width: '20%',
        key: '8',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        width: '20%',
        key: '9',
    },
    {
        title: 'Комментарии',
        dataIndex: 'comment',
        width: '20%',
        key: '10',
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '11',
    },
];
