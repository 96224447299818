import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { Drawer } from 'antd';

import { CloseOutlined, HolderOutlined } from '@ant-design/icons';

import TooltipButton from '../ui/TooltipButton/TooltipButton';
import style from './CustomDrawer.module.scss';


interface IDrawer {
  open: boolean;
  isHeight?: boolean;
  title?: any;
  onSave?: any;
  onClose: () => void;
  children: React.ReactElement | React.ReactElement[];
}

const CustomDrawer: React.FC<IDrawer> = ({open, onClose, children, isHeight}) => {
  const addPropsToReactElement = (element: React.ReactElement, props: any) => {
    if (React.isValidElement(element)) {
      return React.cloneElement(element, props)
    }
    return element
  };

  const addPropsToChildren = (children: React.ReactElement | React.ReactElement[], props: any) => {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props)
    }
    return children.map(childElement => addPropsToReactElement(childElement, props))
  }
  const refContainer = useRef<HTMLDivElement>(null);
  const [isResize, setIsResize] = useState(false);
  const [height, setHeight] = useState(() => 'auto');
  const [width, setWidth] = useState(() => 'auto');
  const [widthContent, setWidthContent] = useState(() => 0);
  const handleMouseMove = (evt: React.MouseEvent) => {
    if (refContainer?.current) {
      const widthWindow = window.innerWidth;
      const width = widthWindow - evt.clientX;
      const minWidth = 550;
      if (width > minWidth) {
        setWidth(`${width}px `);
        setWidthContent(width);
      }
    }
  };
  const closeResize = (evt: React.MouseEvent) => {
    setIsResize(false);
  };
  const openResize = (evt: React.MouseEvent) => {
    setIsResize(true);
  };

  useEffect(() => {
    if (refContainer?.current && open && isHeight) {
      const parent = refContainer.current.closest('.ant-drawer-body');
      if (parent) {
        setHeight(parent.getBoundingClientRect().height + 'px');
        setWidth(refContainer.current.getBoundingClientRect().width + 'px');
      }
    }
  }, [open]);
  return (
    <Drawer
      className={style.drawer}
      width={'auto'}
      placement="right"
      closable={false}
      forceRender={false}
      open={open}>
      {isResize && (
        <div
          onMouseMove={handleMouseMove}
          onMouseUp={closeResize}
          className={style.drawer__board}
        />
      )}
      <div ref={refContainer} className={style.drawer__container} style={{height, maxWidth: width, width}}>
        <TooltipButton
          propsTooltip={{ title: 'Расширить окно' }}
          propsButton={{
            className: style['drawer__resize-button'],
            shape: 'default',
            icon: <HolderOutlined style={{ cursor: 'e-resize' }} />,
            onMouseUp: closeResize,
            onMouseDown: openResize,
          }}
        />
        <CloseOutlined className={style.drawer__close} onClick={onClose} />
         {addPropsToChildren(children, {width: widthContent})}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;