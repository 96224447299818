import React, {useCallback, useMemo, useState} from 'react';
import Icon, {PlusCircleOutlined, SettingOutlined} from "@ant-design/icons";
import {DatePicker, Flex, Menu, MenuProps, Popover, Select} from "antd";
import {Checkbox} from '../Checkbox/Checkbox';
import {
    AddHiddenColumn,
    TableHeaderCell,
    TableHeaderCellColor,
    TableHeaderRow,
} from "./styled";
import {IColumnType} from "./Table";
import {burgerBlueIcon, sortIconUp, sortIconDown, RepairIcon} from "../../../assets/image/svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {IContainers} from "../../../types/containers";

interface Props<T> {
    columns: IColumnType<T>[],
    content?: React.JSX.Element,
    sortData?: (column: IColumnType<T>) => void,
    hiddenColumn?: number[],
    order?: ((sort: string) => void) | undefined,
    filtersFetch?: ((text: (string | number), column: IColumnType<any>) => void) | undefined,
    filters?: ((column: any) => void) | undefined,
    onSelectAll: (checked: boolean) => void;
    allSelected: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

export function TableHeader<T>({
                                   columns,
                                   content,
                                   sortData,
                                   hiddenColumn = [],
                                   order,
                                   filtersFetch,
                                   filters,
                                   onSelectAll,
                                   allSelected
                               }: Props<T>): JSX.Element {
    const findCheck = columns.filter((el: IColumnType<T>) => el.checkbox !== undefined);
    const [openPopovers, setOpenPopovers] = useState<{ [key: number]: boolean }>({});
    const dataFilters: IContainers[] = useSelector((state: RootState) => state.containers.dataFilters);

    const [value, setValue] = useState<string | null>();

    const items: MenuItem[] = [
        {
            label: 'Сначала новые',
            key: 'DESC',
            icon: <Icon component={sortIconUp}/>,
        },
        {
            label: 'Сначала старые',
            key: 'ASC',
            icon: <Icon component={sortIconDown}/>,
        },
    ]

    const optionsFilter = useMemo(() => {
        const uniqueIds = new Set();
        return dataFilters.filter(item => {
            if (!uniqueIds.has(item.type_container)) {
                uniqueIds.add(item.type_container);
                return true;
            }
            return false;
        });
    }, [dataFilters]);

    const searchFilter = useCallback((row: string, column: any) => {
        if (row.length > 1 && filtersFetch) {
            filtersFetch(row, column);
        }
    }, [filtersFetch]);

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleSelect = (option: any) => {
        console.log(option);
        if (filters) {
            filters(option);
        }
        // setValue(option.label);
    };

    const handleOrder: MenuProps['onClick'] = (row) => {
        if (order) {
            order(row.key)
        }
        // setValue(option.label);
    };

    const filter = useCallback((column: IColumnType<T>) => (
        <Flex style={{width: "100%"}} vertical justify={"center"} gap={10}>
            {column.type === 'string' ? (
                <Select
                    allowClear
                    mode="multiple"
                    value={value}
                    onSearch={(value) => searchFilter(value, column)}
                    style={{width: 200}}
                    placeholder={column.title}
                    notFoundContent={null}
                    defaultActiveFirstOption={false}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    options={(optionsFilter || []).map((d) => ({
                        value: d.id,
                        label: d[column.dataIndex],
                    }))}
                    filterOption={(inputValue, option) =>
                        option?.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                    }
                />
            ) : (
                <Flex gap={7}>
                    <DatePicker format={'DD.MM.YYYY'}/>
                    <DatePicker format={'DD.MM.YYYY'}/>
                </Flex>
            )}
            <Flex>
                <Menu mode="inline"
                      style={{borderInlineEnd: 0}}
                      defaultSelectedKeys={['1']}
                      onClick={handleOrder}
                      items={items}/>
            </Flex>
        </Flex>
    ), [dataFilters, items, searchFilter]);

    const handleOpenChange = useCallback((index: number, visible: boolean) => {
        setOpenPopovers(prevState => ({...prevState, [index]: visible}));
    }, []);

    const handleSelectAll = (e: any) => {
        onSelectAll(e);
    };

    return (
        <tr>
            {columns.map((column, columnIndex) => (
                !hiddenColumn.includes(columnIndex) && (
                    column.dataIndex !== 'hidden' ? (
                        <TableHeaderCell key={`table-head-cell-${columnIndex}`}
                                         style={{width: column.width}}>
                            <Flex gap={10} align={"center"}>
                                {findCheck.length > 0 && column.checkbox !== undefined &&
                                    <Checkbox
                                        isChoice={allSelected}
                                        onChange={handleSelectAll}
                                    />}
                                <Flex gap={3}>
                                        <TableHeaderCellColor>
                                            <Flex gap={4}>
                                                {column.dataIndex === 'repair' ?
                                                    <Icon component={RepairIcon}/>
                                                    :
                                                    column.title
                                                }
                                                <Popover placement="bottom"
                                                         content={filter(column)}
                                                         open={openPopovers[columnIndex]}
                                                         onOpenChange={(visible) => handleOpenChange(columnIndex, visible)}
                                                         trigger="click">
                                                    <Icon component={burgerBlueIcon}/>
                                                </Popover>
                                            </Flex>
                                        </TableHeaderCellColor>

                                </Flex>
                            </Flex>
                        </TableHeaderCell>
                    ) : (
                        <TableHeaderCell key={`table-head-cell-${columnIndex}`} style={{width: column.width}}>
                            <AddHiddenColumn>
                                <Popover placement="bottomRight" content={content}>
                                    <PlusCircleOutlined/>
                                </Popover>
                            </AddHiddenColumn>
                        </TableHeaderCell>
                    )
                )
            ))}
        </tr>
    );
}
