import React, {useEffect, useMemo, useState} from 'react';
import Icon, {CloseOutlined} from "@ant-design/icons";
import {
    Flex,
    Form,
    Input,
    Select,
    Row,
    Col,
    Typography,
    DatePicker,
    AutoComplete,
    message, Button, DatePickerProps
} from 'antd';
import dayjs from 'dayjs';
import {deleteBlack} from "../../assets/image/svg";
import moment from "moment";
import {ICurrency, ITypeContainers, ITypeEmail, ITypeMessenger, ITypePhone, ITypeQuality} from "../../types";
import {useStoreDispatch} from "../../store";
import {findCompany} from "../../store/contractors";
import {getCurrency} from "../../store/store";
import {SelectSearch} from "../../components/ui/SelectSearch/SelectSearch";
import {Utils} from "../../utils";
import Uploader from "../../components/ui/Uploader/Uploader";
import NumericInput from "../../components/ui/NumericInput/NumericInput";
import FormContractors from "../contractors/formContractors";
import DrawerComponent from "../../components/DrawerComponent";
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import {saveContractors} from "../../store/contractors";
import { messagesModal } from '../../constants';

const { title: warningTitle, subtitle } = messagesModal.warning;

const {Text, Link} = Typography;

interface Props {
    title?: string,
    onClose?: any,
    dataTypeQuality?: Array<ITypeQuality>,
    dataTypeContainers?: Array<ITypeContainers>,
    dataCurrency?: Array<ICurrency>,
    cityData?: any,
    dataRowContainer?: any,
    form?: any,
    typeForm?: string,
    onFinishForm?: (data: any) => void,
    dataTypePhone?: Array<ITypePhone>,
    dataTypeEmail?: Array<ITypeEmail>,
    dataTypeMessenger?: Array<ITypeMessenger>
}

const createSvgIcon = (svgString: string) => () => (
    <div dangerouslySetInnerHTML={{__html: svgString}}/>
);

const FormContainer: React.FC<Props> = ({
                                            title,
                                            onClose,
                                            dataTypeQuality,
                                            dataTypeContainers,
                                            dataCurrency,
                                            cityData,
                                            dataRowContainer,
                                            form,
                                            typeForm,
                                            onFinishForm,
                                            dataTypePhone,
                                            dataTypeEmail,
                                            dataTypeMessenger
                                        }) => {
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const dispatch = useStoreDispatch();
    const [dateMemory, setMemory] = useState<any>(null);
    const [dateCvv, setDateCvv] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
    const [openCvv, setOpenCvv] = useState(false);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});
    const [owner, setOwner] = useState<number | null>(null);
    const [terminal, setTerminal] = useState<any>([]);
    const [dataId, setDataId] = useState<any[]>([]);
    const [terminalName, setTerminalName] = useState<string | null>(null);
    const [valuePrice, setValuePrice] = useState<string>('');
    const [numContainer, setNumContainer] = useState<string>('');
    const [disabledSave, setDisabledSave] = useState<boolean>(false);
    const [dataRequisites, setDataRequisites] = useState<any>([]);
    const [dataBankRequisites, setDataBankRequisites] = useState([]);

    useEffect(() => {
        dispatch(getCurrency()).then(response => {
            response.payload.forEach((currency: ICurrency) => {
                const SvgIcon = createSvgIcon(currency.svg);
                setIcons(prev => ({...prev, [currency.id]: <SvgIcon/>}));
            });
        })
        if (dataRowContainer) {
            const dateCvv = dataRowContainer?.date_cvv !== null ?
                moment(dataRowContainer?.date_cvv).format('DD.MM.YYYY') : 'Нет даты';
            const dateMemory = dataRowContainer?.date_memory !== null ?
                moment(dataRowContainer?.date_memory).format('DD.MM.YYYY') : 'Нет даты';

            setDateCvv(dateCvv)
            setMemory(dateMemory);
            setTerminalName(dataRowContainer?.child_terminal?.name)
            form.setFieldsValue({
                num_container: dataRowContainer?.num_container,
                type_container: dataRowContainer?.type_container,
                quality: dataRowContainer?.quality,
                owner: dataRowContainer?.contractors?.name_company,
                comment: dataRowContainer?.comment,
                currency: dataRowContainer?.currency,
                price: dataRowContainer?.price,
                arrival_date: dayjs(dataRowContainer?.arrival_date) || null,
                departure_date: dayjs(dataRowContainer.departure_date),
            })
        }
        setValuePrice(dataRowContainer?.price)
    }, [dataRowContainer]);

    const handleDateChange: DatePickerProps['onChange'] = (_, dateStr) => {
        setMemory(dateStr);
    };

    const handleDateChangeCvv: DatePickerProps['onChange'] = (_, dateStr) => {
        setDateCvv(dateStr);
    };

    const onSelectCompany = (id: string, record: any) => {
        setOwner(Number(record.id))
    };

    const getFindCompany = (searchText: any) => {
        if (searchText.length > 2) {
            dispatch(findCompany(searchText.toLowerCase())).then(response => {
                setOptions(response.payload)
            })
        }
    }

    const onFinish = (data: any) => {
        Object.assign(data, {type_form: typeForm})
        data.owner_id = owner !== null ? owner : dataRowContainer?.owner_id
        data.terminal_id = terminal.length > 0 ? terminal[0].value : dataRowContainer?.child_terminal?.city_terminals?.id
        data.terminal = terminalName
        data.child_terminal_id = terminal.length > 0 ? terminal[0].child : dataRowContainer?.child_terminal?.id
        data.date_entry = moment().format('YYYY-MM-DD');
        data.ids_photo = dataId.length > 0 ? dataId : dataRowContainer?.ids_photo !== null ? dataRowContainer?.ids_photo : null
        data.responsible_id = Utils.getUserInfo().id
        data.status_id = 1
        data.price = valuePrice === "" ? null : valuePrice
        data.user_id = Utils.getUserInfo().id
        data.id = dataRowContainer?.id
        data.photo_bool = dataId.length > 0 ? 'есть' : 'нет'
        data.terminal = dataRowContainer?.child_terminal?.name !== null ?
            dataRowContainer?.child_terminal?.name : dataRowContainer?.terminal?.name
        data.currency_price = (data.currency === undefined && valuePrice === undefined) || (valuePrice === "") ?
            null :
            `${data.currency || ''} ${valuePrice || ''}`.trim();
        if (dataId.length === 0) {
            data.ids_photo = null;
        }
        console.log(data)
        if (onFinishForm) {
            onFinishForm(data)
        }
    };

    const onFinishCompany = (data: any) => {
        data.bank = dataBankRequisites.length > 0 ? dataBankRequisites : null;
        data.short_address = dataRequisites.length > 0 ? dataRequisites[10]?.meaning : null;
        data.contractors_id = data.id

        dispatch(saveContractors(data));
        setOpenAddCompany(false);
    }

    const onChangeSelect = (event: any, option: any) => {
        setTerminalName(option.children.props.children)
        if (typeof option.key === 'string') {
            setTerminal([JSON.parse(option.value)])
        } else {
            setTerminal({value: option.value, child: 0})
        }
    }

    const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event)
    }

    const handleCurrency = (id: number, record: any) => {
        // console.log(id)
    }

    const handleCloseCvv = () => {
        setOpenCvv(false);
    }

    const handleCloseMemory = () => {
        setOpen(false);
    }

    const closeForm = () => {
        onClose()
    }

    const onChangeNumContainer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const convertToEng = Utils.convertToEnglishUppercase(event.target.value);
        setNumContainer(convertToEng);
        form.setFieldsValue({num_container: convertToEng});
    }

    const onBlurNumContainer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const isValid = Utils.isValidContainerNumber(event.target.value);
        if (!isValid) {
            message.warning('Неверный формат номера контейнера!');
            setDisabledSave(true);
        } else {
            setDisabledSave(false)
        }
    }

    const handleAddOwner = () => {
        setOpenAddCompany(true);
    }

    const handleCompany = () => {
        setOptions([]);
    }

    const onCloseDrawerCompany = () =>{
        setOpenAddCompany(false);
    }

    return (
        <>
            <CloseOutlined className={'close'} onClick={closeForm}/>
            <Form layout="vertical" autoComplete="off" onFinish={onFinish} form={form}
                  name="getValueProps">
                <Flex justify={'space-between'} className={'drawer-header'}>
                    <Flex vertical style={{width: '100%'}} gap={16}>
                        <Flex vertical style={{width: '100%'}}>
                            <div className={'drawer-title'}>{title}</div>
                        </Flex>
                        <Flex gap={24} vertical>
                            <Flex className={'container-photo'}>
                                <Uploader ids_photo={dataRowContainer?.ids_photo} setDataId={setDataId}/>
                            </Flex>
                            <Row gutter={[12, 8]}>
                                <Col span={8}>
                                    <Form.Item name={'num_container'}
                                               rules={[{required: true, message: 'Обязательное поле!'}]}>
                                        <Input placeholder={'Номер контейнера'} style={{width: '100%'}} allowClear
                                               maxLength={11}
                                               onBlur={onBlurNumContainer} value={numContainer}
                                               onChange={onChangeNumContainer}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'type_container'}
                                               rules={[{required: true, message: 'Обязательное поле!'}]}>
                                        <Select
                                            allowClear
                                            options={dataTypeContainers}
                                            placeholder={'Тип контейнера'}
                                            style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'quality'}
                                               rules={[{required: true, message: 'Обязательное поле!'}]}>
                                        <Select
                                            allowClear
                                            options={dataTypeQuality}
                                            placeholder={'Состояние'}
                                            style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item name={'owner'}
                                               rules={[{required: true, message: 'Обязательное поле!'}]}>
                                        <AutoComplete
                                            allowClear
                                            options={options}
                                            style={{width: '100%'}}
                                            onChange={handleCompany}
                                            onSelect={onSelectCompany}
                                            onSearch={(text: string) => getFindCompany(text)}
                                            placeholder="Собственник"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button type={'text'} style={{
                                        border: '2px solid #05F',
                                        width: '100%'
                                    }} onClick={handleAddOwner}>Добавить</Button></Col>
                            </Row>
                        </Flex>
                        <Form.Item name="comment">
                            <Input.TextArea placeholder={'Комментарий'} autoSize={{minRows: 4, maxRows: 20}}/>
                        </Form.Item>
                        <Row>
                            <Col span={24}>
                                <Form.Item name={'terminal'}>
                                    <SelectSearch
                                        defaults={terminalName}
                                        dataSource={cityData}
                                        onChangeSelect={onChangeSelect}
                                        dropdownRender={onNameChange}
                                        placeholder={'Терминал'}
                                        type={'page'}
                                        style={{width: '100%'}}
                                        hiddenBtn={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name={'currency'}>
                                    <Select style={{width: '100%'}} onChange={handleCurrency}
                                            placeholder={'Валюта'} allowClear>
                                        {dataCurrency?.map((item: ICurrency) => (
                                            <Select.Option key={item.id} value={item.symbol}>
                                                <Flex align={"center"}
                                                      gap={5}>{icons[item.id]}{item.symbol} {item.value}</Flex>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'price'}>
                                    <NumericInput
                                        style={{width: '100%'}}
                                        placeholder={'Входящая ставка'}
                                        setValue={setValuePrice}
                                        value={valuePrice}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name={'arrival_date'}
                                           getValueProps={(value) => ({ value: value && dayjs(Number(value)) })}>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD.MM.YYYY'
                                            placeholder={'Дата прибытия'}
                                        />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'departure_date'}
                                           getValueProps={(value) => ({ value: value && dayjs(Number(value)) })}>
                                        <DatePicker
                                            style={{width:'100%'}}
                                            format='DD.MM.YYYY'
                                            placeholder={'Дата убытия'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={'cvv-box'}>
                            <Col span={12} className={'cvv-container'}>
                                <Flex vertical={false} gap={6}>
                                    <Text className={'cvv-text'}>СВВ</Text>
                                    <Icon component={deleteBlack} width={16} height={16}/>
                                </Flex>
                                <Flex style={{width: '100%'}} gap={8}>
                                    <div style={{width: '25%'}}>
                                        <Link onClick={() => setOpenCvv(!openCvv)}>
                                            <div className={'cvv-text--1'}>
                                                    {dateCvv}
                                            </div>
                                        </Link>
                                        <Form.Item name={'date_cvv'} className={'cvv-text--1'}>
                                            <DatePicker
                                                open={openCvv}
                                                format='DD.MM.YYYY'
                                                onOpenChange={handleCloseCvv}
                                                style={{visibility: 'hidden'}}
                                                onChange={handleDateChangeCvv}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{width: '75%'}}>
                                        <Link onClick={() => setOpen(!open)}>
                                            <div className={'cvv-text--2'}>
                                                    Напомнить {dateMemory}
                                            </div>
                                        </Link>
                                        <Form.Item name={'date_memory'} className={'cvv-text--2'}>
                                            <DatePicker
                                                open={open}
                                                format='DD.MM.YYYY'
                                                onOpenChange={handleCloseMemory}
                                                style={{visibility: 'hidden'}}
                                                onChange={handleDateChange}
                                            />
                                        </Form.Item>
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                        <Row>
                            <Text className={'text-import'}>Импорт из Excel</Text>
                        </Row>
                        <Flex gap={8} align={'flex-end'}>
                            <Button type={'primary'} htmlType={"submit"} style={{width: '70%'}} disabled={disabledSave}>
                                {typeForm === 'create' ? 'Добавить' : 'Сохранить изменения'}
                            </Button>
                            <Button type={'text'} style={{width: '30%', color: '#E14453'}}
                                    onClick={() => setConfirmation(true)}>Отменить</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>

            <DrawerComponent
                open={openAddCompany}
                form={
                    <FormContractors
                        form={form}
                        setDataRequisites={setDataRequisites}
                        setDataBankRequisites={setDataBankRequisites}
                        dataTypePhone={dataTypePhone}
                        dataTypeEmail={dataTypeEmail}
                        dataTypeMessenger={dataTypeMessenger}
                        onClose={onCloseDrawerCompany}
                        title={'Новая компания'}
                        onFinish={onFinishCompany}
                    />
                }
            />
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={openAddCompany ? onCloseDrawerCompany: onClose} />
        </>
    );
};

export default FormContainer;
