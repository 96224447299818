// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDrawer_drawer__6w6Xc {
  width: auto !important;
}
.CustomDrawer_drawer__6w6Xc .drawer-header {
  padding: 22px 31px 0 31px;
}
.CustomDrawer_drawer__6w6Xc .ant-drawer-body {
  padding: 0 !important;
}
.CustomDrawer_drawer__6w6Xc .ant-drawer-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.CustomDrawer_drawer__6w6Xc .ant-drawer-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.CustomDrawer_drawer__6w6Xc .ant-drawer-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.CustomDrawer_drawer__6w6Xc .drawer-title {
  font-size: 20px;
  font-weight: 500;
}
.CustomDrawer_drawer__6w6Xc .drawer-title.terminal {
  color: #05F;
  font-weight: 400;
}
.CustomDrawer_drawer__container__-76y8 {
  min-width: calc(45vw - 250px);
}
.CustomDrawer_drawer__board__TbTdm {
  position: fixed;
  left: -40px;
  top: 0;
  bottom: 0;
  width: 100vw;
  cursor: pointer;
  z-index: 1;
  background: transparent;
}
.CustomDrawer_drawer__resize-button__AyoAI {
  position: absolute;
  left: -15px;
  top: 50%;
  cursor: e-resize;
  z-index: 2;
  min-height: 50px;
  max-width: 15px;
  translate: 0 -50%;
}
.CustomDrawer_drawer__close__6ppSw {
  left: -44px;
  position: absolute;
  top: 15px;
  width: 36px;
  height: 36px;
  background: #FFF;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomDrawer/CustomDrawer.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACI;EACE,yBAAA;AACN;AACI;EACE,qBAAA;AACN;AAAM;EACE,UAAA;EACA,WAAA;AAER;AACM;EACE,mBAAA;AACR;AAEM;EACE,gBAAA;EACA,mBAAA;AAAR;AAGI;EACE,eAAA;EACA,gBAAA;AADN;AAEM;EACE,WAAA;EACA,gBAAA;AAAR;AAKE;EACG,6BAAA;AAHL;AAKE;EACE,eAAA;EACA,WAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,uBAAA;AAHJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAJJ","sourcesContent":[".drawer {\n  width: auto !important;\n  :global {\n    .drawer-header{\n      padding: 22px 31px 0 31px;\n    }\n    .ant-drawer-body{\n      padding: 0 !important;\n      &::-webkit-scrollbar {\n        width: 6px;\n        height: 6px;\n      }\n\n      &::-webkit-scrollbar-track {\n        background: #f1f1f1;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background: #888;\n        border-radius: 10px;\n      }\n    }\n    .drawer-title{\n      font-size: 20px;\n      font-weight: 500;\n      &.terminal{\n        color: #05F;\n        font-weight: 400;\n      }\n    }\n  }\n\n  &__container {\n     min-width: calc(45vw - 250px);\n  }\n  &__board {\n    position: fixed;\n    left: -40px;\n    top: 0;\n    bottom: 0;\n    width: 100vw;\n    cursor: pointer;\n    z-index: 1;\n    background: transparent;\n  }\n  &__resize-button {\n    position: absolute;\n    left: -15px;\n    top: 50%;\n    cursor: e-resize;\n    z-index: 2;\n    min-height: 50px;\n    max-width: 15px;\n    translate: 0 -50%;\n  }\n\n  &__close{\n    left: -44px;\n    position: absolute;\n    top: 15px;\n    width: 36px;\n    height: 36px;\n    background: #FFF;\n    justify-content: center;\n    align-items: center;\n    border-radius: 6px;\n    cursor: pointer;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `CustomDrawer_drawer__6w6Xc`,
	"drawer__container": `CustomDrawer_drawer__container__-76y8`,
	"drawer__board": `CustomDrawer_drawer__board__TbTdm`,
	"drawer__resize-button": `CustomDrawer_drawer__resize-button__AyoAI`,
	"drawer__close": `CustomDrawer_drawer__close__6ppSw`
};
export default ___CSS_LOADER_EXPORT___;
