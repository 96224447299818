import { ALL_TYPE_DEAL } from "../constants";
import socket from "../socket";
import { IComments, IContainers, IDeal } from "../store/deal";

export class Rest_deal{
    static getTypeDeal(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(ALL_TYPE_DEAL, null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static generateIdDeal(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('generate-id-deal', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getContainersDeal(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-containers-deal', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findOneContainersDeal(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-one-container-deal', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static editContainerDeal(param: {container: IContainers, index: number}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('edit-container-deal', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getFiltersDeal(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-deal', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getContainerDealStatuses(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-dealContainer-statusAll', null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getDeals(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-deals', param, (response: any, req: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setDeals(payload: {deal: IDeal, containers: IContainers[], comments: IComments[]}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('set-deal', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findOneDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-one-deal', id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findAllCommentsDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-all-comments-deal', id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static deleteDeal(payload: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('delete-deal', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static deleteContainerDeal(payload: {id: number, id_deal: number}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('delete-containers-deal', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}
