import {IColumnType} from "./Table";
import {TableRowCell} from "./TableRowCell";
import {TableRowItem} from "./styled";
import React from "react";

interface Props<T> {
    data: T[],
    columns: IColumnType<T>[],
    hiddenColumn: any,
    onRow?: (record: any, rowIndex: number) => void,
    editRow?: (item: any) => void,
    deleteRow?: (row: any) => void,
    onClickLink?: ((data: any) => void) | undefined,
    setAllSelected?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    selectedRows?: any,
    handleRowSelect: (id: number, checked: boolean) => void,
    handleCopyAll?: () => void,
    handleDeleteCopyAll?: () => void | any[]
}


export function TableRow<T>({
                                data,
                                columns,
                                hiddenColumn,
                                onRow,
                                editRow,
                                deleteRow,
                                onClickLink,
                                selectedRows,
                                handleRowSelect,
                                handleCopyAll,
                                handleDeleteCopyAll
                            }: Props<T>): JSX.Element {

    return (
        <>
            {data?.length ? data?.map((item: any, itemIndex) => (
                <TableRowItem key={`table-body-${itemIndex}`} onDoubleClick={(e) => {
                    if (onRow) {
                        e.stopPropagation();
                        onRow(item, itemIndex);
                        }
                      }}>
                    {columns.map((column, columnIndex) => (
                        !hiddenColumn.includes(columnIndex) && (
                            <TableRowCell
                                key={`table-row-cell-${columnIndex}`}
                                item={item}
                                column={column}
                                onClickLink={onClickLink}
                                itemIndex={columnIndex}
                                hiddenColumn={hiddenColumn}
                                onRow={onRow}
                                editRow={editRow}
                                deleteRow={deleteRow}
                                handleCopyAll={handleCopyAll}
                                handleDeleteCopyAll={handleDeleteCopyAll}
                                isChecked={selectedRows.has(item.id)}
                                onCheck={(checked) => handleRowSelect(item.id, checked)}
                            />
                        )
                    ))}
                </TableRowItem>
            )) : null}
        </>
    );
}
