import React, {useEffect, useState, useCallback, useContext} from 'react';
import {AutoComplete, Avatar, Button, Col, Flex, Form, Input, Modal, Row, Select, Space, Typography} from 'antd';
import Icon, {UserOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {RootState, useStoreDispatch} from '../../../store';
import {Utils} from "../../../utils";
import {SelectSearch} from "../../../components/ui/SelectSearch/SelectSearch";
import {findExecutor} from "../../../store/contact";
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import {dragdrop} from "../../../assets/image/svg";
import {messagesModal} from '../../../constants';
import DealContext from '../context/DealContext';

const {title: warningTitle, subtitle} = messagesModal.warning;

interface TabDealProps {
    getFindCompany: (searchText: string) => void,
    onClose: () => void,
}

const TabDeal = ({getFindCompany, onClose,}: TabDealProps) => {
    const {type, setType} = useContext(DealContext);
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const dispatch = useStoreDispatch();
    const dataCurrency = useSelector((state: RootState) => state.stores.dataCurrency);
    const dataPercent = useSelector((state: RootState) => state.stores.dataPercent);
    const dataCompany = useSelector((state: RootState) => state.companies.dataCompany);
    const dataMyCompany = useSelector((state: RootState) => state.companies.dataMyCompany);
    const idDeal = useSelector((state: RootState) => state.deal.idDeal);
    const [company, setCompany] = useState<number | null>(null);
    const [companyMy, setCompanyMy] = useState<number | null>(null);
    const [numScore, setNumScore] = useState<string>('');
    const [arResponsible, setArResponsible] = useState<any[]>([{
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id
    }]);
    const [isModalOpenResponsible, setIsModalOpenResponsible] = useState<boolean>(false);
    const [dataExecutor, setDataExecutor] = useState<any[]>([]);
    const [responsibleChange, setResponsibleChange] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);

    const handleSearchCompany = useCallback((text: string) => {
        getFindCompany(text);
    }, [getFindCompany]);

    const handleSelectCompany = useCallback((id: string, record: any) => {
        setCompany(Number(record.id));
    }, []);

    const handleSelectMyCompany = useCallback((id: string, record: any) => {
        setCompanyMy(Number(record.id));
        setNumScore(record.score);
    }, []);

    useEffect(() => {
        const items = [
            {
                id: '1',
                label: 'Компания',
                form: 'company',
                placeholder: 'Выберите компанию',
                data: dataCompany,
                onSelect: handleSelectCompany,
                onSearch: handleSearchCompany
            },
            {
                id: '2',
                label: 'От кого',
                form: 'my_company',
                placeholder: 'выберите юр. лицо',
                data: dataMyCompany,
                onSelect: handleSelectMyCompany
            }
        ];
        setItems(type === 'Закуп' ? items.reverse() : items);
    }, [dataCompany, dataMyCompany, handleSearchCompany,
        handleSelectCompany, handleSelectMyCompany, type]);


    useEffect(() => {
        form.setFieldsValue({
            currency: dataCurrency[0]?.value,
            nds: dataPercent[0]?.value,
            account_num: numScore,
            id_deal: idDeal,
        });
    }, [dataCurrency, form, numScore, dataPercent,idDeal]);

    const onFinish = (values: any) => {
        values.company = company;
        values.my_company = companyMy;
        values.responsible = arResponsible.map(item => item.id);
        console.log(values);
    };

    const showModalResponsible = () => {
        setIsModalOpenResponsible(true);
    }

    const handleCancelResponsible = () => {
        setIsModalOpenResponsible(false);
    }

    const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 2) {
            const result: any = await dispatch(findExecutor(event.target.value));
            if (result.payload.length > 0) {
                setDataExecutor(result.payload);
            }
        }
    };

    const handleAddResponsible = (e: any, option: any) => {
        setResponsibleChange([]);
        setResponsibleChange((prevData: any[]) => [
            ...prevData,
            {
                avatar: option.avatar,
                name: option.children,
                id: option.value
            }
        ]);
    };

    const addFormResponsible = () => {
        setArResponsible((prevData) => [...prevData, ...responsibleChange]);
        setIsModalOpenResponsible(false);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems: any = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        const [item] = reorderedItems;
        setType(item?.id === "2" ? "Закуп" : null);


        setItems(reorderedItems);
    };

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return (
        <Form autoComplete="off" onFinish={onFinish} layout="vertical" form={form} initialValues={{
            currency: dataCurrency[0]?.value,
            nds: dataPercent[0]?.value,
            type_deal: 'Закуп',
            comment: null,
            name_deal: null,
            id_deal: null,
            bet: null,
        }}>
            <Flex vertical>
                <Flex vertical gap={8}>
                    <Flex vertical>
                        <Row gutter={[16, 8]}>
                            <Col span={16}>
                                <Form.Item name={'name_deal'}>
                                    <Input placeholder={'Название сделки'} allowClear onChange={onFinish}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'id_deal'}>
                                    <Input placeholder={'ID П0007'} allowClear/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                        }}>
                                                        <Flex gap={11} align={"center"}>
                                                            <div className={'drag-drop-icon'}>
                                                                <Icon component={dragdrop}/>
                                                            </div>
                                                            <Form.Item label={item.label} name={item.form}
                                                                       style={{width: '100%'}}>
                                                                <AutoComplete
                                                                    popupClassName="certain-category-search-dropdown"
                                                                    allowClear
                                                                    options={item.data}
                                                                    style={{width: '100%'}}
                                                                    onSearch={item.onSearch}
                                                                    onSelect={item.onSelect}
                                                                    placeholder={item.placeholder}
                                                                />
                                                            </Form.Item>
                                                        </Flex>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Row gutter={[16, 8]}>
                            <Col span={16}>
                                <Form.Item name={'account_num'} label={'Номер счета'}>
                                    <Input placeholder="Введите номер счета" allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'bet'} label={''} style={{marginTop: 22}}>
                                    <Input placeholder={'Ставка'} allowClear/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Flex>
                    <Row gutter={[16, 2]} align="middle">
                        <Col span={16}>
                            <Space direction={"vertical"}>
                                <Typography.Link className={'text-card-14'}>Создать счет</Typography.Link>
                                <Typography.Link className={'text-card-14'}>Отправить счет</Typography.Link>
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'Валюта'} name={'currency'}>
                                <Select options={dataCurrency}/>
                            </Form.Item>
                            <Form.Item label={'НДС'} name={'nds'}>
                                <Select options={dataPercent}/>
                            </Form.Item>
                            {/* <Form.Item label={'Тип сделки'} name={'type_deal'}>
                                <Select options={[
                                    {value: 'Доставка КТК', label: 'Доставка КТК'},
                                    {value: 'Доставка груза', label: 'Доставка груза'},
                                    {value: 'Закуп', label: 'Закуп'},
                                    {value: 'Передислокация', label: 'Передислокация'},
                                    {value: 'Внутрянка', label: 'Внутрянка'},
                                    {value: 'Продление', label: 'Продление'},
                                    {value: 'Эвакуация', label: 'Эвакуация'},
                                    {value: 'Забрал клиент', label: 'Забрал клиент'},
                                ]}/>
                            </Form.Item> */}
                        </Col>
                    </Row>
                </Flex>
                <Typography.Title level={4}>Комментарий</Typography.Title>
                <Form.Item name="comment">
                    <Input.TextArea placeholder={'Введите комментарий'} autoSize={{minRows: 3, maxRows: 20}}/>
                </Form.Item>
                <Flex gap={6} vertical>
                    <Typography.Title level={4} style={{marginBottom: 0}}>Дополнительно</Typography.Title>
                    <Typography.Text style={{marginTop: 0}}>Ответственный</Typography.Text>
                    <Flex justify={'space-between'} align={'center'} style={{paddingRight: 7}}>
                        <Flex vertical gap={6}>
                            {arResponsible.map((item, index) =>
                                <Flex gap={6} key={index} align={"center"}>
                                    <Avatar size={28} src={item.avatar !== null && item.avatar} icon={<UserOutlined/>}/>
                                    <Typography.Text style={{marginTop: 0, color: '#05F'}}>
                                        {item.name}
                                    </Typography.Text>
                                </Flex>
                            )}
                        </Flex>
                        <Typography.Text style={{marginTop: 0, color: '#05F', cursor: "pointer"}}
                                         onClick={showModalResponsible}>
                            Добавить ответственного
                        </Typography.Text>
                    </Flex>
                </Flex>
                <Flex gap={8} style={{margin: '31px 0 31px'}}>
                    <Button type={'primary'} style={{width: '70%'}} htmlType={"submit"}>Добавить</Button>
                    <Button onClick={() => setConfirmation(true)} type={'text'}
                            style={{width: '30%', color: '#E14453'}}>Отменить</Button>
                </Flex>
            </Flex>
            <Modal
                title="Добавить ответственного"
                open={isModalOpenResponsible}
                onCancel={handleCancelResponsible}
                footer={null}>
                <Form layout="vertical" style={{height: '50vh', padding: 20}}>
                    <SelectSearch
                        type={'page'}
                        dataSource={dataExecutor}
                        notFoundContent={'Пользователь не найден.'}
                        style={{width: '100%'}}
                        dropdownRender={onNameChange}
                        onChangeSelect={handleAddResponsible}
                        placeholder={'Ответственный'}
                    />
                    <Flex gap={8} style={{position: "absolute", bottom: 28, width: '100%'}}>
                        <Button type={'primary'} style={{width: '56%'}}
                                onClick={addFormResponsible}>Добавить</Button>
                        <Button type={'text'} style={{width: '30%', color: '#E14453'}}
                                onClick={() => setConfirmation(true)}>Отменить</Button>
                    </Flex>
                </Form>
            </Modal>
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation}
                          closeModal={() => setConfirmation(false)}
                          actionAfterConsent={isModalOpenResponsible ? handleCancelResponsible : onClose}/>
        </Form>
    );
};

export default TabDeal;
