import {FC} from 'react';
import '../../../assets/css/drawer-right.scss'
import {Avatar, Flex} from "antd";
import {UserOutlined} from "@ant-design/icons";
import Image from '../../../assets/image/Image.png'

export interface IDrawerProps {
    col?: number,
}

const DrawerRight: FC<IDrawerProps> = ({col}) => {
    return (
        <Flex className={'drawer-container'} justify={"flex-start"} gap={8} vertical align={'center'}>
            {
                Array.from({length: 6},
                    (v, i) => (
                        <Avatar src={Image} size={36}  icon={<UserOutlined/>} key={i}/>
                    ))
            }
        </Flex>
    );
};

export default DrawerRight;
