// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./check.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(68, 83, 113, 0.2509803922);
  border-radius: 3px;
  cursor: pointer;
}
.element:checked {
  background-color: #0055FF;
  border: 1.5px solid #0055FF;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.element.image-choice {
  background-color: #1F1F1F !important;
  border: 1.5px solid #FFFFFF;
}

.label {
  display: flex;
}
.label.image-label {
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Checkbox/Checkbox.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,kBAAA;EACA,eAAA;AACD;AAAC;EACC,yBAAA;EACA,2BAAA;EACA,yDAAA;AAEF;AAAC;EACC,oCAAA;EACA,2BAAA;AAEF;;AACA;EACC,aAAA;AAED;AADC;EACC,QAAA;AAGF","sourcesContent":[".element {\n\tappearance: none;\n\twidth: 20px;\n\theight: 20px;\n\tborder: 2px solid #44537140;\n\tborder-radius: 3px;\n\tcursor: pointer;\n\t&:checked {\n\t\tbackground-color: #0055FF;\n\t\tborder: 1.5px solid #0055FF;\n\t\tbackground-image: url('./check.svg');\n\t}\n\t&.image-choice {\n\t\tbackground-color: #1F1F1F !important;\n\t\tborder: 1.5px solid #FFFFFF;\n\t}\n}\n.label{\n\tdisplay: flex;\n\t&.image-label {\n\t\tgap: 8px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
