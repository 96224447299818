import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import Icon, { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TooltipButton from '../TooltipButton/TooltipButton';
import { documentIcon, moreIcon, plusIcon} from "../../../assets/image/svg";
import './style.scss'

const Index = () => {

    const items: MenuProps['items'] = [
        {
            key: 1, label: <TooltipButton propsTooltip={{placement: 'leftTop', title: 'Редактировать'}} propsButton={{ icon: <EditOutlined />, shape: "circle" }} />,
        },
        {
            key: 2, label: <TooltipButton propsTooltip={{placement: 'leftTop', title: 'Удалить'}} propsButton={{ icon: <DeleteOutlined />, shape: "circle" }} />,
        },
    ];

    return (
        <div className="frame">
            <div className="view">
                <div className="element-action-add-small">
                    <Icon component={plusIcon} className="union" alt="Union" />
                </div>
            </div>
            <div className="div">
                <div className="element-document-document">
                    <Icon component={documentIcon} className="union" alt="Union" />
                </div>
                <div className="view-2">
                   <Dropdown menu={{ items,  style: {background: 'transparent', boxShadow: 'none'} }} placement='bottom' trigger={['click']}>
                        <a className='' onClick={(e) => e.preventDefault()}>
                            <Icon component={moreIcon} />
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="view-3">
                <div className="element-document-document">
                    <Icon component={documentIcon} className="union" alt="Union"/>
                </div>
                <div className="view-2">
                    <Dropdown menu={{ items, style: {background: 'transparent', boxShadow: 'none'}}} placement='bottom' trigger={['click']}>
                        <a className='' onClick={(e) => e.preventDefault()}>
                            <Icon component={moreIcon} />
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="view-4">
                <div className="element-document-document">
                    <Icon component={documentIcon} className="union" alt="Union"/>
                </div>
                <div className="view-2">
                    <Dropdown menu={{ items, style: {background: 'transparent', boxShadow: 'none'}}} placement='bottom' trigger={['click']}>
                        <a className='' onClick={(e) => e.preventDefault()}>
                            <Icon component={moreIcon} />
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="text-wrapper">Загрузить файл</div>
            <div className="text-wrapper-2">Счет на оплату №1</div>
            <div className="text-wrapper-3">Счет на оплату №2</div>
            <div className="text-wrapper-4">Закрывающие документы</div>
        </div>
    );
};

export default Index;
