import React, {useEffect, useState} from 'react';
import Container from "../../components/Container";
import {Button, Divider, Flex, Form, Input, message, Modal, notification, Space} from "antd";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {RootState, useStoreDispatch} from "../../store";
import {useTranslation} from "react-i18next";
import "../../assets/css/terminal.scss";
import {SelectSearch} from "../../components/ui/SelectSearch/SelectSearch";
import {deleteTerminal, getTerminal, getTerminalCity, searchTerminalCity, setTerminal} from "../../store/terminals";
import socket from "../../socket";
import FormModalTerminal from "./formModalTerminal";
import {Utils} from "../../utils";
import {IPagination, Table} from "../../components/ui/Table/Table";
import {columns} from "./columns";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router/names";
import {getCurrency, getPhoneMask} from "../../store/store";
import {useSelector} from "react-redux";
import FormCardModalTerminal from "./formCardModalTerminal";
import {useCsvExport} from "../../hooks";

const Index: React.FC = () => {
    const dispatch = useStoreDispatch();
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const router = useHistory();
    const [searchCity, setSearchCity] = useState<string | null>(null);
    const [city, setCity] = useState<[]>([]);
    const [cityData, setDataCity] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [isMap, setIsMap] = useState<boolean>(false);
    const [data, setData] = useState<any[]>(Utils.defaultTable);
    const [current, setCurrent] = useState<number>(1);
    const [page, setPage] = useState<number>(20);
    const [total, setTotal] = useState<number>(0);
    const [dataRow, setDataRow] = useState<{}>({});
    const dataCurrency = useSelector((state: RootState) => state.stores.dataCurrency);
    const [loading, setLoading] = useState<boolean>(false);
    const [typeForm, setTypeForm] = useState<string | null>(null);
    const { generateCSV, copyToClipboard } = useCsvExport({
        fields: columns
            .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
            .map((item: any) => item.dataIndex) // Создаем массив из dataIndex
    });

    const fetchData = (current: number, page: number) => {
        setLoading(true)
        dispatch(getTerminal({current: (current - 1) * page , page: page})).then(response => {
            if (response.payload.success === 1){
                setData(response.payload.data);
                setTotal(response.payload.count.count);
                setLoading(false)
            } else {
                message.error(response.payload.message);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        dispatch(getCurrency())
        dispatch(getPhoneMask())
        dispatch(getTerminalCity()).then(response => {
            setDataCity(response.payload)
        })
        fetchData(current, page);
        socket.once("res-search-terminal-city", (response: any) => {
            setCity(response)
        });
        socket.once("res-set-terminal-city", (response: any) => {
            setDataCity(response)
        });
        socket.on("res-set-terminal", resSetTerminal);
        socket.on("res-delete-terminal", resHandleDeleteRow);

        return () => {
            socket.off("res-set-terminal", resSetTerminal);
            socket.off("res-delete-terminal", resHandleDeleteRow);
        }
    }, [typeForm]);

    const resHandleDeleteRow = (response: any) => {
        setData(prevData => {
            // Проверьте, что response является массивом, если это необходимо
            if (!Array.isArray([response])) {
                console.error('Invalid response data:', [response]);
                return prevData;
            }

            // Предположим, что response это объект с обновленными данными
            const updatedItem = response;
            return prevData.map(item =>
                item.id === updatedItem.id ? { ...item, ...updatedItem } : item
            );
        });
    }

    const resSetTerminal = (responses: any) => {
        const response = JSON.parse(responses);
        if (response.success === 0) {
            Utils.InfoOpenNotification('topRight', response.message, 20, api);
            return; // Early return to avoid further processing
        }

        // Handle successful response
        if (typeForm === 'create') {
            // Add new data to the beginning of the list
            const updatedData = [response.data, ...data];
            setData(updatedData);
        } else {
            // Update existing data in the list
            setData(prevData => {
                const index = prevData.findIndex(item => item.id === response.data.id);

                if (index === -1) {
                    return prevData; // Return previous data if the item is not found
                }

                // Update the item at the found index
                return [
                    ...prevData.slice(0, index),
                    { ...prevData[index], ...response.data },
                    ...prevData.slice(index + 1)
                ];
            });
        }

        // Close the modal and show a notification
        setIsModalOpen(false);
        Utils.openNotification(
            'topLeft',
            typeForm === 'create' ? 'Добавлено' : 'Обновлено',
            api
        );
    };

    const onChangeSelect = (event: any, option: any) => {
       // console.log(option);
    }

    const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value.toLocaleLowerCase())
        if (event.target.value === '') {
            setSearchCity(null)
        }
        dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(response => {
            setCity(response.payload)
        })
    };

    const onClickAddTerminal = () => {
        setTypeForm('create')
        setIsModalOpen(true);
        setIsMap(false)
        setDataRow([])
    }

    const handleCancelModal = () => {
        setIsModalOpen(false);
        form.resetFields()
    }

    const handleCancelViewModal = () => {
        setIsModalViewOpen(false)
        form.resetFields()
    }

    const onFinishAddTerminal = (payload: any) => {
        Object.assign(payload, {type_form: typeForm})
        dispatch(setTerminal(payload));
    }

    const onClickLink = (data: any) => {
        if (data[1].column === 1) {
            router.push({
                pathname: RouteNames.CONTAINERS,
                search: `?child=${encodeURIComponent(data[0].city_child_terminals_id)}
                &city=${encodeURIComponent(0)}
                &name=${encodeURIComponent(' - ' + data[0].city_child.name)}`
            });
        }
        if (data[1].column === 2) {
            router.push({
                pathname: RouteNames.CONTAINERS,
                search: `?child=${encodeURIComponent(0)}
                &city=${encodeURIComponent(data[0].city_child.city_terminals.id)}
                &name=${encodeURIComponent(data[0].city_child.city_terminals.name)}`
            });
        }
    }

    const handleShowEditForm = (data: {}) => {
        setDataRow(data)
        setIsModalOpen(true)
        setIsMap(true)
        setTypeForm('update')
    }

    const viewCardTerminal = (payload: any) => {
        setIsModalViewOpen(true)
        setDataRow(payload)
    }

    const onChangePagination = (page: IPagination<any>) => {
        if (page.current != null && page.pageSize != null) {
            setCurrent(page.current);
            setPage(page.pageSize);
            fetchData(current, page.pageSize);
        }
    }

    const handleDeleteTerminal = (row: any) =>{
         dispatch(deleteTerminal({
            current: (current - 1) * page , page: page, id: row.id,
            delete: row.delete !== true
         }))
    }

    const handleCopyAll = (selectedRows: number[]) => {
        const arSelectedRows = Array.from(selectedRows);
        const rowsData = generateCSV(arSelectedRows, data);
        copyToClipboard(rowsData);
    };

    const handleDeleteCopyAll = (selectedRows: any) => {
        const arSelectedRows = Array.from(selectedRows);
        arSelectedRows.forEach((item: any) => {
            // Оптимизированная фильтрация
            const deleteData = data.find(el => el.id === item && el.delete !== true);

            if (deleteData) {
                dispatch(deleteTerminal({
                    current: (current - 1) * page,
                    page: page,
                    id: item,
                    delete: deleteData.delete !== true
                }));
            }
        });
    };

    return (
        <Container>
            {contextHolder}
            <Flex gap={20} justify={'space-between'} align={'flex-start'} style={{width: '100%'}}
                  className={'container'}>
                <div className={'drawer-title'}>{t('TerminalTable')}</div>
                <Flex gap={16}>
                    <Button icon={<PlusOutlined/>} style={{background: '#05F', color: '#fff'}}
                            onClick={onClickAddTerminal}/>
                    <SelectSearch
                        dataSource={searchCity !== null ? city : cityData}
                        onChangeSelect={onChangeSelect}
                        dropdownRender={onNameChange}
                        placeholder={'поиск'}
                        type={'page'}
                        style={{width: 200}}
                        defaults={'Екатеринбург'}
                        hiddenBtn={false}
                        onClick={onClickAddTerminal}
                    />
                    <Input
                        allowClear
                        prefix={<SearchOutlined/>}
                        className={'input header-depo'}
                        placeholder="Поиск"
                    />
                </Flex>
            </Flex>
            <Space direction="vertical" size="middle" style={{width: "100%"}}>
                <Table
                    onClickLink={onClickLink}
                    dataSource={data}
                    columns={columns}
                    deleteRow={handleDeleteTerminal}
                    loading={loading}
                    height={'calc(-162px + 100vh)'}
                    editRow={handleShowEditForm}
                    pagination={{pageSize: page, total: Number(total), showSizeChanger: true}}
                    onChangePagination={onChangePagination}
                    handleCopyAll={handleCopyAll}
                    handleDeleteCopyAll={handleDeleteCopyAll}
                    style={{overflow: "auto hidden"}}
                    onRow={(record, rowIndex) => {
                        viewCardTerminal(record)
                    }}
                />
            </Space>
            <Modal
                maskClosable={false}
                title= {typeForm === 'create'? 'Добавить терминал':'Редактировать терминал'}
                open={isModalOpen}
                onCancel={handleCancelModal}
                width={548}
                footer={null}>
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    <FormModalTerminal
                        form={form}
                        dataCityRedux={cityData}
                        defaultCity={'Екатеринбург'}
                        showMapEdit={isMap}
                        dataCurrency={dataCurrency}
                        setIsModalOpenForm={setIsModalOpen}
                        onFinishAddTerminal={onFinishAddTerminal}
                        dataRow={dataRow}
                        typeForm={typeForm}
                    />
                </Flex>
            </Modal>
            <Modal
                open={isModalViewOpen}
                maskClosable={false}
                onCancel={handleCancelViewModal}
                title="О терминале"
                style={{ top: 30 }}
                width={548}
                footer={null}>
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    <FormCardModalTerminal
                        dataRow={dataRow}
                    />
                </Flex>
            </Modal>
        </Container>
    );
};

export default Index;
