import { ComponentType } from "react";
import CrmContainers from "../components/CrmContainers";
import ForgetPassword from '../pages/auth/ForgetPassword';
import Login from "../pages/auth/Login";
import RegistrationForm from "../pages/auth/Registration";
import Contacts from "../pages/contacts";
import Containers from "../pages/containers";
import { RouteNames } from "./names";
import DepoContainers from "../components/DepoContainers";
import Terminals from "../pages/terminals";
import Deal from "../pages/deal";


export interface IRoute {
    path: string;
    component: ComponentType;
    exact?: boolean;
}

export const publicRoutes: IRoute[] = [
    { path: RouteNames.LOGIN, exact: false, component: Login },
    { path: RouteNames.REGISTRATION, exact: false, component: RegistrationForm },
    { path: RouteNames.FORGET_PASSWORD, exact: false, component: ForgetPassword }
]

export const privateRoutes: IRoute[] = [
    { path: RouteNames.MAIN, exact: true, component: CrmContainers },
    { path: RouteNames.DEPOT, exact: false, component: DepoContainers },
    { path: RouteNames.CONTACT, exact: false, component: Contacts },
    { path: RouteNames.CONTAINERS, exact: false, component: Containers },
    { path: RouteNames.TERMINALS, exact: false, component: Terminals },
    { path: RouteNames.DEAL, exact: false, component: Deal },
]
