import {useEffect, useState} from 'react';
import './assets/css/App.scss';
import {Layout} from "antd";
import AppRouter from "./components/AppRouter";
import {Utils} from "./utils";
import {BrowserRouter, useHistory} from "react-router-dom";
import {setAuth} from "./store/users"
import {useStoreDispatch} from "./store";
import {RouteNames} from './router/names';
import Loading from './pages/Loading';

function App() {
    const history = useHistory();
    const dispatch = useStoreDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        if (Utils.getStorage('_in') === null) {
            dispatch(setAuth(false))
            history.push(RouteNames.LOGIN);
        } else {
            dispatch(setAuth(true))
        }
        setIsLoading(false);
    }, [dispatch, history])
    return (
        <Layout style={{height: 'calc(100vh - 0)'}}>
            <Layout.Content>
                {isLoading ? <Loading/>
                    : (<BrowserRouter>
                        <AppRouter/>
                    </BrowserRouter>)}
            </Layout.Content>
        </Layout>
    );
}

export default App;
