import {IColumnType} from "../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
    {
        title: 'Номер контейнера',
        dataIndex: 'num_container',
        link: true,
        checkbox: true,
        menu: true,
        width: '100%',
        type: "string",
        key: '1',
    },
    {
        title: 'Тип',
        dataIndex: 'type_container',
        width: '20%',
        type: "string",
        key: '2',
    },
    {
        title: 'Фото',
        dataIndex: 'photo_bool',
        width: '20%',
        type: "string",
        key: '3',
    },
    {
        title: 'В ремонте',
        dataIndex: 'repair',
        width: '30%',
        key: '4',
    },
    {
        title: 'Клиент',
        dataIndex: 'contractors.name_company',
        width: '20%',
        key: '5',
    },
    {
        title: 'Состояние',
        dataIndex: 'quality',
        width: '20%',
        key: '6',
    },
    {
        title: 'Входящая ставка',
        dataIndex: 'currency_price',
        width: '20%',
        key: '7',
    },
    {
        title: 'Бронь или продажа',
        dataIndex: 'status_terminal.name',
        width: '20%',
        key: '8',
    },
    {
        title: 'Бронь До',
        dataIndex: 'booking_before',
        type: "date",
        width: '20%',
        key: '9',
    },
    {
        title: 'Дата входа',
        dataIndex: 'date_entry',
        type: "date",
        width: '20%',
        key: '10',
    },
    {
        title: 'Терминал',
        dataIndex: 'child_terminal.name',
        width: '100px',
        key: '11',
    },
    {
        title: 'Комментарии',
        dataIndex: 'comment',
        width: '20%',
        key: '12',
    },
    {
        title: 'Дата прибытия',
        dataIndex: 'arrival_date',
        type: "date",
        width: '20%',
        key: '13',
    },
    {
        title: 'Дата убытия',
        dataIndex: 'departure_date',
        type: "date",
        width: '30%',
        key: '14',
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '15',
    },
];
