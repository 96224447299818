import React from 'react'
import { message, Tooltip } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { copyIcon } from '../../../assets/image/svg';

const CopyButton = ({text}: {text: string}) => {

  const [isLoading, setIsLoading] = React.useState(false);

  const copyTextBuffer = (text: string) => {
    if (isLoading) return;
    setIsLoading(true);
    navigator.clipboard.writeText(text)
    .then(() => {
      message.success('Текст скопирован');
    })
    .catch(err => {
      message.error('Ошибка копирования');
    })
    .finally(() => setIsLoading(false));
  }

  return (
    text ? <Tooltip title='Копировать' placement='topLeft'>
      <Icon style={{opacity: isLoading ? .3 : 1, cursor: 'pointer'}} component={copyIcon} onClick={() => copyTextBuffer(text)}/>
    </Tooltip> : null
  )
}

export default CopyButton;
