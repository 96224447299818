import React, { useEffect, useMemo } from 'react';
import { useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Button, Col, Flex, Form, Modal, Select, Typography } from 'antd';


import { IFormDeal } from './types';
import { Utils } from '../../../utils';
import TabDoc from './tabs/edit/TabDoc';
import { messagesModal } from '../../../constants';
import { findExecutor } from '../../../store/contact';
import { TAB_DEAL, TAB_DOCUMENT } from '../constants';
import { RootState, useStoreDispatch } from '../../../store';
import { ITab, ITabs } from '../../../components/ui/Tabs/Tabs';
import ScrollList from '../../../components/ui/ScrollList';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import { clearSearchCompany } from '../../../store/contractors';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import { SelectSearch } from '../../../components/ui/SelectSearch/SelectSearch';
import {
  generateIdDeal,
  IDeal,
  setDocsDeal,
  setEditDeal,
  setIsEditDealContainers,
  setResponsible,
} from '../../../store/deal';
import TabDeal from './tabs/edit/TabDeal';
import Responsible from './list/Responsible';
import classnames from 'classnames';
import style from '../style.module.scss';

const dealNames = ['name_deal', 'my_company', 'company'];

const FormDeal: React.FC<IFormDeal> = ({
  onClose,
  onFinish,
  isOpen,
  title,
  initialValue,
}) => {
  const refResponsible = useRef(null);
  const [form] = Form.useForm();
  // const typeDeal = Form.useWatch('type_deal', { form, preserve: true });
  const type_deal_id = Form.useWatch('type_deal_id', { form, preserve: true });
  const [activeKey, setActiveKey] = useState(() => TAB_DEAL);
  const [searchValue, setSearchValue] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isErrors, setErrors] = React.useState<{
    containers: string[];
    doc: string[];
  }>(() => ({ containers: [], doc: [] }));
  const [dataExecutor, setDataExecutor] = useState<any[]>([]);
  const [isEditResponsible, setIsEditResponsible] = useState(() => false);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);
  const [isModalOpenResponsible, setIsModalOpenResponsible] =
    useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const idDeal: any = useSelector<RootState>((state) => state.deal.idDeal);
  const isEditDeal = useSelector<RootState>((state) => state.deal.isEditDeal);
  const responsible = useSelector((state: RootState) => state.deal.responsible);
  const dealType = useSelector<RootState>((state) => state.deal.dealType) as {
    id: number;
    value: string;
  }[];
  const isEditDealContainers = useSelector<RootState>(
    (state) => state.deal.isEditDealContainers,
  ) as boolean;

  // HACK: type IDeal
  const defaultValues: IDeal = {
    id_deal: '',
    type_deal: 'Закуп',
    type_deal_id: 3,
    name_deal: '',
    phone: '',
    account_num: '', // номер счета
    my_company: '',
    my_company_id: undefined,
    company: '',
    company_id: undefined,
    contact_id: undefined,
    currency: '',
    currency_id: undefined,
    nds: undefined,
    bet: undefined,
    responsible: [],
    containers: [],
    containers_ids: [],
    comment: '',
    comment_last: '',
    comments: [],
    docs: [],
    route: ['', ''],
  };

  // возможно будет нужен
  const selectedType = (val: string | null) => {
    dispatch(generateIdDeal(val ?? ''));
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: 'Сделка',
        forceRender: true,
        isError: !!isErrors['containers']?.length,
        disabledText: isEditDealContainers ? 'Завершите редактирование' : '',
        disabled: isEditDealContainers,
        content: <TabDeal form={form} />,
      },
      {
        key: TAB_DOCUMENT,
        label: 'Документы',
        forceRender: true,
        isError: !!isErrors['doc']?.length,
        content: <TabDoc form={form} isOpen />,
      },
    ],
    [JSON.stringify(isErrors), form, isEditDeal, isEditDealContainers],
  );

  const onSubmit = async (values: any) => {
    const { containers, comments, ...rest } = values;
    const route = rest?.route || [];
    rest.comment = '';

    const submit = {
      // status_id для теста
      deal: {
        ...initialValue,
        ...rest,
        responsible,
        route,
        routeText: route.join('\n'),
        status_id: initialValue?.id ? 2 : 1,
      },
      containers,
      comments,
    };
    if (onFinish) {
      onFinish(submit);
      dispatch(setResponsible([]));
      dispatch(setDocsDeal([]));
    }
    return;
  };
  const onUpdate = async (changedValues: any, allValues: any) => {
    // const { company_id, company, my_company_id, my_company } = allValues;
    //   if (!company_id) {
    //     form.setFieldValue('company', '');
    //   }
    //   if (!company) {
    //     form.setFieldValue('company_id', null);
    //   }
    //   if (!my_company_id) {
    //     form.setFieldValue('my_company', '');
    //   }
    //   if (!my_company) {
    //     form.setFieldValue('my_company_id', null);
    //   }

    setErrors({ containers: [], doc: [] });
    return;
  };
  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate) {
      const result = { ...isErrors };
      errorFields.forEach(({ name }: { name: string[] }) => {
        const [title] = name;
        if (dealNames.includes(title)) {
          result?.containers.push(title);
        }
      });
      setErrors((prev) => ({ ...prev, ...result }));
    }

    return;
  };

  const handleClose = () => {
    const promise = new Promise((resolve, reject) => {
      resolve(dispatch(setEditDeal(false)));
      resolve(dispatch(clearSearchCompany()));
      resolve(dispatch(setResponsible([])));
    });
    promise.then(() => {
      if (onClose) {
        onClose();
      }
      setErrors({ containers: [], doc: [] });
      form.resetFields();
    });
  };

  const addFormResponsible = () => {
    const respUser = { ...responsibleChange };
    respUser.isResponsible = isEditResponsible;
    if (isEditResponsible) {
      form.setFieldValue('responsible_id', respUser?.id);
      form.setFieldValue('responsible_name', respUser?.name);
    }
    const filter = responsible
      ?.filter((item: any) => item?.id !== respUser?.id)
      .map((item: any) => {
        const current = { ...item, isResponsible: false };
        return current;
      });
    dispatch(
      setResponsible(
        isEditResponsible ? [respUser, ...filter] : [...responsible, respUser],
      ),
    );
    setIsModalOpenResponsible(false);
    setIsEditResponsible(false);
    setResponsibleChange(null);
    setSearchValue(null);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 2) {
      const result: any = await dispatch(findExecutor(event.target.value));
      const currentResult = result?.payload?.filter(({ id }: { id: number }) =>
        isEditResponsible
          ? responsible?.[0]?.id !== id
          : responsible?.findIndex((item) => item?.id === id) === -1,
      );

      if (currentResult.length > 0) {
        setDataExecutor(currentResult);
      }
    }
  };

  const handleAddResponsible = (e: any, option: any) => {
    const resp = {
      avatar: option.avatar,
      name: option.children,
      id: option.value,
    };
    setResponsibleChange(resp);
    setDataExecutor([]);
    setSearchValue(option.children);
  };

  const showModalResponsible = () => {
    setIsModalOpenResponsible(true);
  };

  const handleCancelResponsible = () => {
    setSearchValue(null);
    setIsModalOpenResponsible(false);
    setIsEditResponsible(false);
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (initialValue?.id) {
      form.setFieldsValue({ ...defaultValues, ...initialValue });
      dispatch(setResponsible(initialValue?.responsible));
    } else {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };
      dispatch(generateIdDeal(' П')).finally(() => {
        dispatch(setResponsible(Utils.getUserInfo().fio ? [initUser] : []));
        const responsibleInfo = {
          responsible_id: initUser.id,
          responsible_name: initUser.name,
        };
        defaultValues.id_deal = idDeal;
        form.setFieldsValue({ ...defaultValues, ...responsibleInfo });
      });
    }
    form.resetFields();
  }, [initialValue?.id, isOpen, idDeal]);

  useEffect(() => {
    // form.resetFields();
    dispatch(setEditDeal(true));
    return () => {
      dispatch(setEditDeal(false));
      dispatch(setIsEditDealContainers(false));
    };
  }, []);

  return (
    <>
    <Form
      className={style['tabs-form']}
      autoComplete="off"
      noValidate
      layout="vertical"
      form={form}
      initialValues={
        initialValue?.id ? { ...defaultValues, ...initialValue } : defaultValues
      }
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      {/* <CloseOutlined className="close" onClick={() => setConfirmation(true)} /> */}
      <Flex justify="space-between" align="center">
        <Flex gap={10} align="center" style={{ marginTop: 15 }}>
          <Typography.Text className={style['label-card-20']}>
            {title}
          </Typography.Text>
          {/* {renderTag({label: 'тип', value: 'Новая'})} */}
        </Flex>
        <Col span={8}>
          <Flex vertical>
            <Typography.Text className={style['text-card-12']}>
              Тип сделки
            </Typography.Text>

            <Form.Item name={'type_deal'} noStyle />
            <Form.Item name={'type_deal_id'}>
              <Select
                placeholder="Выберите тип сделки"
                options={dealType?.map(({ id, value }) => ({
                  value: id,
                  label: value,
                }))}
                onSelect={(_, opt) =>
                  form.setFieldValue('type_deal', opt?.label ?? '')
                }
              />
            </Form.Item>
          </Flex>
        </Col>
      </Flex>

      <ITabs
        isErrorTabs
        activeKey={activeKey}
        tabs={tabs}
        onChange={changeClickTab}
      />
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={
          isModalOpenResponsible ? handleCancelResponsible : handleClose
        }
      />
      <Flex gap={6} vertical>
        <Typography.Title level={4} className={style['subtitle']}>
          Дополнительно
        </Typography.Title>
        <Typography.Text style={{ marginTop: 0 }}>
          Ответственный
        </Typography.Text>
        <Form.Item name="containers_ids" noStyle />
        <Form.Item name="responsible" noStyle />
        <Form.Item name="responsible_id" noStyle />
        <Form.Item name="responsible_name" noStyle />
        {!!responsible?.length && (
          <ScrollList
            tableRef={refResponsible}
            setLastItemReached={(v) => v}
            height={150}
            totalTableActivity={1}
            data={responsible}
            paginationActivity={() => {}}
            isTable={false}
            endMessage={null}
          >
            {responsible.map((item, index, items) =>
              item?.name ? (
                <Flex
                  key={index}
                  justify={'space-between'}
                  align={'baseline'}
                  style={{ paddingRight: 7, minWidth: '400px' }}
                >
                  <Responsible
                    title={item?.name}
                    description={item?.isResponsible ? 'Ответственный' : ''}
                    avatar={item?.avatar}
                  />
                  <Flex vertical gap={6}>
                    {!index && (
                      <>
                        <Typography.Text
                          style={{
                            marginTop: 0,
                            color: '#05F',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setIsEditResponsible(true);
                            showModalResponsible();
                          }}
                        >
                          Сменить ответственного
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            marginTop: 0,
                            color: '#05F',
                            cursor: 'pointer',
                          }}
                          onClick={showModalResponsible}
                        >
                          Добавить ответственного
                        </Typography.Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              ) : null,
            )}
          </ScrollList>
        )}
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify="space-between"
          style={{ margin: '32px 0', marginLeft: 'auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: isEditDealContainers
                ? 'Завершите редактирование'
                : 'Добавить',
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
              disabled: isEditDealContainers,
            }}
          >
            {initialValue?.id ? 'Сохранить изменения' : 'Добавить'}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ width: '30%', color: '#E14453' }}
          >
            Отменить
          </Button>
        </Flex>
      </Form.Item>

    </Form>
      <Modal
        className={classnames(style.modal, {[style.modal_hidden]: confirmation})}
        title={`${isEditResponsible ? 'Сменить' : 'Добавить'} ответственного`}
        open={isModalOpenResponsible}
        onCancel={handleCancelResponsible}
        footer={null}
      >
        <Flex vertical style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            type={'page'}
            defaults={searchValue}
            dataSource={dataExecutor}
            notFoundContent={'Пользователь не найден.'}
            style={{ width: '100%' }}
            dropdownRender={onNameChange}
            onChangeSelect={handleAddResponsible}
            placeholder={'Ответственный'}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
              disabled={!responsibleChange}
            >
              Добавить
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              Отменить
            </Button>
          </Flex>
        </Flex>
      </Modal>
      </>
  );
};

export default FormDeal;
