import { useState } from 'react';

import { Flex, TabsProps } from "antd";
import { useTranslation } from "react-i18next";

import Tabs from './ui/Tabs';
import Layouts from "./Layouts";
import { useBreadcrumb } from '../hooks';
import Terminals from "../pages/terminals/Terminals";
import Containers from "../pages/containers/Containers";
import TooltipButton from './ui/TooltipButton/TooltipButton';

const DepoContainers = () => {
    const {t, i18n} = useTranslation();
    const [activeKey, setActiveKey] = useState('Terminals');
    useBreadcrumb(['Депо', t(activeKey)]);

    const items = [
        { key: 'Terminals', label: t('Terminals'), content: t('Terminals')},
        { key: 'Containers', label: t('Containers'), content: t('Containers') },
    ];

    const handleClickTab = (key: string) => setActiveKey(key);


    return (
        <Layouts>
            <Flex style={{width:'100%'}} vertical>
                <Tabs
                    isTabBarText={false}
                    defaultActiveKey={activeKey}
                    onChange={handleClickTab}
                    tabs={items}
                    indicator={{ size: (origin: any) => origin - 100, align: "center" }}
                />
            </Flex>
        </Layouts>
    );
};

export default DepoContainers;
