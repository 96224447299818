import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ISourceContact} from "../types";
import {Rest_contacts} from "../services/rest_contacts";
import {IContacts} from "../types/contacts";

interface IContactState {
    dataSourceContact: Array<ISourceContact>,
    resultFindExecutor: [],
    resultContacts: Array<IContacts>,
    resultCity: [],
    dataContact: Array<IContacts>,
    totalData: number,
    findDataContact: []
}

const initialState: IContactState = {
    dataSourceContact: [],
    resultFindExecutor: [],
    resultContacts: [],
    resultCity: [],
    dataContact: [],
    totalData: 0,
    findDataContact: []
};
export const sourceContact = createAsyncThunk(
    'sourceContact',
    async () => {
        return await Rest_contacts.getSourceContact()
    }
)
export const findExecutor = createAsyncThunk(
    'findExecutor',
    async (payload: string) => {
        return await Rest_contacts.getFindExecutor(payload)
    }
)
export const findCity = createAsyncThunk(
    'findCity',
    async (payload: {}) => {
        return await Rest_contacts.getFindCity(payload)
    }
)
export const findContact = createAsyncThunk(
    'findContact',
    async (payload: string) => {
        return await Rest_contacts.findContact(payload)
    }
)
export const saveContact = createAsyncThunk(
    'saveContact',
    async (payload: {}) => {
        return await Rest_contacts.setContacts(payload)
    }
)
export const updateContact = createAsyncThunk(
    'updateContact',
    async (payload: {}) => {
        return await Rest_contacts.updateContacts(payload)
    }
)
export const getContact = createAsyncThunk(
    'getContact',
    async (payload: {}) => {
        return await Rest_contacts.getContact(payload)
    }
)
export const deleteContact = createAsyncThunk(
    'deleteContact',
    async (payload: {}) => {
        return await Rest_contacts.deleteContact(payload)
    }
)

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        deleteState(state, action) {
            state.dataContact.filter(contact => contact.id !== action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sourceContact.fulfilled, (state, action) => {
            state.dataSourceContact = action.payload
        })
        builder.addCase(findExecutor.fulfilled, (state, action) => {
            state.resultFindExecutor = action.payload
        })
        builder.addCase(saveContact.fulfilled, (state, action) => {
            state.resultContacts = action.payload
        })
        builder.addCase(updateContact.fulfilled, (state, action) => {
            state.resultContacts = action.payload
        })
        builder.addCase(findCity.fulfilled, (state, action) => {
            state.resultCity = action.payload
        })
        builder.addCase(getContact.fulfilled, (state, action) => {
            state.totalData = action.payload.count.count
            state.dataContact = action.payload.data
        })
        builder.addCase(findContact.fulfilled, (state, action) => {
            state.findDataContact = action.payload.data
        })
    },
});
export default contactSlice.reducer;
export const { deleteState } = contactSlice.actions
