import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { Drawer } from 'antd';

import { HolderOutlined } from '@ant-design/icons';

import TooltipButton from './ui/TooltipButton/TooltipButton';

import '../assets/css/drawer.scss';

const styleArrow: React.CSSProperties = {
  position: 'absolute',
  left: '-15px',
  top: '50%',
  cursor: 'e-resize',
  zIndex: 2,
  minHeight: '50px',
  width: '15px',
  translate: '0 -50%',
};
const styleBoard: React.CSSProperties = {
  position: 'fixed',
  left: '-40px',
  top: 0,
  bottom: 0,
  width: '100vw',
  cursor: 'pointer',
  zIndex: 1,
  background: 'transparent',
};

interface IDrawer {
  open: boolean;
  form: React.ReactNode;
  isHeight?: boolean;
  title?: any
  onSave?: any
}

const DrawerComponent: React.FC<IDrawer> = ({open, form, isHeight}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isResize, setIsResize] = useState(false);
  const [height, setHeight] = useState(() => 'auto');
  const [width, setWidth] = useState(() => 'auto');
  const handleMouseMove = (evt: React.MouseEvent) => {
    if (ref?.current) {
      const widthWindow = window.innerWidth;
      const width = widthWindow - evt.clientX;
      const minWidth = 550;
      if (width > minWidth) {
        setWidth(`${width}px `);
      }
    }
  };
  const closeResize = (evt: React.MouseEvent) => {
    setIsResize(false);
  };
  const openResize = (evt: React.MouseEvent) => {
    setIsResize(true);
  };

  useEffect(() => {
    if (ref?.current && open && isHeight) {
      const parent = ref.current.closest('.ant-drawer-body');
      if (parent) {
        setHeight(parent.getBoundingClientRect().height + 'px');
      }
    }
  }, [open]);
  return (
    <Drawer
      width={'auto'}
      placement="right"
      closable={false}
      forceRender={false}
      open={open}>
      {isResize && (
        <div
          onMouseMove={handleMouseMove}
          onMouseUp={closeResize}
          style={styleBoard}
        />
      )}
      <div ref={ref} style={{ minWidth: 'calc(45vw - 250px)', height, width }}>
        <TooltipButton
          propsTooltip={{ title: 'Расширить окно' }}
          propsButton={{
            style: styleArrow,
            shape: 'default',
            icon: <HolderOutlined style={{ cursor: 'e-resize' }} />,
            onMouseUp: closeResize,
            onMouseDown: openResize,
          }}
        />
        {form}
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
