export enum RouteNames {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    FORGET_PASSWORD = '/forget_password',
    CONTRACTORS = '/contractors',
    CONTACT = '/contact',
    STAFF = '/staff',
    MAIN = '/',
    CRM = '/crm',
    CRM_ID = '/crm/:id',
    CRM_COMPANY = '/crm/company',
    CRM_COMPANY_CREATE = '/crm/company/create',
    CRM_COMPANY_EDIT = '/crm/company/edit/',
    CRM_COMPANY_VIEW = '/crm/company/view/',
    CRM_CONTACTS = '/crm/contacts',
    CRM_CONTACTS_CREATE = '/crm/contacts/create',
    CRM_CONTACTS_EDIT = '/crm/contacts/edit/',
    CRM_CONTACTS_VIEW = '/crm/contacts/view/',
    CONTAINERS = '/containers',
    CONTAINERS_ONE = '/containers/one/:id',
    CONTAINERS_ID = '/containers/:id',
    CONTAINERS_CREATE = '/containers/create',
    CONTAINERS_EDIT = '/containers/edit/',
    CONTAINERS_VIEW = '/containers/view/',
    TERMINALS = '/terminals',
    TERMINALS_ID = '/terminals/:id',
    TERMINALS_CREATE = '/terminals/create',
    TERMINALS_EDIT = '/terminals/edit/',
    TERMINALS_VIEW = '/terminals/view/',
    DEPOT = '/depot',
    DEAL = '/deals',
    DEAL_ID = '/deals/:id',
    DEAL_CREATE = '/deals/0',
    DEAL_EDIT = '/deals/',
    DEAL_VIEW = '/deals/view/',
    DEAL_VIEW_ID = '/deals/view/:id',
    NOT_FOUND = '*',
}
