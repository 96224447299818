import React, {
  ComponentType,
  ForwardRefExoticComponent,
  SVGProps,
} from 'react';
import { message, Tooltip } from 'antd';
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';
import { copyIcon } from '../../../assets/image/svg';

interface ICopyButton {
  text: string;
  textSuccess?: string;
  textError?: string;
  icon?:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
}

const CopyButton: React.FC<ICopyButton> = ({ text, icon, textError, textSuccess }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const copyTextBuffer = (text: string) => {
    if (isLoading) return;
    setIsLoading(true);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success(textSuccess || 'Текст скопирован');
      })
      .catch((err) => {
        message.error(textError || 'Ошибка копирования');
      })
      .finally(() => setIsLoading(false));
  };

  return text ? (
    <Tooltip title="Копировать" placement="topLeft">
      <Icon
        style={{ opacity: isLoading ? 0.3 : 1, cursor: 'pointer', color: '#858FA3' }}
        component={icon ? icon : copyIcon}
        onClick={() => copyTextBuffer(text)}
      />
    </Tooltip>
  ) : null;
};

export default CopyButton;
