import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  Flex,
  InputNumber,
  MenuProps,
  Select,
  Space,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import style from '../../../style.module.scss';
import { ITabView, IValues } from '../../types';
import { messagesModal } from '../../../../../constants';
import { moreIcon } from '../../../../../assets/image/svg';
import { fileTypes } from '../../../../../helpers/input-helpers';
import { RootState, useStoreDispatch } from '../../../../../store';
import UploaderDocs from '../../../../../components/ui/UploaderDocs';
import ModalConfirm from '../../../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../../../components/ui/TooltipButton/TooltipButton';
import {
  deleteContainerDeal,
  editContainerDeal,
  editDealContainerList,
  IContainers,
} from '../../../../../store/deal';

const { title, subtitle } = messagesModal.delete;

const TabDoc: React.FC<ITabView> = ({ deal }) => {
  const dispatch = useStoreDispatch();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [deleteContainer, setDeleteContainer] = React.useState<IContainers>();
  const [values, setValues] = React.useState<IValues>({});
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.deal.containerDealStatuses,
  ) as { id: number; value: string }[];
  const containers = useSelector<RootState>(
    (state) => state.deal.containers,
  ) as IContainers[];
  const editContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...containers[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...result[index], isEdit: true };
    dispatch(editDealContainerList(result));
  };
  const deletedContainer = () => {
    dispatch(
      deleteContainerDeal({
        id: deleteContainer?.id as number,
        id_deal: deal.id as number,
      }),
    );
  };
  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
    isEdit?: boolean,
  ): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: 'Редактировать' }}
          propsButton={{
            onClick: () => editContainer(index),
            icon: <EditOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
    {
      key: '2',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: 'Удалить' }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
  ];

  const submitContainer = (index: number) => {
    const result = [...containers];
    result[index] = { ...values[index], isEdit: false };
    const id = result[index]?.id;
    if (id) {
      dispatch(editContainerDeal({ container: result[index], index }));
    }
  };
  const closeEditContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...values[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...values[index], isEdit: false };
    dispatch(editDealContainerList(result));
  };

  const handlerChange = ({
    name,
    value,
    index,
  }: {
    name: string;
    value: any;
    index: number;
  }) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [name]: value,
      },
    }));
  };

  return (
    <Flex gap={30} vertical align="flex-start">
      <div className={style['file-box']}>
        <UploaderDocs
          isView
          ids={deal?.docs}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
        />
      </div>

      <div className={style['overlap-group-deal']}>
        <div className={style['table']}>
          <div className={style['table__container']}>
            <div className={style['table__header']}>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                № КТК
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Тип
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Статус
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Входящая ставка
              </div>
              <div
                className={classnames(
                  style['table__cell'],
                  style['label-card-16'],
                )}
              >
                Стоимость
              </div>
              <div className={style['menu-icon']}>
                <div className={style['label-card-16']}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'link1',
                          label: (
                            <Typography.Text
                              data-id="link"
                              className={style['menu-icon__link']}
                            >
                              Загрузить в Excel
                            </Typography.Text>
                          ),
                        },
                        {
                          key: 'link2',
                          label: (
                            <Typography.Text
                              data-id="link"
                              className={style['menu-icon__link']}
                            >
                              Выгрузить в Excel
                            </Typography.Text>
                          ),
                        },
                      ],
                    }}
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <Typography.Link className="" onClick={(e) => e.preventDefault()}>
                      <Icon component={moreIcon} />
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className={style['table__body']}>
              {containers.map((field, index) => (
                <div key={field.num_container} className={style['table__row']}>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['num_container']}
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['type']}
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['isEdit'] ? (
                      <Select
                        placeholder="Добавьте статус"
                        style={{ width: '100%' }}
                        defaultValue={field.status_id}
                        onChange={(value) => {
                          const status =
                            containerDealStatuses.find(({ id }) => id === value)
                              ?.value || null;
                          handlerChange({
                            name: 'status',
                            value: status,
                            index,
                          });
                          handlerChange({ name: 'status_id', value, index });
                        }}
                        options={containerDealStatuses?.map(
                          ({ id, value }) => ({ value: id, label: value }),
                        )}
                      />
                    ) : (
                      <span>{field?.['status'] || 'Добавьте статус'}</span>
                    )}
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    <span>{field?.['bet'] ?? ''}</span>
                  </div>
                  <div
                    className={classnames(
                      style['table__cell'],
                      style['text-card-14'],
                    )}
                  >
                    {field?.['isEdit'] ? (
                      <Space>
                        <InputNumber<number>
                          className={style.input}
                          placeholder="Добавьте цену"
                          value={values[index]['price']}
                          controls={false}
                          min={0}
                          maxLength={15}
                          onChange={(val) =>
                            handlerChange({
                              name: 'price',
                              value: val,
                              index,
                            })
                          }
                        />
                        {values[index]['price'] ? (
                          <CloseCircleOutlined
                            className={style.input__icon}
                            onClick={() =>
                              handlerChange({
                                name: 'price',
                                value: '',
                                index,
                              })
                            }
                          />
                        ) : null}
                      </Space>
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['price'],
                          },
                        }}
                      >
                        {field?.['price'] || 'Добавьте цену'}
                      </Typography.Text>
                    )}
                  </div>
                  <Flex className={style['table__menu']} align="center">
                    <div className={style['text-card-14']}>
                      {field?.['isEdit'] ? (
                           <Dropdown
                           open
                           menu={{
                             items: [
                               {key: '1', label: (<TooltipButton
                               propsTooltip={{ title: 'Сохранить' }}
                               propsButton={{
                                 className: style['table__button'],
                                 shape: 'circle',
                                 icon: <CheckOutlined />,
                                 onClick: () => submitContainer(index),
                               }}
                             />)}, {
                               key: '2',
                               label: (
                                 <TooltipButton
                                 propsTooltip={{ title: 'Закрыть' }}
                                 propsButton={{
                                   className: style['table__button'],
                                   shape: 'circle',
                                   icon: <CloseOutlined />,
                                   onClick: () =>
                                     closeEditContainer(index),
                                 }}
                               />
                               )
                             }
                             ],
                             style: {
                               background: 'transparent',
                               boxShadow: 'none',
                             },
                           }}
                           placement="bottom"
                           trigger={['click']}
                         >
                           <Typography.Link
                             className=""
                             onClick={(e) => e.preventDefault()}
                           >
                             <Icon component={moreIcon} />
                           </Typography.Link>
                         </Dropdown>
                      ) : (
                        <Dropdown
                          menu={{
                            items: getItems(
                              () => {
                                setConfirmation(true);
                                setDeleteContainer(field);
                              },
                              editContainer,
                              index,
                            ),
                            style: {
                              background: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Typography.Link className="" onClick={(e) => e.preventDefault()}>
                            <Icon component={moreIcon} />
                          </Typography.Link>
                        </Dropdown>
                      )}
                    </div>
                  </Flex>
                </div>
              ))}
              <ModalConfirm
                title={title}
                subtitle={subtitle}
                isOpen={confirmation}
                closeModal={() => setConfirmation(false)}
                actionAfterConsent={deletedContainer}
              />
            </div>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default TabDoc;
